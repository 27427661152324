import React from "react";
import Chart from "react-apexcharts";


const ChartStat = ({ ...props }) => {

  const options = {
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: ["Valide", "Autre", "Vide"],
    series: [props.valides, props.autre, props.vide],
    colors: ["#4CAF50", "#ff9800", "#df0029"],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
            offsetY: 40,
          },
        },
      },
    ],
  };

  return (
    <div className="card">
      <div className="card-body">
        <h6 className="text-22 font-weight-light mb-1">{props.title}</h6>
        <p className=" text-muted">Nombre total des offres d'emploi : {props.total}</p>
        <div className="row">
          <div className="">
            <Chart
              options={options}
              series={options.series}
              type={options.chart.type}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartStat;
