import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NotificationManager,
} from "react-notifications"; import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      loading: false,
      modifying: false,
      selectedItem: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ loading: true });
    const x = await strapi.getDataRef_regions();
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false });
    } else {
      this.setState({ regions: x });
      this.setState({ loading: false });
    }
  }

  async update(row, value, column) {
    this.setState({ loading: true, modifying: false });
    const x = await strapi.updateDataset_region(row, value, column);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false });
    } else {
      this.getData();
    }
  }

  columns = [
    {
      id: 1,
      dataField: "Nom",
      text: "Region",
      // editable: true,
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 },

    },
    // {
    //   id: 2,
    //   dataField: "lexique",
    //   text: "Lexique",
    //   // editable: true,
    //   // editor: {
    //   //   type: Type.TEXTAREA,
    //   // },
    //   // editorStyle: { height: 500 },
    // },
    {
      id: 3,
      dataField: "coefficient_aj",
      text: "Coefficient d'ajustement (%)",
      // editable: true,
      // style: {textAlign : "center"},
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 },
    },
    // {
    //   id: 2,
    //   text: "Actions",
    //   editable: true,
    //   editor: {
    //     type: Type.CHECKBOX,
    //   },
    //   editorStyle: { height: 50 },
    // },

  ];

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.setState({ modifying: true, selectedItem: row })
    }
  };

  rowStyle = (row, rowIndex) => {
    // console.log(this.state.selectedItem);
    const style = {};
    if (this.state.selectedItem) {
      if (row.id === this.state.selectedItem.id) {
        style.backgroundColor = "#c7a5bc"
      }
      if (!row.isActive) {
        style.fontWeight = "bold";
        style.color = "red";
      }
    }

    return style;
  };

  handleSubmitClick = async () => {
    this.setState({ loading: true })
    const result = await strapi.disableDataset_region(this.state.selectedItem.id, !this.state.selectedItem.isActive)
    if (result) {
      this.setState({ loading: false })
      this.getData()

    } else {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false })
    }
    this.setState({ modifying: false })
  }



  render() {
    return (
      <div>
        <SimpleCard
          style={{
            marginLeft: window.innerWidth - 750,
            top: 220,
            position: 'fixed',
            overflowY: 'hidden',
            overflowx: 'hidden',
            // backgroundColor : 'F9F6F4'
            // paddingRight : 0
            // width: 1200
          }}>

          <div className="d-flex justify-content-end mr-lg-4">
            <RefModal
              data={() => this.getData()}
              type="reg"
              title="Ajouter une région"
              op="ajout"
            ></RefModal>
            {this.state.selectedItem.id && this.state.modifying && <RefModal
              data={() => this.getData()}
              type="reg"
              title="Modifier"
              op="modification"
              nom={this.state.selectedItem.Nom}
              coef={this.state.selectedItem.coefficient_aj}
              id_region={this.state.selectedItem.id}
              id_data={this.state.selectedItem.id}
            ></RefModal>}

            {this.state.selectedItem.id && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={this.handleSubmitClick}
              visible={!this.state.modifying}
            >
              <span className="ul-btn__text"> {this.state.selectedItem.isActive ? "Désactiver" : "Activer"}</span>

            </Button>
            }

            {this.state.selectedItem.id && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={() => this.setState({ modifying: false, selectedItem : [] })}>
              <span className="ul-btn__text"> Annuler </span>
            </Button>}



          </div>
        </SimpleCard>

        {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
        <br />
        <br />
        <br />
        <br />

        <BootstrapTable
          bootstrap4
          keyField="id"
          // loading="true"
          data={this.state.regions}
          columns={this.columns}
          // cellEdit={
          //   cellEditFactory({
          //     mode: "dbclick",
          //     blurToSave: true,
          //     afterSaveCell: (oldValue, newValue, row, column) => { oldValue === newValue ? console.log("Nothing changed") : this.update(row, newValue, column.id) },
          //     onStartEdit: (row) => { this.setState({ modifying: true, selectedItem: row }) },
          //   })}
          noDataIndication={"Aucun enregistrement"}
          rowStyle={this.rowStyle}
          rowEvents={this.rowEvents}
        />
      </div>
    );
  }
}

export default Region;
