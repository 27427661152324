import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Dropdown, Row, Col, Badge, Container,Tab,Tabs} from "react-bootstrap";
import strapi from "app/services/strapi";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { set } from "lodash";

const ModalSkills = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const [show_offreskills, setshowlistoffres] = useState([]);
  const [loaded_whitelist,setloaded_whitelist]=useState(false);
  const [whiteliste,setwhitelist]=useState([]);
  const[Suggestiontlist,setsuggestionlist]=useState([]);
  const handleClose = () => {
    setShow(false);
    
  };
  const handlemodalchange = () => {
    window.location.reload(false);
  };
  const IsBlacklisted = async (id) => {

    let leven_treshold = await strapi.getBlacklistedstate(id);
    if (leven_treshold[0]?.levenshtein_treshold.low < 0) {
      return true;
    }
    else {
      return false;
    }
  }

  const Searchfilter_skill = async (e) => {
    // setloaded_whitelist(false);
    // if (show_offreskills && e.target.value.length > 0) {
    //   let offre_list = await show_offreskills.filter(
    //     obj => {
    //       if (obj.potential_skill) {
    //         if (obj.potential_skill.toLowerCase().includes(e.target.value.toLowerCase())) {
    //           return obj;
    //         }
    //       }
    //       else if (obj.skill_name) {
    //         if (obj.skill_name.toLowerCase().includes(e.target.value.toLowerCase()) || obj.matched_token.toLowerCase().includes(e.target.value.toLowerCase())) {
    //           return obj;
    //         }
    //       }
    //     }
    //   )
    //   setshowlistoffres(offre_list);
    //  setloaded_whitelist(true) 
    // }
    // else {
    //   setshowlistoffres(offre);
    //   setloaded_whitelist(true) ;

    // }


  }

  const getData = async () => {
    setShow(true);
    const data = await props.getData();
    let new_whitelist=[...whiteliste];
    await data.map(async element => {
      if (element.skill_name) {
        let blacklisted = await IsBlacklisted(element.id_skill);
        if (!blacklisted) {
          new_whitelist.push(element)
          setwhitelist(new_whitelist);
          console.log(whiteliste);
             }
        else {
        }
      }
      else {
       Suggestiontlist.push(element);
       setsuggestionlist(Suggestiontlist);
      }
    })
        setshowlistoffres(whiteliste);

    setTimeout(() => {
      setloaded_whitelist(true)
    }, 5000);
   
    
    // console.log(show_offreskills);

    console.log("get data one",show_offreskills)


 };
  useEffect(() => {
    // console.log("kochi",loaded_whitelist)
  
      setshowlistoffres(whiteliste);
    
  }, [whiteliste])


  // useEffect(()=>{
  //   setshowlistoffres(show_offreskills);
  //   console.log("useffect watching show offer",show_offreskills);

  // },[show_offreskills])



  return (
    <Fragment>
      <NotificationContainer />
      {props.title ? (
        <Button
          onClick={() => {
            getData();
          }}
        >
          {props.title}
        </Button>
      ) : (
          <font size="5">
            <i
              className="i-Add mr-4   font-weight-900 text-primary cursor-pointer"
              onClick={() => {
                getData();
              }}
            ></i>
          </font>
        )}

      <Modal
        show={show}
        onHide={handleClose }
        {...props}
        size="lg"
        id="modal"
        scrollable={true}
      >

        <Modal.Header closeButton>
          <Modal.Title>Offre Complète</Modal.Title>
        </Modal.Header>
        {show_offreskills && (
          <Modal.Body>
            <Row className="mb-2">
              <Col>

                <h4 className="card-title mt-1">Les Compétences de l'offre :</h4>
              </Col>
              <Col>
                {/* <div className="input-group">
                  <input
                    className="form-control rounded  h-75"
                    type="search"
                    placeholder="Rechercher"
                    onChange={async (e) => Searchfilter_skill(e)}
                  />

                  <div className="input-group-append rounded h-75 ">
                    <span className="input-group-text h-75" id="basic-addon2">
                      <i className="search-icon  i-Magnifi-Glass1"></i>
                    </span>
                  </div>


                </div> */}
              </Col>
            </Row>
            
            {console.log("what we have got",show_offreskills)}
            
            <Tabs
      id="uncontrolled-tab-example"
     defaultActiveKey="Extraction"
      className="mb-3"
    >
      <Tab eventKey="Extraction" title="Compétence">
      <Container className="mt-2">
              <Row style={{ maxWidth: "100%" }}>
                {!loaded_whitelist ? (
                    <div className="align-center">
                      <span className="spinner-glow spinner-glow-primary mr-3 "></span>{" "}
                    </div>
                  ):(show_offreskills.map(element =>

                  <Col >
                    <div className="card shadow border mb-4" style={{ borderRadius: 25 }}>


                      <div className={element.skill_name ? "bg-primary card-header text-white p-2" : "bg-warning card-header text-white p-2"} style={{ borderTopRightRadius: 25, borderTopLeftRadius: 25, }}><h5 className="text-center text-white">{element.skill_name ? element.skill_name : ""}</h5></div>
                      <div className="card-body p-2 my-2 " style={{ borderRadius: 25 }}>

                        {/* <Badge pill className={`badge-outline-info p-2   m-2`}><h5 className="text-center text-dark text-lg px-4 font-weight-bold">{element.id_skill}</h5></Badge> */}
                        <Badge pill className={`badge-outline-info p-2  m-2`}><h5 className=" text-center  text-dark text-lg px-4  font-weight-bold">{element.matched_token ? element.matched_token : element.potential_skill?.replace("'", "").replace("'", "")}</h5></Badge>
                        {/* <h5 className=" text-center  text-dark text-lg px-4  font-weight-bold">{element.matched_token ? element.matched_token : element.potential_skill.replace("'", "").replace("'", "")}</h5> */}
                      </div>
                    </div>
                  </Col>)

                )}
              </Row>
            </Container>     
         </Tab>
      <Tab eventKey="Suggestions" title="Suggestion">
      <Container className="mt-2">
              <Row style={{ maxWidth: "100%" }}>
                {!loaded_whitelist ? (
                    <div className="align-center">
                      <span className="spinner-glow spinner-glow-primary mr-3 "></span>{" "}
                    </div>
                  ):(Suggestiontlist.map(element =>

                  <Col >
                    <div className="card shadow border mb-4" style={{ borderRadius: 25 }}>


                      <div className={element.skill_name ? "bg-primary card-header text-white p-2" : "bg-warning card-header text-white p-2"} style={{ borderTopRightRadius: 25, borderTopLeftRadius: 25, }}><h5 className="text-center text-white">{element.skill_name ? element.skill_name : "Suggestion"}</h5></div>
                      <div>{console.log(show_offreskills[11])}</div>
                      <div className="card-body p-2 my-2 " style={{ borderRadius: 25 }}>

                        {/* <Badge pill className={`badge-outline-info p-2   m-2`}><h5 className="text-center text-dark text-lg px-4 font-weight-bold">{element.id_skill}</h5></Badge> */}
                        <Badge pill className={`badge-outline-info p-2  m-2`}><h5 className=" text-center  text-dark text-lg px-4  font-weight-bold">{element.matched_token ? element.matched_token : element.potential_skill?.replace("'", "").replace("'", "")}</h5></Badge>
                        {/* <h5 className=" text-center  text-dark text-lg px-4  font-weight-bold">{element.matched_token ? element.matched_token : element.potential_skill.replace("'", "").replace("'", "")}</h5> */}
                      </div>
                    </div>
                  </Col>)

                )}
              </Row>
            </Container>
      </Tab>
    </Tabs>  
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalSkills;
