import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import strapi from "app/services/strapi";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const ModalOffre = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const [offre, setOffre] = useState(null);

  const handleClose = () => {
    setShow(false);
  };


  return (
    <Fragment>
      <NotificationContainer />
      {props.title ? (
        <Button
          onClick={() => {
            setShow(true)
          }}
        >
          {props.title}
        </Button>
      ) : (
        <font size="4">
          <i
            className="i-Add mr-4   font-weight-900 text-primary cursor-pointer"
            onClick={() => {
              setShow(true)
            }}
          ></i>
        </font>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size="lg"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Offre Avant Modification</Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Secteur avant modification</h6>
                    <p className="mb-2">{props.secteur_avant ? props.secteur_avant : props.secteur}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Emploi-Métier avant modification</h6>
                    <p className="mb-2">{props.emploi_avant ? props.emploi_avant : props.emploi}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Ville avant modification</h6>
                    <p className="mb-2">{props.ville_avant ? props.ville_avant : props.ville}</p>
                  </div>
                </div>
              </div>
          </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalOffre;
