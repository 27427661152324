import React, { Component } from "react";
import { Breadcrumb, SimpleCard } from "@gull";
import {
  Accordion,
  Card,
  AccordionToggle,
  AccordionCollapse,
  Button,
} from "react-bootstrap";
import { classList } from "@utils";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import strapi from "app/services/strapi";
import ReactPaginate from "react-paginate";
import { isThursday } from "date-fns/esm";
import TestModal from "./TestModal";
import ModifyModal from "./ModifyModal";
import SweetAlert from "sweetalert2-react";
import swal from "sweetalert2";

class GestionUtilisateurs extends Component {
  state = {
    users: [],
    loading: true,
    nbr: 0,
    tests: [],
    reload: false,
  };
  async componentDidMount() {
    this.getusers();
    // this.getTests();
  }
  handlePageClick = (data) => {
    let pageNumber = data.selected;
  };

  getnot = () => {
    NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
  };

  getrole = async (email) => {
    const ro = await strapi.getUserRole_By_email(email);
    localStorage.setItem("role", ro[0].role.name);
  };
  Delete = async (id) => {
    swal
      .fire({
        title: "",
        text: "Voulez vous vraiment supprimer l'enregistrement?",
        icon: "warning",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      })
      .then(async (result) => {
        if (result.value) {
          const promise = new Promise((resolve, reject) => {
            resolve(this.deleteUser(id));
          });

          await promise;

          swal.fire("Succès", "L'utilisateur a été bien supprimé.", "success");
          this.getusers();
        } else {
          swal.fire("Annulation de la suppression", "", "error");
        }
      });
  };

  // DReload = async (id) => {
  //   const promise = new Promise((resolve, reject) => {
  //     resolve(this.Delete(id));
  //   });
  //   await promise;

  //   this.getusers();
  // };

  deleteUser = async (id) => {
    const x = await strapi.deleteUser(id);
    if (x === false) {
      NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
    } else {
      // return x;
    }
  };

  getuserDetail = async (idUser) => {
    const x = await strapi.getUsers_By_ID(idUser);
    if (x === false) {
      NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
    } else {
      return x;
    }
  };

  async getTests() {
    const data = await strapi.getDataTests();
    this.setState({ tests: data });
  }

  async getusers() {
    this.setState({ loading: true });
    // const nombre = await strapi.getUser_count();
    // this.setState({ nbr: nombre });
    const users = await strapi.getDataUsers();

    if (users === false) {
      this.setState({ loading: false });
      NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
    } else {
      this.setState({ users });
      this.setState({ loading: false });
    }
  }

  render() {
    let users = this.state.users;
    let tests = this.state.tests;
    return (
      <div>
        <SimpleCard title="Liste des utilisateurs">
          <NotificationContainer />
          <p></p>
          <div className="d-flex justify-content-end mr-lg-4">
            <TestModal
              reloadData={() => this.getusers()}
              type="Add"
              title="Ajouter"
            ></TestModal>
          </div>
          <br />
          <br />

          {this.state.loading ? (
            <div className="table-responsive">
              <table
                id="user_table"
                className="table table-bordered  text-center"
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Nom d'utilisateur</th>
                    <th scope="col">Email</th>
                    <th scope="col">Rôle</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
              <br />
              <br />

              <div align="center">
                <div className="spinner spinner-primary mr-3"></div>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table
                id="user_table"
                className="table table-bordered  text-center"
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Nom d'utilisateur</th>
                    <th scope="col">Email</th>
                    <th scope="col">Rôle</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>

                      <td>{user.role.name}</td>
                      <td>
                        <span className="cursor-pointer text-success mr-2">
                          <TestModal
                            reloadData={() => this.getusers()}
                            getData={() => this.getuserDetail(user.id)}
                            id={user.id}
                            type="modify"
                            title="Modifier"
                          ></TestModal>
                        </span>
                        <span className="cursor-pointer text-danger mr-2">
                          <i
                            className="nav-icon i-Close-Window font-weight-bold"
                            onClick={async () => this.Delete(user.id)}
                          ></i>
                        </span>
                      </td>
                      {/* <TestModal
                        reloadData={() => this.getusers()}
                        getData={() => this.getuserDetail(user.id)}
                        id={user.id}
                        type="modify"
                      ></TestModal> */}
                      {/* <td>
                        <Button onClick={() => this.getrole(user.email)}>
                          Role
                        </Button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {/* <div className="d-flex justify-content-end mr-lg-4">
            <ReactPaginate
              previousLabel={<i className="i-Previous"></i>}
              nextLabel={<i className="i-Next1"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.nbr / 10 + 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div> */}

          {/* <div className="table-responsive">
            <table
              id="user_table"
              className="table table-bordered  text-center"
            >
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {tests.map((test) => (
                  <tr>
                    <td>{test.id}</td>
                    <td>{test.Name}</td>

                    <td>
                      <span className="cursor-pointer text-danger mr-2">
                        <i className="nav-icon i-Close-Window font-weight-bold"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </SimpleCard>
      </div>
    );
  }
}

export default GestionUtilisateurs;
