// const initialState = {
//     user: null,
//     jwt: null,

//   };

//   const Reducer =( state=initialState , action ) => {
//       switch (action.type) {
//           case "USER_SAVE": {
//               return {
//               ...state,
//               jwt: action.payload.jwt,
//               user: action.payload.user,
//           };
//           }
//           case "USER_DELETE": {
//             return {
//             ...state,
//             jwt: null,
//             user: null,
//         };
//         }

//       }
//   }

//   export default Reducer;
