import React, { Component } from "react";

function Top15_emploi_region(props) {
  return (
    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={
        props.filters ? 
        `https://app.powerbi.com/reportEmbed?reportId=247424f4-9837-4e93-b9e2-555009b1e2c6&autoAuth=true&filterPaneEnabled=false&${props.filters}`
        // and prospective_emploi_x0020_offre_emploi%2Fdate_x002D_Année%20eq%202022`
        :
        `https://app.powerbi.com/reportEmbed?reportId=247424f4-9837-4e93-b9e2-555009b1e2c6&autoAuth=true&filterPaneEnabled=false`
      }
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
}

export default Top15_emploi_region;