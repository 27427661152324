import axios from "axios";
import localStorageService from "./localStorageService";
import jwt_decode from "jwt-decode";
import { loginWithEmailAndPassword } from "../redux/actions/LoginActions";
import history from "@history.js";
import strapi from "./strapi";
const apibaseurl = "https://mwkb.anapec.org";
const authroute = "auth/local";


class JwtAuthService {
  // user = {
  //   userId: "1",
  //   role: "ADMIN",
  //   displayName: "Watson Joyce",
  //   email: "watsonjoyce@gmail.com",
  //   photoURL: "/assets/images/face-7.jpg",
  //   age: 25,
  //   token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  // };

  loginWithEmailAndPassword = async (user) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(user);
      }, 1000);
    }).then((data) => {
      // localStorageService.setItem("auth_user", data);

      return data;
    });
  };

  checkTokenValidity = () => {
    // console.log("Checking token validity");
    let token = localStorage.getItem('jwt_token')
    if (token !== null) {
      const { exp } = jwt_decode(token)
      const expirationTime = (exp * 1000) - 60000
      // console.log("DATE: ", Date.now(), "|| EXP: ", expirationTime);
      if (Date.now() >= expirationTime) {
        this.logout()
      }
    } else {
      this.logout()
    }

  }

  loginWithToken = (email, password) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.login(email, password));
      }, 100);
    }).then((data) => {
      this.setSession(data.jwt, data.role);
      this.setUser(data.user);
      return data;
    });
  };

  logout = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("role");
    history.push({
      pathname: "/session/signin",
    });
    window.location.reload(false);
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      // localStorage.setItem("role", role);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      // localStorage.removeItem("role");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };


  login = async (email, password) => {
    try {
      const response = await fetch(`${apibaseurl}/${authroute}`, {
        method: "POST",
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });

      const json = await response.json();
      if (json.jwt) {


        const x = {
          email: json.identifier,
          password: json.password,
          jwt: json.jwt,

        };

        loginWithEmailAndPassword(x);
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  // logout = async () => {
  //   localStorage.setItem("jwt", null);
  //   localStorage.setItem("user", null);
  //   // deleteUser();
  // };

  // getDataOffre_emplois = async (reg, secteur, start) => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   let endPoint = "";

  //   const queryreg = qs.stringify({
  //     _where: [{ region: reg }],
  //   });

  //   const querysec = qs.stringify({
  //     _where: [{ secteur: secteur }],
  //   });

  //   const queryRegSec = qs.stringify({
  //     _where: { _and: [{ secteur: secteur }, { region: reg }] },
  //   });

  //   if (!reg && !secteur) {
  //     endPoint = `${apibaseurl}/${offre_emploiroute}?_start=${start}&_limit=10`;
  //   } else if (reg && !secteur) {
  //     endPoint = `${apibaseurl}/${offre_emploiroute}?${queryreg}&_start=${start}&_limit=10`;
  //   } else if (!reg && secteur) {
  //     endPoint = `${apibaseurl}/${offre_emploiroute}?${querysec}&_start=${start}&_limit=10`;
  //   } else {
  //     endPoint = `${apibaseurl}/${offre_emploiroute}?${queryRegSec}&_start=${start}&_limit=10`;
  //   }

  //   try {
  //     const response = await fetch(endPoint, requestConfig);
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getDataPre_normalized_offre_emplois = async (start) => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${pre_normalized_offre_emploiroute}?_start=${start}&_limit=10`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getPre_normalized_offre_emplois_count = async () => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${pre_normalized_offre_emploiroute}/count`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getOffre_emplois_count = async () => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${offre_emploiroute}/count`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getDataPre_normalized_offre_emplois_By_ID = async (id) => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${pre_normalized_offre_emploiroute}/${id}`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getDataRef_regions = async () => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${ref_regionroute}?_limit=100`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  // getDataRef_secteurs = async () => {
  //   // const jwt = localStorage.getItem("jwt");

  //   const requestConfig = {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${apibaseurl}/${ref_secteurroute}?_limit=100`,
  //       requestConfig
  //     );
  //     const json = await response.json();
  //     if (json.error) {
  //       return false;
  //     }
  //     return json;
  //   } catch (err) {
  //     return false;
  //   }
  // };
  ///////////////////////////////////
}

export default new JwtAuthService();
