import React, { Component } from "react";

function Skills(props) {

  return (
    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={props.filters ?
        `https://app.powerbi.com/reportEmbed?reportId=0dea6a9d-8e1b-411a-8464-f639ff1f2aa0&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&${props.filters}`
        :
        `https://app.powerbi.com/reportEmbed?reportId=0dea6a9d-8e1b-411a-8464-f639ff1f2aa0&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`}

      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
}

export default Skills;
