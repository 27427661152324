import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NotificationManager,
} from "react-notifications";
import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";

class Clusters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clusters: [],
            loading: false,
            modifying: false,
            selectedItem: [],
            searchValue : ''
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const clusters = await strapi.getDataset_skills_parents();
        if (clusters === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false });
        } else {
            this.setState({ clusters: clusters, loading: false });
        }



    }

    
    onChangeSearch = ({ target: { value } }) => {
        this.setState({ searchValue: value });
      };




    columns = [
        {
            id: 1,
            dataField: "french_name",
            text: "Cluster",
            style: { textAlign: "center" },

        },

    ];

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.setState({ modifying: true, selectedItem: row });
        }
    };

    rowStyle = (row, rowIndex) => {
        const style = {};
        if (this.state.selectedItem) {
            if (row.french_name === this.state.selectedItem.french_name) {
                style.backgroundColor = "#c7a5bc"
            }
        }
        // if (!row.isActive) {
        //     style.fontWeight = "bold";
        //     style.color = "red";
        // }
        return style;
    };

    handleSubmitSearch = async () => {
        this.setState({ loading: true });
        const x = await strapi.searchClusterByKeyword(this.state.searchValue);
        if (x === false) {
          NotificationManager.error(
            "Erreur lors de la connexion au serveur. Merci de patienter...",
            "Erreur"
          );
          this.setState({ loading: false });
        } else {
          this.setState({ clusters: x, loading: false })
        }
      }


    render() {
        return (
            <div>
                <SimpleCard
                    style={{
                        marginLeft: window.innerWidth - 750,
                        top: 220,
                        position: 'fixed',
                        overflowY: 'hidden',
                        overflowx: 'hidden',
                    }}>

                    <div className="d-flex justify-content-end mr-lg-4">
                        <RefModal
                            data={() => this.getData()}
                            type="cluster"
                            title="Ajouter un cluster de compétences"
                            op="ajout"
                        ></RefModal>
                        {this.state.selectedItem.french_name && this.state.modifying && <RefModal
                            data={() => this.getData()}
                            type="cluster"
                            title="Modification"
                            nom={this.state.selectedItem.french_name}
                            lexique={this.state.selectedItem.french_name}
                            op="modification"
                        ></RefModal>}
                        {this.state.selectedItem.french_name && this.state.modifying && <Button
                            variant="secondary"
                            className="btn-icon m-1"
                            onClick={() => { this.setState({ modifying: false, selectedItem: [] }) }}
                            visible={!this.state.modifying}
                        >
                            <span className="ul-btn__text"> Annuler</span>

                        </Button>
                        }



                    </div>
                </SimpleCard>

                {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
                <br />
                <br />
                <br />
                <div className="form-inline">
                    <input
                        className="form-control"
                        placeholder="Saisir le mot clé à chercher"
                        id="search"
                        type="string"
                        value={this.state.searchValue}
                        onChange={this.onChangeSearch}
                    />
                    <Button
                        variant="secondary"
                        className="btn-icon m-1"
                        onClick={this.handleSubmitSearch}
                    >
                        <span className="ul-btn__text"> Rechercher</span>
                    </Button>
                </div>
                <center>
                    <div style={{ width: '50%' }}>
                        <BootstrapTable
                            bootstrap4
                            keyField="name"
                            data={this.state.clusters}
                            columns={this.columns}
                            noDataIndication={"Aucun enregistrement"}
                            rowStyle={this.rowStyle}
                            rowEvents={this.rowEvents}
                        />
                    </div>
                </center>
            </div>
        );
    }
}

export default Clusters;
