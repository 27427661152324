import React, { Component, useEffect, useState } from "react";
import SimpleCard from "app/repertoire/components/cards/SimpleCard";
import { Row, Col, Tab, Tabs, Button, TabContent, Nav } from "react-bootstrap";
import SecteurANAPEC from "./SecteurANAPEC";
import Region from "./Region";
import Skill from "./Skills";
import Ville from "./Ville";
import Soft_skills from "./Soft_skills"
import Certification from "./Certification"
import EmploiANAPEC from "./EmploiANAPEC";
import Clusters from "./Clusters"


function Referentiel() {

  return (

    <SimpleCard title="Référentiel">
      <div className="simple-card-title">
        
                 
                        </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="d-flex  px-2">
          <Nav.Item className="mr-2 flex-grow-1 text-center">
            <Nav.Link eventKey="first">Régions</Nav.Link>
          </Nav.Item>
          <Nav.Item className="mr-2 flex-grow-1 text-center">
            <Nav.Link eventKey="second">Villes</Nav.Link>
          </Nav.Item>
          <Nav.Item className="mr-2 flex-grow-1 text-center">
            <Nav.Link eventKey="third">Secteur</Nav.Link>
          </Nav.Item>
          <Nav.Item className="flex-grow-1 text-center">
            <Nav.Link eventKey="fourth">Emploi-Métier</Nav.Link>
          </Nav.Item>
          <Nav.Item className="flex-grow-1 text-center">
            <Nav.Link eventKey="fifth">Compétences techniques</Nav.Link>
          </Nav.Item>
          <Nav.Item className="flex-grow-1 text-center">
            <Nav.Link eventKey="sixth">Compétences communes</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item className="flex-grow-1 text-center">
            <Nav.Link eventKey="seventh">Certifications</Nav.Link>
          </Nav.Item> */}
          <Nav.Item className="flex-grow-1 text-center">
            <Nav.Link eventKey="eighth">Clusters</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <Region />
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <Ville />
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <SecteurANAPEC />
          </Tab.Pane>
          <Tab.Pane eventKey="fourth">
            <EmploiANAPEC />
          </Tab.Pane>
          <Tab.Pane eventKey="fifth">
            <Skill />
          </Tab.Pane>
          <Tab.Pane eventKey="sixth">
            <Soft_skills />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="seventh">
            <Certification />
          </Tab.Pane> */}
          <Tab.Pane eventKey="eighth">
            <Clusters />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </SimpleCard>
  );
}

export default Referentiel;
