import React, { Component } from "react";
import { Breadcrumb, SimpleCard } from "@gull";
import {
  Accordion,
  Card,
  AccordionToggle,
  AccordionCollapse,
} from "react-bootstrap";
import { classList } from "@utils";
import Echart5 from "../ui-kits/card-metrics/Echart5";
import Echart6 from "../ui-kits/card-metrics/Echart6";
import Echart7 from "../ui-kits/card-metrics/Echart7";
import Echart8 from "../ui-kits/card-metrics/Echart8";
import strapi from "app/services/strapi";

import {} from "../widgets/statisticsWidgetChartOptions";

class Statistique extends Component {
  state = {
    videntreprise: 0,
    total: 0,
    autre: 0,
    vidsecteur: 0,
    vidregion: 0,
    videmploi: 0,
    allsecteur: 0,
    allregion: 0,
    autsecteur: 0,
    autregion: 0,
    validx: "",
    autx: "",
    videx: "",
    x_label: "",
    loading: true,
    title: "",
    showadd: false,
    showload: false,
  };

  setOpenItem = (name) => {
    if (name === this.state.openItem) {
      this.setState({ openItem: "" });
    } else this.setState({ openItem: name });
  };
  async componentDidMount() {
    // this.getCountNull("entreprise");
    this.getCount();

    // this.getAllOffres();
    // this.gettest2();
  }

  // async getCountNull(col) {
  //   const n = await strapi.getVide_count(col);
  //   // const nombre = await strapi.getOffre_emplois_count();
  //   console.log("get count");

  //   this.setState({ vide: n });
  //   // this.setState({ total: nombre });
  // }

  getTitleLabel(tit) {
    switch (tit) {
      case "type_de_contrat":
        this.setState({ title: "Type de contrat" });
        break;
      case "formation":
        this.setState({ title: "Formation" });
        break;
      case "entreprise":
        this.setState({ title: "entreprise" });
        break;
      case "source":
        this.setState({ title: "Source" });
        break;
      case "fonction":
        this.setState({ title: "Fonction" });
        break;
      case "niv_experience":
        this.setState({ title: "Niveau d'experience" });
        break;
      case "langue_demande":
        this.setState({ title: "Langue demandé" });
        break;
      case "description_poste":
        this.setState({ title: "Description de poste" });
        break;
      case "profil_recherche":
        this.setState({ title: "Profill Recherché" });
        break;
      case "description_entreprise":
        this.setState({ title: "Description Entreprise" });
        break;
      case "url":
        this.setState({ title: "URL" });
        break;

      default:
      // code block
    }
  }
  async getNewStat() {
    this.setState({ showadd: true, showload: true });
    const all = await strapi.getOffre_emplois_count();

    const aut_x = await strapi.getAutre_count(this.state.x_label);
    const vide_x = await strapi.getVide_count(this.state.x_label);


    this.setState({
      autx: aut_x,

      videx: vide_x,

      validx: all - aut_x - vide_x,
    });


    this.setState({ showload: false });
  }

  async getCount() {


    this.setState({ loading: true });
    const all = await strapi.getOffre_emplois_count();

    const autreg = await strapi.getAutre_countreg();
    const autsec = await strapi.getAutre_countsec();

    const vide_region = await strapi.getVide_count("region");
    const vide_secteur = await strapi.getVide_count("secteur");
    const vide_emploi = await strapi.getVide_count("poste");


    this.setState({
      total: all,
      autsecteur: autsec,
      autregion: autreg,
      allsecteur: all - autsec - vide_secteur,
      allregion: all - autreg - vide_region,
      allemploi: all - vide_emploi,

      vidsecteur: vide_secteur,
      vidregion: vide_region,
      videmploi: vide_emploi,
      loading: false,
    });

  }

  gety() {
    return 2500;
  }

  render() {

    return (
      <div>
        {this.state.loading == true ? (
          <div>
            <div align="center">
              <div
                className="spinner spinner-primary mr-3"
                style={{
                  height: "120px",
                  width: "120px",
                  marginTop: "150px",
                }}
              ></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <Echart5
                  height="200px"
                  description={"Total : " + this.state.total}
                  title="Secteur"
                  data={[
                    {
                      name: "Champs non vides",
                      value: this.state.allsecteur,
                      borderColor: "#4CAF50",
                    },
                    {
                      name: "Autre",
                      value: this.state.autsecteur,
                      borderColor: "#ff9800",
                    },
                    {
                      name: "Champs vides",
                      value: this.state.vidsecteur,
                      borderColor: "#df0029",
                    },
                  ]}
                ></Echart5>
              </div>
              <div className="col-md-4 mb-4">
                <Echart5
                  height="200px"
                  description={"Total : " + this.state.total}
                  title="Region"
                  data={[
                    {
                      name: "Champs non vides",
                      value: this.state.allregion,
                      borderColor: "#4CAF50",
                    },
                    {
                      name: "Autre",
                      value: this.state.autregion,
                      borderColor: "#ff9800",
                    },
                    {
                      name: "Champs vides",
                      value: this.state.vidregion,
                      borderColor: "#df0029",
                    },
                  ]}
                ></Echart5>
              </div>
              <div className="col-md-4 mb-4">
                {" "}
                <Echart5
                  height="200px"
                  description={"Total : " + this.state.total}
                  title="Emploi-métier"
                  data={[
                    {
                      name: "Champs non vides",
                      value: this.state.allemploi,
                      borderColor: "#4CAF50",
                    },
                    {
                      name: "Autre",
                      value: 0,
                      borderColor: "#df0029",
                    },
                    {
                      name: "Champ vides",
                      value: this.state.videmploi,
                      borderColor: "#df0029",
                    },
                  ]}
                ></Echart5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 ">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-22 font-weight-light mb-1">
                      Ajouter un champ :{" "}
                    </h6>
                    <br />
                    <select
                      className="custom-select task-manager-list-select col-md-5"
                      onChange={async ({ target: { value } }) => {
                        if (value == "choisir") {
                        } else {
                          const promise = new Promise((resolve, reject) => {
                            resolve(
                              this.setState({
                                x_label: value,
                                // title: value,
                              })
                            );
                          });
                          await promise;
                        }
                        this.getTitleLabel(value);


                        this.getNewStat();
                      }}
                    >
                      <option>Choisir</option>
                      <option value="type_de_contrat">Type de contrat</option>
                      <option value="formation">Formation</option>
                      <option value="entreprise">Entreprise</option>
                      <option value="fonction">Fonction</option>
                      <option value="niv_experience">
                        Niveau d'experience
                      </option>
                      <option value="langue_demande">Langue demandé</option>
                      <option value="description_poste">
                        Description de poste
                      </option>
                      <option value="profil_recherche">Profil recherché</option>
                      <option value="description_entreprise">
                        Description d'entreprise
                      </option>
                      <option value="source">Source</option>
                      <option value="url">URL</option>
                    </select>
                    <br />
                    <br />
                    <div className="col-md-12 mb-6">
                      {this.state.showadd ? (
                        <div>
                          {this.state.showload ? (
                            <div align="center">
                              <div className="spinner spinner-primary mr-3"></div>
                            </div>
                          ) : (
                            <div>
                              <Echart5
                                height="200px"
                                description={"Total : " + this.state.total}
                                title={this.state.title}
                                data={[
                                  {
                                    name: "Champs non vides",
                                    value: this.state.validx,
                                    borderColor: "#4CAF50",
                                  },
                                  {
                                    name: "Autre",
                                    value: this.state.autx,
                                    borderColor: "#ff9800",
                                  },
                                  {
                                    name: "Champs vides",
                                    value: this.state.videx,
                                    borderColor: "#df0029",
                                  },
                                ]}
                              ></Echart5>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Statistique;
