// import axios from "axios";
// import localStorageService from "./localStorageService";
// import qs from "qs";
// import { loginWithEmailAndPassword } from "../redux/actions/LoginActions";
// import history from "@history.js";
// import strapi from "app/services/strapi";

// const powerbi_base_url="https://api.powerbi.com/v1.0/myorg/";
// class powerbi {
//   datasetid="";
//   groupid="f35db383-09ed-4d92-8d56-6a54188c52ab";
//     constructor() { }

//      getvalUsingFind(data,nom) {
//       console.log("we re in find");
//       let dtsetid= data.find(item => item.name.toLocaleLowerCase() === nom.toLocaleLowerCase()).id;
//       return dtsetid ;
//     }

//     getDatasetsId=async(nom)=>{
//       console.log("datasets abt to get fetched");
//        let datasets=await this.getDatasets();
//        console.log("id abt to get fetched");
//        let id=this.getvalUsingFind(datasets.value,nom);
//        console.log(id);
//        return id ;
        
//       }

//      getDatasets=async()=>{
//        let   powerbi_token=await strapi.BItoken();
//         console.log("token abt to get fetched");      
//           const requestConfig = {
//           method: "GET",
  
//           headers: {
//             Authorization: `Bearer ${powerbi_token}`,
//             'Content-Type': 'application/json',
//           },
      
//         };
  
  
//         try {
//           console.log("abt to fetch")
//           const response = await fetch(
//             `${powerbi_base_url}/groups/${this.groupid}/datasets`,
//             requestConfig
//           );
//           const json = await response.json();
//           // console.log(response.status)
//           // console.log(json)
//           if (json.error) {
//             return false;
//           }
//           return json;
//         } catch (err) {
//           return false;
//         }
          
//         }
 
      
//       postRefreshData=async()=>{
//         let   powerbi_token=await strapi.BItoken();
//         this.datasetid=await this.getDatasetsId("full dataset");
//         const requestConfig = {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${powerbi_token}`,
//           "Content-Type": "application/json",
//         },
    
//       };
     
//       try {
//         const response = await fetch(
//           `${powerbi_base_url}/groups/${this.groupid}/datasets/${this.datasetid}/refreshes`,
//           requestConfig
//         );
//       if(response.status == 202){
//         console.log("refresh accepted")
//         return true;
//       }
//       else {
//         console.log("refresh declined")

//         return false;
//       }
//       } catch (err) {
//         console.log("refresh error")

//         return false;
//       }
        
//       }

//     GetLastRefreshState=async()=>{  
//       let   powerbi_token=await strapi.BItoken();
//       this.datasetid=await this.getDatasetsId("full dataset");
//       const requestConfig = {
//       method: "GET",

//       headers: {
//         Authorization: `Bearer ${powerbi_token}`,
//         'Content-Type': 'application/json',
//       },
  
//     };


//     try {
//       const response = await fetch(
//         `${powerbi_base_url}/groups/${this.groupid}/datasets/${this.datasetid}/refreshes?$top=1`,
//         requestConfig
//       );
//       const json = await response.json();
//       if (json.error) {
//         return false;
//       }
//       else if (json.value[0].status.toLocaleLowerCase()=== "Completed".toLocaleLowerCase()){
//         return false;
//       }
//       else if (json.value[0].status.toLocaleLowerCase()=== "Unknown".toLocaleLowerCase()){
//         return true;
//       }
//       else if (json.value[0].status.toLocaleLowerCase()=== "Failed".toLocaleLowerCase()){
//         return false;
//       }
//     } catch (err) {
//       return false;
//     }
//      }

    

// }

// export default new powerbi();
