import React, { Component } from "react";
import strapi from "app/services/strapi";
// import { options1 } from "../charts/apex/apexBarChartOptions";
import {} from "../widgets/statisticsWidgetChartOptions";
import ChartStat from "./ChartStat";
import { Breadcrumb } from "app/repertoire";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class Stat2 extends Component {
  state = {
    videntreprise: 0,
    total: 0,
    autre: 0,
    vidsecteur: 0,
    vidregion: 0,
    videmploi: 0,
    secteur_valides: 0,
    region_valides: 0,
    autsecteur: 0,
    autregion: 0,
    validx: "",
    autx: "",
    videx: "",
    x_label: "",
    loading: true,
    title: "",
    showadd: false,
    showload: false,
    selectedSource: null,
    selectedYear: null,
  };

  setOpenItem = (name) => {
    if (name === this.state.openItem) {
      this.setState({ openItem: "" });
    } else this.setState({ openItem: name });
  };
  async componentDidMount() {
    this.getCount();
  }

  async getCount() {
    // const firstDay = moment.startOf(this.state.selectedSource).format("yyyy-mm-dd");
    // const lastDay = moment.endOf(this.state.selectedSource).format("yyyy-mm-dd");

    let firstDay = this.state.selectedYear
      ? this.state.selectedYear + "-01-01"
      : null;

    let lastDay = this.state.selectedYear
      ? this.state.selectedYear + "-12-31"
      : null;

    this.setState({ loading: true });
    const all = await strapi.getOffreCountBySourceAndDate(
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const region_autres = await strapi.getAutre_countreg(
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const secteur_autres = await strapi.getAutre_countsec(
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const emploi_autres = await strapi.getAutre_count(
      "poste",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const type_de_contrat_autres = await strapi.getAutre_count(
      "type_de_contrat",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const formation_autres = await strapi.getAutre_count(
      "formation",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const entreprise_autres = await strapi.getAutre_count(
      "entreprise",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const fonction_autres = await strapi.getAutre_count(
      "fonction",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const niveau_exp_autres = await strapi.getAutre_count(
      "niv_experience",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const language_autres = await strapi.getAutre_count(
      "langue_demande",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const description_autres = await strapi.getAutre_count(
      "description_poste",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const profile_autres = await strapi.getAutre_count(
      "profil_recherche",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const desc_entreprise_autres = await strapi.getAutre_count(
      "description_entreprise",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    // const salaire_autres = await strapi.getAutre_count("salaire")

    const region_vide = await strapi.getVide_count(
      "region",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const secteur_vide = await strapi.getVide_count(
      "secteur",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const emploi_vide = await strapi.getVide_count(
      "poste",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const type_de_contrat_vide = await strapi.getVide_count(
      "type_de_contrat",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const formation_vide = await strapi.getVide_count(
      "formation",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const entreprise_vide = await strapi.getVide_count(
      "entreprise",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const fonction_vide = await strapi.getVide_count(
      "fonction",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const niveau_exp_vide = await strapi.getVide_count(
      "niv_experience",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const language_vide = await strapi.getVide_count(
      "langue_demande",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const description_vide = await strapi.getVide_count(
      "description_poste",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const profile_vide = await strapi.getVide_count(
      "profil_recherche",
      this.state.selectedSource,
      firstDay,
      lastDay
    );
    const desc_entreprise_vide = await strapi.getVide_count(
      "description_entreprise",
      this.state.selectedSource,
      firstDay,
      lastDay
    );

    if (!all) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    }

    // const salaire_vide = await strapi.getAutre_count("salaire");

    this.setState({
      total: all,
      secteur_autres: secteur_autres,
      region_autres: region_autres,
      emploi_autres: emploi_autres,
      type_de_contrat_autres: type_de_contrat_autres,
      formation_autres: formation_autres,
      entreprise_autres: entreprise_autres,
      fonction_autres: fonction_autres,
      niveau_exp_autres: niveau_exp_autres,
      language_autres: language_autres,
      description_autres: description_autres,
      profile_autres: profile_autres,
      desc_entreprise_autres: desc_entreprise_autres,
      // salaire_autres : salaire_autres,
      secteur_vide: secteur_vide,
      region_vide: region_vide,
      emploi_vide: emploi_vide,
      formation_vide: formation_vide,
      entreprise_vide: entreprise_vide,
      type_de_contrat_vide: type_de_contrat_vide,
      fonction_vide: fonction_vide,
      niveau_exp_vide: niveau_exp_vide,
      language_vide: language_vide,
      description_vide: description_vide,
      profile_vide: profile_vide,
      desc_entreprise_vide: desc_entreprise_vide,
      // salaire_vide : salaire_vide,
      secteur_valides: all - secteur_autres - secteur_vide,
      region_valides: all - region_autres - region_vide,
      emploi_valides: all - emploi_vide,
      type_de_contrat_valides:
        all - type_de_contrat_autres - type_de_contrat_vide,
      formation_valides: all - formation_autres - formation_vide,
      entreprise_valides: all - entreprise_autres - entreprise_vide,
      fonction_valides: all - fonction_autres - fonction_vide,
      niveau_exp_valides: all - niveau_exp_autres - niveau_exp_vide,
      language_valides: all - language_autres - language_vide,
      description_valides: all - description_autres - description_vide,
      profile_valides: all - profile_autres - profile_vide,
      desc_entreprise_valides:
        all - desc_entreprise_autres - desc_entreprise_vide,
      // salaire_valides : all - salaire_autres - salaire_vide,
      loading: false,
    });
  }

  gety() {
    return 2500;
  }

  render() {
    // const options1 = {
    //   chart: {
    //     width: "100%",
    //     type: "pie",
    //   },
    //   labels: ["Champs non vide", "Autre", "Champs vides"],
    //   series: [
    //     this.state.region_valides,
    //     this.state.autregion,
    //     this.state.vidregion,
    //   ],
    //   colors: ["#4CAF50", "#ff9800", "#df0029"],
    //   legend: {
    //     position: "bottom",
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         chart: {
    //           width: 300,
    //         },
    //         legend: {
    //           position: "bottom",
    //           offsetY: 40,
    //         },
    //       },
    //     },
    //   ],
    // };
    //   let options1 = this.state.options;
    // const vidae = this.gety();

    // console.log("snsjhd viiiiiiiiiiiiiiiiiiiiiiiiiiide");
    // console.log(vidae);
    // console.log("VViiiiiiiiiiiiiiiiiide");  {this.state.loading == true ? (

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Statistiques sur la complétude des données" },
          ]}
        ></Breadcrumb>
        <NotificationContainer />
        <div className="row">
          <div className="col-md-2">
            <strong className="text-sm-left  heading text-primary">
              <span className="mr-1">Source</span>{" "}
            </strong>
            <select
              className="custom-select task-manager-list-select"
              onChange={async ({ target: { value } }) => {
                if (value == "choisir") {
                  const promise = new Promise((resolve, reject) => {
                    resolve(this.setState({ selectedSource: null }));
                  });
                  await promise;
                } else {
                  const promise = new Promise((resolve, reject) => {
                    resolve(this.setState({ selectedSource: value }));
                  });
                  await promise;
                }

                await this.getCount();
              }}
            >
              <option value="choisir">Réinitialiser</option>
              <option value="anapec">ANAPEC</option>
              <option value="marocannonces">Maroc Annonces</option>
              <option value="rekrute">Rekrute</option>
              <option value="emploi">Emploi</option>
              <option value="m-job">M-job</option>
              <option value="marocadres">Marocadres</option>
              <option value="offre-emploi">Offre emploi</option>
           
               </select>
              </div>
          <div className="col-md-2 ">
            <strong className="text-sm-left  heading text-primary">
              <span className="mr-1">Année</span>{" "}
            </strong>
            <select
              className="custom-select task-manager-list-select"
              onChange={async ({ target: { value } }) => {
                if (value == "choisir") {
                  const promise = new Promise((resolve, reject) => {
                    resolve(this.setState({ selectedYear: null }));
                  });
                  await promise;
                } else {
                  const promise = new Promise((resolve, reject) => {
                    resolve(this.setState({ selectedYear: value }));
                  });
                  await promise;
                }

                await this.getCount();
              }}
            >
              <option value="choisir">Réinitialiser</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
            </select>
          </div>
        </div>
        {this.state.loading == true ? (
          <div>
            <div align="center">
              <div
                className="spinner spinner-primary mr-3"
                style={{
                  height: "120px",
                  width: "120px",
                  marginTop: "150px",
                }}
              ></div>
            </div>
          </div>
        ) : (
          <div>
            <br /> <br />
            <div className="row">
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Région"}
                  valides={this.state.region_valides}
                  autre={this.state.region_autres}
                  vide={this.state.region_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Secteur"}
                  valides={this.state.secteur_valides}
                  autre={this.state.secteur_autres}
                  vide={this.state.secteur_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Emploi-métier"}
                  valides={this.state.emploi_valides}
                  autre={this.state.emploi_autres}
                  vide={this.state.emploi_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Type de contrat"}
                  valides={this.state.type_de_contrat_valides}
                  autre={this.state.type_de_contrat_autres}
                  vide={this.state.type_de_contrat_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Formation"}
                  valides={this.state.formation_valides}
                  autre={this.state.formation_autres}
                  vide={this.state.formation_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Enterprise"}
                  valides={this.state.entreprise_valides}
                  autre={this.state.entreprise_autres}
                  vide={this.state.entreprise_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Fonction"}
                  valides={this.state.fonction_valides}
                  autre={this.state.fonction_autres}
                  vide={this.state.fonction_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Niveau d'expérience"}
                  valides={this.state.niveau_exp_valides}
                  autre={this.state.niveau_exp_autres}
                  vide={this.state.niveau_exp_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Language demandé"}
                  valides={this.state.language_valides}
                  autre={this.state.language_autres}
                  vide={this.state.language_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Description du poste"}
                  valides={this.state.description_valides}
                  autre={this.state.description_autres}
                  vide={this.state.description_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Profile recherché"}
                  valides={this.state.profile_valides}
                  autre={this.state.profile_autres}
                  vide={this.state.profile_vide}
                  total={this.state.total}
                />
              </div>
              <div className="col-md-4 mb-4">
                <ChartStat
                  title={"Description de l'entreprise"}
                  valides={this.state.desc_entreprise_valides}
                  autre={this.state.desc_entreprise_autres}
                  vide={this.state.desc_entreprise_vide}
                  total={this.state.total}
                />
              </div>
              {/* <div className="col-md-4 mb-4">
              <ChartStat
                  title={"Salaire"}
                  valides={this.state.salaire_valides}
                  autre={this.state.salaire_autres}
                  vide={this.state.salaire_vide}
                  total={this.state.total}
                />
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Stat2;
