import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import Signin from "./views/sessions/Signin";
import sessionsRoutes from "./views/sessions/sessionsRoutes";

function App() {
  // if (localStorage.getItem("jwt_token")) {
  //   return (
  //     <AppContext.Provider value={{ routes }}>
  //       <Provider store={Store}>
  //         <Suspense fallback={<Loading></Loading>}>
  //           <Router history={history}>{renderRoutes(routes)}</Router>
  //         </Suspense>
  //       </Provider>
  //     </AppContext.Provider>
  //   );
  // } else {
  //   return (
  //     <AppContext.Provider value={{ sessionsRoutes }}>
  //       <Provider store={Store}>
  //         <Suspense fallback={<Loading></Loading>}>
  //           <Router history={history}>{renderRoutes(sessionsRoutes)}</Router>
  //         </Suspense>
  //       </Provider>
  //     </AppContext.Provider>
  //   );
  // }

  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <Suspense fallback={<Loading></Loading>}>
          <Router history={history}>{renderRoutes(routes)}</Router>
        </Suspense>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
