import React from "react";
import ReactEcharts from "echarts-for-react";
import { echartOptions } from "@gull";

const Echart5 = ({ height, description, title, data }) => {
  console.log("sssssssssss" + data);
  const getData = () => {
    let Stats = [];
    for (var i = 0; i < data.length; i++) {
      Stats.push({
        name: data[i].name,
        value: data[i].value,
        ...echartOptions.pieLabelOff,
        itemStyle: {
          borderColor: data[i].borderColor,
        },
      });
    }
    return Stats;
  };

  const option = {
    ...echartOptions.defaultOptions,
    ...{
      series: [
        {
          type: "pie",
          itemStyle: echartOptions.pieLineStyle,
          data: getData(),
        },
      ],
    },
  };
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="text-22 font-weight-light mb-1">{title}</h6>
        <p className=" text-muted">{description}</p>
        <div className="row">
          <div className="col">
            <br />
            <div style={{ color: "#4CAF50" }}>
              Valides: {data[0].value}
            </div>{" "}
            <br />{" "}
            <div style={{ color: "#df0029" }}>Vides: {data[2].value} </div>
            <br />
            <div style={{ color: "#ff9800" }}>Autres: {data[1].value}</div>
          </div>
          <div className="col">
            <ReactEcharts style={{ height: height }} option={option} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Echart5;
