import React, { Component, Fragment } from "react";
import strapi from "app/services/strapi";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Accordion,
  Card,
  AccordionToggle,
  AccordionCollapse,
  Button,
} from "react-bootstrap";
import swal from "sweetalert2";
import powerbi from "app/services/powerbi";

function Evolution_nbr_postes(props) {
  return (
    <Fragment>

    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={
      props.filters ? 
      `https://app.powerbi.com/reportEmbed?reportId=8cb9c071-ff18-417e-984a-4eae7445055f&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false&${props.filters}`
      :
      "https://app.powerbi.com/reportEmbed?reportId=8cb9c071-ff18-417e-984a-4eae7445055f&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false"}
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
    </Fragment>
  );
}

export default Evolution_nbr_postes;
