import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NotificationManager,
} from "react-notifications"; import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";

class Skill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      loading: false,
      modifying: false,
      selectedItem: [],
      searchValue: "",
      multiple: false,
      allselected: false, 
      selectedItemCluster : "-"

    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    if (this.state.searchValue === "") {
      this.setState({ loading: true });
      const x = await strapi.getDataset_skills_ST1();
      if (x === false) {
        NotificationManager.error(
          "Erreur lors de la connexion au serveur. Merci de patienter...",
          "Erreur"
        );
        this.setState({ loading: false, modifying: false });
      } else {
        this.setState({ skills: x });
        this.setState({ loading: false, modifying: false });
      }
    } else {
      const x = await strapi.searchSkillByKeyword("ST1", this.state.searchValue);
      if (x === false) {
        NotificationManager.error(
          "Erreur lors de la connexion au serveur. Merci de patienter...",
          "Erreur"
        );
        this.setState({ loading: false });
      } else {
        this.setState({ skills: x, loading: false })
      }
    }

  }

  async update(row, value, column) {
    this.setState({ loading: true, modifying: false });
    const x = await strapi.updateSkill(row.id_skill, column, value);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false, modifying: false });
    } else {
      this.setState({ loading: false });
      this.getData();
    }
  }

  columns = [
    {
      id: 1,
      dataField: "french_name_deepl",
      text: "Nomenclature française",
      // editable: true,
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 },
      style: { textAlign: "center" },

    },
    {
      id: 2,
      dataField: "parent",
      text: "Nom du Cluster",
      // editable: true,
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 },
      style: { textAlign: "center" },

    },


  ];

  rowEvents = {
    onClick: async (e, row, rowIndex) => {
      if (this.state.multiple) {
        let newarr = [...this.state.selectedItem]
        if (!newarr.includes(row)) {
          newarr.push(row)
        } else {
          newarr = newarr.filter((e) => e.id_skill !== row.id_skill)
        }
        this.setState({ modifying: true, selectedItem: newarr })
      } else 
        this.setState({ modifying: true, selectedItem: row })
        // const x = await strapi.getSkill_Parent(row.english_name)
        // if(x === false){
        //   NotificationManager.error(
        //     "Erreur lors de la connexion au serveur. Merci de patienter...",
        //     "Erreur"
        //   );
        // }else {
        //   this.setState({
        //     selectedItemCluster : x[0]?.name
        //   })
        // }
    }
  };

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (this.state.multiple) {
      if (this.state.selectedItem)
        if (this.state.selectedItem?.includes(row)) {
          style.backgroundColor = "#c7a5bc"
        }
    } else {
      if (this.state.selectedItem)
        if (row.id_skill === this.state.selectedItem?.id_skill) {
          style.backgroundColor = "#c7a5bc"
        }
    }

    if (row.levenshtein_treshold?.low < 0) {
      style.fontWeight = "bold";
      style.color = "red";
    }
    return style;
  };

  handleSubmitClick = async () => {
    this.setState({ loading: true });
    //Update Levenshtein distance (Column 3) to -10000 means desactivate the skill
    const x = await strapi.updateSkill(this.state.selectedItem.id_skill, this.state.selectedItem.english_name, this.state.selectedItem.french_name_deepl, this.state.selectedItem.levenshtein_treshold?.low >= 0 ? -10000.0 : 0);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false, modifying: false });
    } else {
      this.getData();
    }
  }

  handleSubmitSearch = async () => {
    this.setState({ loading: true });
    const x = await strapi.searchSkillByKeyword("ST1", this.state.searchValue);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false });
    } else {
      this.setState({ skills: x, loading: false })
    }
  }
  handleDelete = async () => {
    this.setState({ loading: true });
    //Update Levenshtein distance (Column 3) to -10000 means desactivate the skill
    const x = await strapi.deleteSkill(this.state.selectedItem.id_skill);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false, modifying: false });
    } else {
      this.getData();
    }
  }


  onChangeSearch = ({ target: { value } }) => {
    this.setState({ searchValue: value });
  };

  render() {
    return (
      <div>
        <SimpleCard
          style={{
            marginLeft: window.innerWidth - 950,
            top: 220,
            position: 'fixed',
            overflowY: 'hidden',
            overflowx: 'hidden',
            // backgroundColor : 'F9F6F4'
            // paddingRight : 0
            // width: 1200
          }}>

          <div className="d-flex justify-content-end mr-lg-4">
            <RefModal
              data={() => this.getData()}
              type="skills"
              title="Ajouter une compétence technique"
              type_skill="ST1"
              op="ajout"
            ></RefModal>
            {this.state.selectedItem.id_skill && this.state.modifying && <RefModal
              data={() => this.getData()}
              type="skills"
              title="Modification"
              type_skill="ST1"
              nom={this.state.selectedItem.french_name_deepl}
              lexique={this.state.selectedItem.english_name}
              id={this.state.selectedItem.id_skill}
              coef={this.state.selectedItem.levenshtein_treshold?.low}
              cluster={this.state.selectedItemCluster}
              op="modification"
            ></RefModal>}
            {this.state.selectedItem.id && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={this.handleDelete}>
              <span className="ul-btn__text"> Supprimer </span>
            </Button>}
            {this.state.selectedItem.english_name && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={this.handleSubmitClick}
              visible={!this.state.modifying}
            >
              <span className="ul-btn__text"> {this.state.selectedItem.levenshtein_treshold?.low >= 0 ? "Désactiver" : "Activer"}</span>

            </Button>
            }


            <Button
              variant={"secondary"}
              className="btn-icon m-1"
              onClick={async () => {

                this.setState({ multiple: !this.state.multiple, selectedItem: [] })
              }}>
              <span className="ul-btn__text"> {this.state.multiple? "Selection Simple" : "Selection Multiple"} </span>
            </Button>
            {(this.state.selectedItem.id_skill || this.state.selectedItem.length > 0) && <RefModal
              data={() => this.getData()}
              type="cluster_assign"
              title="Affecter à un cluster"
              skills={this.state.selectedItem}
              op="assign_cluster"
            ></RefModal>}

            {this.state.multiple && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={() => {
                if (this.state.allselected) {
                  this.setState({
                    selectedItem: [],
                    allselected: false
                  })
                } else {
                  this.setState({
                    selectedItem: this.state.skills,
                    allselected: true
                  })
                }
              }}
            >
              <span className="ul-btn__text"> {this.state.allselected ? "Annuler la séléction" : "Sélectionner tout"}</span>

            </Button>}

            {this.state.selectedItem.id_skill && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={() => { this.setState({ modifying: false, selectedItem: [] }) }}
              visible={!this.state.modifying}
            >
              <span className="ul-btn__text"> Annuler</span>

            </Button>
            }

          </div>
        </SimpleCard>

        {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
        <br />
        <br />
        <br />
        <br />
        <div className="form-inline">
          <input
            className="form-control"
            placeholder="Saisir le mot clé à chercher"
            id="search"
            type="string"
            value={this.state.searchValue}
            onChange={this.onChangeSearch}
          />
          <Button
            variant="secondary"
            className="btn-icon m-1"
            onClick={this.handleSubmitSearch}
          >
            <span className="ul-btn__text"> Rechercher</span>
          </Button>
        </div>
        <br />
        <br />
        <br />
        <center>
          <div style={{ width: '50%' }}>
            <BootstrapTable
              bootstrap4
              keyField="id_skill"
              data={this.state.skills}
              columns={this.columns}
              noDataIndication={"Aucun enregistrement"}
              rowStyle={this.rowStyle}
              rowEvents={this.rowEvents}
            />
          </div>
        </center>
      </div>
    );
  }
}

export default Skill;
