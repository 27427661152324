import React, { Component } from "react";
import { Table, Button, Card } from "react-bootstrap";
import { Breadcrumb } from "app/repertoire";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import swal from "sweetalert2";

class ScrapingScript extends Component {
  constructor(props) {
    super(props);

    // Initializing the state
    this.state = {
      script: "",
      data: {
        url: "",
        url_offre: "",
        url_nextPage: "",
        offre: [
          {
            key: "",
            value: "",
            inDetails: true,
          },
        ],
      },
    };
  }
  componentDidMount() {
    // getAllInvoice().then((res) => this.setState({ invoiceList: res.data }));
  }

  // handeViewClick = (invoiceId) => {
  //   this.props.history.push(`/invoice/${invoiceId}`);
  //   // getInvoiceById(invoiceId).then(res => console.log(res.data));
  // };

  // handleDeleteInvoice = (invoice) => {
  //   // deleteInvoice(invoice).then((res) => {
  //   //   swal.fire({
  //   //     title: "Deleted!",
  //   //     text: "Your file has been deleted.",
  //   //     type: "success",
  //   //     icon: "success",
  //   //     timer: 1500,
  //   //   }
  //   //   );
  //   //   this.setState({
  //   //     invoiceList: res.data,
  //   //     shouldShowConfirmationDialog: false,
  //   //   });
  //   // });
  // };

  handleDialogClose = () => {
    this.setState({ shouldShowConfirmationDialog: false });
  };

  render() {
    let { invoiceList } = this.state;
    return (
      <div>
        <Breadcrumb
          routeSegments={[{ name: "Options de scraping" }]}
        ></Breadcrumb>
        <form>
          <div className="row">
            <div className="col-md-6 form-group mb-3">
              <label htmlFor="">
                <font size="3">URL du site</font>
              </label>
              <input
                id="url_site"
                name="url_site"
                className="form-control"
                placeholder="URL"
                type="text"
              />

              <div className="text-danger mt-1 ml-2"></div>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label htmlFor="">
                <font size="3">URL de l'offre</font>
              </label>
              <input
                id="url_offre"
                name="url_offre"
                className="form-control"
                placeholder="URL"
                type="text"
              />

              <div className="text-danger mt-1 ml-2"></div>
            </div>
          </div>

          {/* <div className="separator-breadcrumb border-top"></div> */}

          <div className="row">
            <div className="col-md-6 form-group mb-3">
              <label htmlFor="">
                <font size="3">URL de la page suivante</font>
              </label>
              <input
                id="url_pagesuiv"
                name="url_pagesuiv"
                className="form-control"
                placeholder="URL"
                type="text"
              />

              <div className="text-danger mt-1 ml-2"></div>
            </div>
          </div>

          <div className="separator-breadcrumb border-top"></div>

          <Card elevation={6} className="w-100 overflow-auto">
            <Table style={{ minWidth: 750 }}>
              <thead>
                <tr>
                  <th className="pl-sm-24">Champ</th>
                  <th className="px-0">Valeur</th>
                  <th className="px-10">Interface</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Poste
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="poste"
                    />
                  </td>
                  <td className="pl-0 capitalize " align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="poste_interface"
                            id="poste_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="poste_interface"
                            id="poste_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Secteur
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="secteur"
                    />
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="secteur_interface"
                            id="secteur_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="secteur_interface"
                            id="secteur_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Région
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="region"
                    />
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="region_interface"
                            id="region_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="region_interface"
                            id="region_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Entreprise
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="entreprise"
                    />
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="entreprise_interface"
                            id="entreprise_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="entreprise_interface"
                            id="entreprise_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Date
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="date"
                    />
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="date_interface"
                            id="date_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="date_interface"
                            id="date_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="pl-sm-24 capitalize" align="left">
                    Nombre de postes
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="nbr_poste"
                    />
                  </td>
                  <td className="pl-0 capitalize" align="left">
                    <div className="col-lg-2">
                      <div className="ul-form__radio-inline">
                        <label className=" ul-radio__position radio radio-primary form-check-inline">
                          <input
                            type="radio"
                            name="nbrposte_interface"
                            id="nbrposte_p"
                          />
                          <span className="ul-form__radio-font">Pricipale</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="ul-radio__position radio radio-primary">
                          <input
                            type="radio"
                            name="nbrposte_interface"
                            id="nbrposte_d"
                          />
                          <span className="ul-form__radio-font">Details</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </form>
        {/* <ConfirmationDialog
          open={this.state.shouldShowConfirmationDialog}
          onConfirmDialogClose={this.handleDialogClose}
          onYesClick={this.handleDeleteInvoice}
          text="Are you sure to delete?"
        /> */}
      </div>
    );
  }
}

export default ScrapingScript;
