import { lazy } from "react";
import { Redirect } from "react-router-dom";
import OffresRoutes from "../offres/offresRoutes";
import GullLayout from "app/GullLayout/GullLayout";
import OffresList from "../offres/OffresList";
const Signup = lazy(() => import("./Signup"));

const Signin = lazy(() => import("./Signin"));

const Dashboard = lazy(() => import("../../GullLayout/Layout1/Layout1"));

const Test = lazy(() => import("../app/invoice/Test"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const Error404 = lazy(() => import("./Error"));

const Test3 = lazy(() => import("./Test3"));
const ooo = lazy(() => import("../offres/OffresList"));

// const yu =
const sessionsRoutes = [
  {
    path: "/session/signup",
    component: Signup,
  },
  {
    path: "/session/signin",
    component: localStorage.getItem("jwt_token") ? Test3 : Signin,
    // component: Signin,
  },
  {
    path: "/test3",
    component: localStorage.getItem("jwt_token") ? Test3 : Signin,
    // component: Test3,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/session/404",
    component: Error404,
  },
  // {
  //   path: "/offres/projetees",
  //   component: Signin,
  // },
  // {
  //   path: "/offres/brutes",
  //   component: Signin,
  // },
];

export default sessionsRoutes;
