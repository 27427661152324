import React, { Component } from "react";

function Vue_region(props) {

  return (
    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={props.filters ?
        `https://app.powerbi.com/groups/f35db383-09ed-4d92-8d56-6a54188c52ab/reports/27ca85b9-1c82-48e7-b2d0-578e9624fa79&autoAuth=true&filterPaneEnabled=false&${props.filters}`
        :
        `https://app.powerbi.com/groups/f35db383-09ed-4d92-8d56-6a54188c52ab/reports/27ca85b9-1c82-48e7-b2d0-578e9624fa79&autoAuth=true&filterPaneEnabled=false`}
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
}

export default Vue_region;
