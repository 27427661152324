import React, { Component } from "react";
import { Breadcrumb } from "app/repertoire";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import strapi from "app/services/strapi";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class ArticlesList extends Component {
  state = {
    articles: [],
    prevarticles :[],
    nextarticles : [],
    search:"",
    Page : 1,
    Page_count :1,
    Loading :false
  };

  async getarticles_search() {

    const art = await strapi.getarticles_keyword(this.state.Page, 8,this.state.search);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ articles: art });
      this.setState({Loading:true});

    }
  }
  async getNextarticles_search() {

    const art = await strapi.getarticles_keyword(this.state.Page+1, 8,this.state.search);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ nextarticles: art });
      this.setState({Loading:true});

    }
  }


  async getPrevarticles_search() {

    const art = await strapi.getarticles_keyword(this.state.Page-1, 8,this.state.search);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ prevarticles : art });
      this.setState({Loading:true});

    }
  };

  async getarticles() {

    const art = await strapi.getarticles(this.state.Page, 8);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ articles: art });
      this.setState({Loading:true});

    }
  }

  async getNextarticles() {

    const art = await strapi.getarticles(this.state.Page+1, 8);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ nextarticles: art });
      this.setState({Loading:true});

    }
  }

  async getPrevarticles() {

    const art = await strapi.getarticles(this.state.Page-1, 8).then();
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ prevarticles : art });
      this.setState({Loading:true});

    }
  };
  handleSearch = data =>{
    this.setState({Loading:false});
    let keywords=data.target.value;
    this.setState({search:keywords});
    this.setState({Loading:true});
  }
  handlePageClick = data => {
  
    let currentPage = data.selected+1;
    this.setState({Loading:false});

    if(currentPage>this.state.Page){
      this.setState({
        Page_count : this.state.Page_count+1
      })
    }
    
    if(currentPage<this.state.Page){
      this.setState({
        Page_count : this.state.Page_count-1
      })
    }
    this.setState({Page:currentPage})
    this.setState({Loading:true});

  };

  async componentDidMount() {
    if(this.state.search!==""){
      this.getarticles_search();
      this.getNextarticles_search();
      this.getPrevarticles_search();

    }
    else{
      this.getarticles();
      this.getNextarticles();
      this.getPrevarticles();
      }
      this.setState({Loading:true});
    console.log("prev",this.state.articles);
   

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.Page !== this.state.Page && this.state.search=="") {
      this.getarticles();
      this.getNextarticles();
      this.getPrevarticles();
      console.log("next on update",prevState.nextarticles.length);
      if(prevState.nextarticles.length<8 && prevState.Page<this.state.Page){
        document.getElementsByClassName('next')[0].style.visibility='hidden';
        this.setState({
          Page_count : this.state.Page_count-1
        })
      }
      else{
        document.getElementsByClassName('next')[0].style.visibility='visible';
        this.setState({
          Page_count : this.state.Page
        })

      }
      }
      if((prevState.Page !== this.state.Page && this.state.search!="")|| prevState.search!=this.state.search){
        this.getarticles_search();
        this.getNextarticles_search();
        this.getPrevarticles_search();
        this.setState({Page_count:1})

      }
      

    }
  
  

  render() {
    const regex = /(<([^>]+)>)/gi;
    // let p=document.querySelectorAll('[aria-label^="Page"]');
    // console.log("aria label",p);
    // p.forEach(element =>element.parentElement.removeChild(element));
    
    return (
      <div>
       <Breadcrumb
          routeSegments={[{ name: "Articles" }]}
        ></Breadcrumb>
        <section className="product-cart">
        <div className="col-sm-3 col-md-3 mb-2">
                    <div className="d-flex justify-content-lg-start">
                      <span className="mr-1">Rechercher:</span>
                      <div className="mr-1">
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder=""
                          onChange={this.handleSearch}
                          aria-controls="ul-contact-list"
                        />
                      </div>
                    </div>
                  </div>
          {!this.state.Loading?
          (
            <font size="7">
            <div align="center">
              <div className="spinner spinner-primary mr-3"></div>
            </div>
          </font>
          ):
          (<div>
          </div>)}
          <div className="row list-grid mb-4">

            {
            !this.state.articles ? (
            <div className="align-center">
                  <span className="spinner-glow spinner-glow-primary mr-3 "></span>{" "}
              </div>) :
              (
              this.state.articles.map((article) => (
                <div className="list-item col-md-3 ">
                <div className="card o-hidden mb-4 d-flex flex-column">
                  <div className="list-thumb d-flex">
                    <img alt="" src={article.image}/>
                  </div>
                  <div className="flex-grow-1 d-bock">
                    <div className="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center">
                      <Link className="w-40 w-sm-100" to={{pathname :`${article.source}`}} target="_blank">
                      <div className="text-center ">
                         <h5 className="text-primary"><b>{article.post_title}...</b></h5>
                        </div>               
                      </Link>
                      <p className="m-0 text-muted text-small w-15 w-sm-100">
                      {article.post_content.replace(regex, "").length>200?(article.post_content.replace(regex, "").substring(1,200)):(article.post_content.replace(regex, ""))}...
                

                      </p>
                      {/* <p className="m-0 text-muted text-small w-15 w-sm-100">
                        $32.00 <del className="text-secondary">$54.00</del>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              )))
              }
                 
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={""}
            breakClassName={"break-me"}
            pageCount={this.state.Page_count+1}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination pagination-lg"}
            subContainerClassName={"pages"}
            activeClassName={"active"}
            className="ml-3 px-3"
          />
        </section>
      </div>
    );
  }
}

export default ArticlesList;
