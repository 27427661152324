import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NotificationManager,
} from "react-notifications"; import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";

class CategoriesPro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplois: [],
            loading: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        this.setState({ loading: true });
        const x = await strapi.getDataset_emploi_ANAPEC();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false });
        } else {
            this.setState({ emplois: x });
            this.setState({ loading: false });
        }
    }

    async update(row, value, column) {
        this.setState({ loading: true });
        const x = await strapi.updateDataset_emploianapec(row, value, column);
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false });
        } else {
            this.setState({ loading: false });
            this.getData();
        }
    }

    handleSubmitClick = async () => {
        this.setState({ loading: true })
        const result = await strapi.disableDataset_emploi_anapec(this.state.selectedItem.id, !this.state.selectedItem.isActive)
        if (result) {
            this.setState({ loading: false })
            this.getData()

        } else {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false })
        }
        this.setState({ modifying: false })
    }


    columns = [
        {
            id: 1,
            dataField: "emploi_metier",
            text: "Emploi-Métier ANAPEC",
        },
        {
            id: 2,
            dataField: "lexique",
            text: "Lexique",
        },
        {
            id: 3,
            dataField: "coefficient_aj",
            text: "Coefficient d'ajustement (%)",
        },

    ];

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.setState({ modifying: true, selectedItem: row });
            console.log(this.state.selectedItem);
        }
    };

    rowStyle = (row, rowIndex) => {
        const style = {};
        if (this.state.selectedItem) {
            if (row.id === this.state.selectedItem.id) {
                style.backgroundColor = "#c7a5bc"
            }
        }
        if (!row.isActive) {
            style.fontWeight = "bold";
            style.color = "red";
        }
        return style;;
    };

    paginationOptions = {
        // custom: true,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: "First",
        prePageText: "Précédent",
        nextPageText: "Suivante",
        lastPageText: "Dernière",
        nextPageTitle: "Page Suivante",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        sizePerPageList: [
            {
                text: "15",
                value: 15,
            },
            {
                text: "30",
                value: 30,
            },
            // {
            //   text: "All",
            //   value: {this.state.userList}.length,
            // },
        ],
        showTotal: true,
        totalSize: 15,
    };
    render() {
        return (
            <div>
                <SimpleCard
                    style={{
                        marginLeft: window.innerWidth - 750,
                        top: 220,
                        position: 'fixed',
                        overflowY: 'hidden',
                        overflowx: 'hidden',
                        // backgroundColor : 'F9F6F4'
                        // paddingRight : 0
                        // width: 1200
                    }}>

                    <div className="d-flex justify-content-end mr-lg-4">

                        <RefModal
                            data={() => this.getData()}
                            type="emploianapec"
                            title="Ajouter un Emploi-Métier ANAPEC"
                            op="ajout"
                        ></RefModal>
                        {this.state.selectedItem && this.state.modifying && <RefModal
                            data={() => this.getData()}
                            type="emploianapec"
                            title="Modifier"
                            op="modification"
                            nom={this.state.selectedItem.emploi_metier}
                            lexique={this.state.selectedItem.lexique}
                            coef={this.state.selectedItem.coefficient_aj}
                            id={this.state.selectedItem.id}
                        ></RefModal>}

                        {this.state.selectedItem && this.state.modifying && <Button
                            variant="secondary"
                            className="btn-icon m-1"
                            onClick={this.handleSubmitClick}
                            visible={!this.state.modifying}
                        >
                            <span className="ul-btn__text"> {this.state.selectedItem.isActive ? "Désactiver" : "Activer"}</span>

                        </Button>
                        }
                        {this.state.selectedItem && this.state.modifying && <Button
                            variant="secondary"
                            className="btn-icon m-1"
                            onClick={() => this.setState({ modifying: false, selectedItem: [] })}>
                            <span className="ul-btn__text"> Annuler </span>
                        </Button>}



                    </div>
                </SimpleCard>
                {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
                <br />
                <br />
                <br />
                <br />
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    // loading="true"id
                    data={this.state.emplois}
                    columns={this.columns}
                    // cellEdit={cellEditFactory({
                    //     mode: "dbclick",
                    //     blurToSave: true,
                    //     afterSaveCell: (oldValue, newValue, row, column) => { oldValue === newValue ? console.log("Nothing changed") : this.update(row, newValue, column.id) },
                    //     onStartEdit: (row) => { this.setState({ modifying: true, selectedItem: row }) },

                    // })}
                    noDataIndication={"Aucun enregistrement"}
                    rowStyle={this.rowStyle}
                    rowEvents={this.rowEvents}
                />
            </div>
        );
    }
}

export default CategoriesPro;
