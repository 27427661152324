import React, { Component } from "react";
import { Breadcrumb } from "app/repertoire";
import jwtAuthService from "app/services/jwtAuthService";
import ReactPaginate from "react-paginate";
import strapi from "app/services/strapi";
import dateFormat from "dateformat";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AppDataTable from "../dataTable/AppDataTable";
import SearchableDataTable from "../dataTable/SearchableDataTable";
import { Button, Popover, OverlayTrigger, Badge,Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import ModalOffre from "./ModalOffre";
import ModalDetailOffre from "./ModalDetailOffre";
import { Dropdown } from "react-bootstrap";

// const popover = (props) => (
//   <Popover id="popover-basic" {...props}>
//     {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
//     <Popover.Content>
//       Pour effectuer une recherche, veuillez retourner a la premère page !
//     </Popover.Content>
//   </Popover>
// );

class PreNormalizedOffresList extends Component {
  

  constructor(props) {
    super(props);
    const columns=[{

    }];
    // Initializing the state
    this.state = {
      pre_normalized_offre_emplois: [],
      rowsPerPage: 10,
      page: 1,
      start: 0,
      searchQuery: "",
      Loading: "",
      nbr: 0,
      alloffres: [],
      howload: false,
      showexp: true,
      showdow: false,
      datesort: true,
      headers: [
        { label: "Poste", key: "poste" },
        { label: "Secteur", key: "secteur" },

        { label: "Entreprise", key: "entreprise" },
        { label: "Fonction", key: "fonction" },

        { label: "Source", key: "source" },

        { label: "Description entreprise", key: "description_entreprise" },
        { label: "Description  poste", key: "description_poste" },
        { label: "Profil recherché", key: "profil_recherche" },
      ],
    };
  }

  getoffreBrute = async (idOffre) => {
    const x = await strapi.getDataPre_normalized_offre_emplois_By_ID(idOffre);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      return x;
    }
  };

  getoffreDetail = async (idOffre) => {
    const x = await strapi.getOffre_emplois_By_ID(idOffre);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      return x;
    }
  };

  handleSearch = ({ target: { value } }) => {
    this.setState({ searchQuery: value });
  };
  handlePageClick = (data) => {
    let page = data.selected;

    this.setState({ page }, () => {
      this.setState({ start: this.state.page * 10 }, () => {
        this.getData(this.state.start, this.state.datesort);
      });
    });
  };
  async getData(start, datasort) {
    this.setState({ Loading: true });
    const nombre = await strapi.getPre_normalized_offre_emplois_count();
    this.setState({ nbr: nombre });
    const data = await strapi.getDataPre_normalized_offre_emplois(
      start,
      datasort
    );
    if (data === false) {
      NotificationManager.error(
        "Quelque chose ne s'est passé comme prévue",
        "Erreur"
      );
      this.setState({ Loading: false });
    } else {
      this.setState({ pre_normalized_offre_emplois: data });
      this.setState({ Loading: false });
    }
  }

  async getAllOffres() {
    this.setState({
      showexp: false,
      showload: true,
    });
    const rt = await strapi.getDataExported_Pre_normalized_offre_emplois();
    this.setState({
      showexp: false,
      showload: false,
      showdow: true,
    });

    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ alloffres: rt });

      NotificationManager.success("L'exportation est terminée !", "Excel");
    }
  }

  toshowLoad() {
    this.setState({ showload: true });
  }
  tohidLoad() {
    this.setState({ showload: false });
  }
  tohidown() {
    this.setState({ showdow: false });
    this.setState({ showexp: true });
  }
  toSortDate() {
    const va = this.state.datesort;

    this.setState({ datesort: !va }, () =>
      this.getData(this.state.start, this.state.datesort)
    );
  }

  async componentDidMount() {
    this.getData(0, true);
  }

  render() {
    let { pre_normalized_offre_emplois } = this.state;

    pre_normalized_offre_emplois =
      this.state.pre_normalized_offre_emplois.filter(
        (pre_normalized_offre_emploi) =>
          pre_normalized_offre_emploi.poste
            .toLowerCase()
            .match(this.state.searchQuery.toLowerCase())
      );

    return (
      <div>
        <Breadcrumb routeSegments={[{ name: "Offres originales" }]}></Breadcrumb>
        {/* <SearchableDataTable></SearchableDataTable> */}
        <NotificationContainer />
        <section className="contact-list">
          <div className="row">
            <div className="col-sm-12">
              <div className="card text-left">
                <div className="row ">
                  {/* {this.state.showexp ? (
                    <Button
                      key="success"
                      variant={`outline-success`}
                      className="m-1"
                      onClick={() => this.getAllOffres()}
                    >
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        src="/assets/images/exce.png"
                        alt=""
                      />
                      Exporter les données
                    </Button>
                  ) : null} */}
                  {this.state.showload ? (
                    <div style={{ margin: "10px", display: "flex" }}>
                      <span className="spinner-glow spinner-glow-success mr-3 "></span>{" "}
                      <font style={{ color: "#28c76e" }} size="3">
                        En cours ...
                      </font>
                    </div>
                  ) : null}
                  <br />
                  {this.state.showdow ? (
                    <CSVLink
                      data={this.state.alloffres}
                      headers={this.state.headers}
                      filename="offres.csv"
                      asyncOnClick={true}
                      onClick={
                        () => {
                          this.tohidown();
                        } // 👍🏻 Your click handling logic
                      }
                    >
                      <font
                        style={{
                          margin: "10px",
                          display: "flex",
                          color: "#28c76e",
                        }}
                        size="5"
                      >
                        <i className="i-Download mr-2"></i>
                        <font size="3"> Télécharger</font>
                      </font>
                    </CSVLink>
                  ) : null}{" "}
                  <div className="col-sm-12 col-md-6 mb-2"></div>
                  <div className="col-sm-12 col-md-6 mb-2"></div>
                </div>
                
                {this.state.Loading == true ? (
                  <div className="card">
                      <Table 
                      hover
                      size="md"
                        id="ul-contact-list"
                        className="display table w-100"
                      >
                        <thead>
                          <tr>
                            <th>Poste</th>
                            <th>Secteur</th>
                            <th>Nombre de postes</th>
                            <th>Région</th>
                            <th>
                              Date{" "}
                              {/* <font
                                size="4"
                                style={{ marginLeft: "5px", cursor: "pointer" }}
                              >
                                <i
                                  className={
                                    this.state.datesort
                                      ? "i-Arrow-Up-2"
                                      : "i-Arrow-Down-2"
                                  }
                                  onClick={
                                    () => {
                                      this.toSortDate();
                                    } // 👍🏻 Your click handling logic
                                  }
                                ></i>
                              </font> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td></td> */}
                          </tr>
                        </tbody>
                      </Table>
                      {/* <br />
                      <br />
                      <br /> */}
                      <font size="10">
                        <div align="center">
                          <div className="spinner spinner-primary mr-3"></div>
                        </div>
                      </font>
                      {/* <br />
                      <br />
                      <br /> */}
                    <div className="d-flex justify-content-end mr-lg-4 p-2">
                      <ReactPaginate
                        previousLabel={"Précédent"}
                        nextLabel={"Suivant"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          pre_normalized_offre_emplois.length /
                          this.state.rowsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination pagination-lg"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : (
                    <div className="card-body pt-1">
                      
                        <Table 
                        hover
                        size="md"
                          striped="columns"
                          id="ul-contact-list"
                          className="display table w-100"
                        >
                          <thead className="">
                            <tr>
                              <th>Emploi-métier</th>
                              <th>Secteur</th>
                              <th>Nombre de postes</th>
                              <th>Région</th>
                              <th>Date de publication </th>
                              <th>
                                Date de récupération
                              <font
                                  size="4"
                                  style={{ marginLeft: "5px", cursor: "pointer" }}
                                >
                                  <i
                                    className={
                                      this.state.datesort
                                        ? "i-Arrow-Up-2"
                                        : "i-Arrow-Down-2"
                                    }
                                    onClick={
                                      () => {
                                        this.toSortDate();
                                      } // 👍🏻 Your click handling logic
                                    }
                                  ></i>
                                </font>
                              </th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            pre_normalized_offre_emplois
                              .slice(
                                this.state.rowsPerPage * this.state.page -
                                10 * this.state.page,
                                this.state.rowsPerPage * (this.state.page + 1) -
                                10 * this.state.page
                              )
                              .map((pre_normalized_offre_emploi) => (
                                <tr key={pre_normalized_offre_emploi.id}>
                                  <td style={{ maxWidth: "200px" }}>
                                    {pre_normalized_offre_emploi.poste}
                                  </td>
                                  <td style={{ maxWidth: "200px" }}>{pre_normalized_offre_emploi.secteur}</td>
                                  <td className="text-left px-5"style={{ maxWidth: "50px" }}>
                                    <Badge pill className="text-dark text-15 text-left self-align-center">
                                    {pre_normalized_offre_emploi.nombre_poste
                                      ? pre_normalized_offre_emploi.nombre_poste
                                      : 1}
                                  </Badge>
                                  </td>
                                  <td style={{ maxWidth: "250px" }}>{pre_normalized_offre_emploi.region}</td>
                                  <td style={{ maxWidth: "100px" }}>
                                    {dateFormat(
                                      pre_normalized_offre_emploi.created_at,
                                      "dd-mmm-yyyy"
                                    )}
                                  </td>
                                  <td style={{ maxWidth: "100px" }}>
                                    {dateFormat(
                                      pre_normalized_offre_emploi.updated_at,
                                      "dd-mmm-yyyy"
                                    )}
                                  </td>

                                  <td>
                                    <Dropdown>
                                      <Dropdown.Toggle className="mr-3 toggle-hidden border-0 bg-white d-flex flex-row">
                                        <span className="_dot _inline-dot bg-primary mr-1"></span>
                                        <span className="_dot _inline-dot bg-primary mr-1"></span>
                                        <span className="_dot _inline-dot bg-primary"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="mr-3 border-0 bg-white d-flex flex-column p-3">
                                        <ModalOffre
                                          title="Détails"
                                          centered={true}
                                          getData={() =>
                                            this.getoffreBrute(
                                              pre_normalized_offre_emploi.id
                                            )
                                          }
                                        />
                                        <br/>
                                        <ModalDetailOffre
                                          title="Offre Structurée"
                                          centered={true}
                                          getData={() =>
                                            this.getoffreDetail(
                                              pre_normalized_offre_emploi.id
                                            )
                                          }
                                        />
                                        <br/>
                                        <Button variant="primary" onClick={(e) => {
                                          e.preventDefault();
                                          window.open(pre_normalized_offre_emploi.url, '_blank');

                                        }}>
                                          Source
                                  </Button>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <ModalOffre
                                    title="Détails"
                                    centered={true}
                                    getData={() =>
                                      this.getoffreBrute(
                                        pre_normalized_offre_emploi.id
                                      )
                                    }
                                  />
                                  {"  "}
                                  <ModalDetailOffre
                                    title="Offre Structurée"
                                    centered={true}
                                    getData={() =>
                                      this.getoffreDetail(
                                        pre_normalized_offre_emploi.id
                                      )
                                    }
                                  />
                              {"  "}
                                <Button variant="primary" onClick={(e) => {
                                    e.preventDefault();
                                    window.open(pre_normalized_offre_emploi.url, '_blank');
                                    
                                  }}>
                                    Source
                                  </Button> */}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      
                      <div className="d-flex justify-content-end mr-lg-4">
                        <ReactPaginate
                          previousLabel={"Précédent"}
                          nextLabel={"Suivant"}
                          forcePage={this.state.page}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.nbr / 10}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={6}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination pagination-lg"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PreNormalizedOffresList;
