import React, { Component } from "react";

function GrapheVeille(props) {
  return (
    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={props.filters ? `https://app.powerbi.com/reportEmbed?reportId=59e81e52-a484-4436-8c64-e5be6b04e58f&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false&${props.filters}`
        :
        `https://app.powerbi.com/reportEmbed?reportId=59e81e52-a484-4436-8c64-e5be6b04e58f&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWFmcmljYS1ub3J0aC1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`
      }
      frameborder="0"
      allowFullScreen="true"
    ></iframe >


  );
}

export default GrapheVeille;
