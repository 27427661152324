import React, { Component, Fragment } from "react";
import { Breadcrumb } from "app/repertoire";
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import ModalOffre from "./ModalOffre";
import ModalAM from "./ModalAvantMod";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button, Modal, Form } from "react-bootstrap";
import jwtAuthService from "app/services/jwtAuthService";
import strapi from "app/services/strapi";
import ModalDetailOffre from "./ModalDetailOffre";
import ModalSkills from "./ModalSkills";
import Moda from "./ModalDetailOffre";
import printJS from "print-js";
import { Dropdown } from "react-bootstrap";


class OffresList extends Component {
  constructor(props) {
    super(props);

    // Initializing the state
    this.state = {
      offre_emplois: [],
      page: 0,
      start: 0,
      searchQuery: "",
      Loading: "",
      showload: false,
      showexp: true,
      showdow: false,
      reg: null,
      source: null,
      startOfYear: null,
      endOfYear: null,
      secteur_anapec: null,
      secteur_rome: null,
      showError: false,
      nbr: 0,
      datesort: true,
      nbpostesort: false,
      progress: 0,
      exportdata: [],
      alloffres: [],
      lesregs: [],
      secteurs_anapec: [],
      secteurs_rome: [],
      emploi_metier: [],
      emploi: null,
      villes: [],
      ville: null,
      test2s: [],
      csv: [],
      countcol: 0,
      selected: [],
      selectedSecteur: null,
      selectedMetier: null,
      selectedVille: null,
      searchURL: '',
      searchEmploi: '',
      showModal: false,
      radioActivated: null,
      headers: [
        { label: "Poste", key: "poste" },
        { label: "Secteur", key: "secteur" },
        { label: "Region", key: "region" },
        { label: "Nombre de postes", key: "nombre_poste" },
        { label: "Date", key: "date" },
        { label: "Type de contrat", key: "type_de_contrat" },
        { label: "Entreprise", key: "entreprise" },
        { label: "Fonction", key: "fonction" },
        { label: "Niveau d'experience", key: "niv_experience" },
        { label: "Source", key: "source" },
        { label: "URL", key: "url" },
      ],
    };
  }

  getoffreDetail = async (idOffre) => {
    const x = await strapi.getOffre_emplois_By_ID(idOffre);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      return x;
    }
  };
  getoffreSkils = async (id) => {
    const x = await strapi.getOffre_Skills_regex(id);
    const y = await strapi.getOffre_Skills_bert(id);
    let w = []
    y.forEach(element => {
      let y_array_skills = element.potential_skills.replace("]", "").replace("[", "").split(",")
      y_array_skills.forEach(
        elt => {
          w.push({
            id: y[0].id,
            id_job_offer: y[0].id_job_offer,
            potential_skill: elt

          })
        }
      )
    });

    let z = x.concat(w)

    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );

    } else if (y === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    }

    else {
      return z;
    }
  };
  // Remove from here and add it to Modal offre Component to avoid repitition
  getoffreBrute = async (idOffre) => {
    const x = await strapi.getDataPre_normalized_offre_emplois_By_ID(idOffre);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      return x;
    }
  };

  getoffreBrute;

  toshowLoad() {
    this.setState({ showload: true });
  }
  tohidLoad() {
    this.setState({ showload: false });
  }
  tohidown() {
    this.setState({ showdow: false });
    this.setState({ showexp: true });
  }
  ////////////////////////////////////////////////////////////////
  handleSearch = ({ target: { value } }) => {
    this.setState({ searchQuery: value });
  };

  ///

  handleActivateFilters = () => {
    this.getData(
      this.state.reg,
      this.state.ville,
      this.state.secteur_anapec,
      this.state.emploi,
      this.state.searchEmploi,
      this.state.start,
      this.state.nbpostesort,
      this.state.source,
      this.state.startOfYear,
      this.state.endOfYear
    );

  }

  handlePageClick = (data) => {
    let page = data.selected;

    this.setState({ page }, () => {
      this.setState({ start: this.state.page * 20 }, () => {
        this.getData(
          this.state.reg,
          this.state.ville,
          this.state.secteur_anapec,
          this.state.emploi,
          this.state.searchEmploi,
          this.state.start,
          this.state.nbpostesort,
          this.state.source,
          this.state.startOfYear,
          this.state.endOfYear
        );
      });
    });
  };

  async componentDidMount() {
    // this.getData(null, null, null, null, null, 0, false, null, null, null);
    this.getreg();
    this.getsec_anapec();
    this.getsec_rome();
    this.getVilles();
    this.getemploi();

  }

  async getreg() {
    const rt = await strapi.getDataRef_regions();
    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ lesregs: rt });
    }
  }

  async getemploi() {
    const e = await strapi.getDataset_emploi_ANAPEC();
    if (e === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ emploi_metier: e });
    }
  }
  ////////////////////////////////////////////////////////////////
  async getsec_rome() {
    const rt = await strapi.getDataRef_secteurs();

    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ secteurs_rome: rt });
    }
  }

  async getsec_anapec() {
    const rt = await strapi.getDataRef_secteurs_anapec();

    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ secteurs_anapec: rt });
    }
  }

  dateFormatter(cell, row) {
    return (
      <span>
        {moment(cell).format('DD-MM-YYYY')}
      </span>
    );
  }

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (!row.active) {
      style.backgroundColor = "#9796b0"
    } else if(parseInt(row.modified,10)){
      style.backgroundColor = "#d39e00"
    }
    
    return style;
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  onChangeSearchByURL = ({ target: { value } }) => {
    this.setState({ searchURL: value });
  };

  handleSubmitSearchByURL = async () => {
    if (this.state.searchURL.length > 1) {
      this.setState({ Loading: true })
      const sch = await strapi.getJobOffer_byURL(this.state.searchURL);
      if (sch === false) {
        NotificationManager.error(
          "Erreur lors de la connexion au serveur. Merci de patienter...",
          "Erreur"
        );
      } else {
        this.setState({ nbr: sch.length }, () => {
          this.setState({
            offre_emplois: sch,
            Loading: false
          })
        });
      }
    }

  }

  onChangeSearch = ({ target: { value } }) => {
    this.setState({ searchEmploi: value });
  };


  handleSubmitSearch = async () => {
    if (this.state.searchEmploi.length > 1) {
      this.setState({ Loading: true });
      this.getData(
        this.state.reg,
        this.state.ville,
        this.state.secteur_anapec,
        this.state.emploi,
        this.state.searchEmploi,
        this.state.start,
        this.state.nbpostesort,
        this.state.source,
        this.state.startOfYear,
        this.state.endOfYear
      );
    }
  }

  async getVilles() {
    const vl = await strapi.getDataRef_villes();

    if (vl === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ villes: vl });
    }
  }

  async getData(reg, ville, secteur_anapec, emploi, emploi_original, start, nbpostesort, source, startOfYear, endOfYear) {
    this.setState({ Loading: true, showError: false, selected: [] });
    const nombre = await strapi.getData_offre_emploi_new_count(
      reg,
      ville,
      secteur_anapec,
      emploi,
      emploi_original,
      source,
      startOfYear,
      endOfYear
    );
    this.setState({ nbr: nombre[0]?.total });
    const x = await strapi.getData_offre_emploi_new(reg, ville, secteur_anapec, emploi, emploi_original, start, nbpostesort, source, startOfYear, endOfYear);
    if (x?.code) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ Loading: false });
    } else {
      this.setState({ offre_emplois: x });
      this.setState({ Loading: false });
    }
    if (this.state.offre_emplois.length == 0) {
    }
  }



  render() {
    const columns = [
      {
        dataField: 'emploi_original',
        text: 'Emploi Original',



      }, {
        dataField: 'emploi',
        text: 'Emploi-Métier',
      },
      {
        dataField: 'secteur_original',
        text: 'Secteur Original',
      }, {
        dataField: 'secteur',
        text: 'Secteur'
      },
      {
        dataField: 'nombre_poste',
        text: 'Postes',
        style: { width: '50px', maxWidth: '50px' },
        headerStyle: { width: '80px', maxWidth: '80px' },
      },
      {
        dataField: 'region',
        text: 'Region',
        headerStyle: { width: '150px', maxWidth: '150px' },
      },
      {
        dataField: 'ville',
        text: 'Ville',
        headerStyle: { width: '120px', maxWidth: '120px' },
      },
      {
        dataField: 'date',
        text: 'Date de publication',
        formatter: this.dateFormatter,
        headerStyle: { width: '120px', maxWidth: '120px' },
      },
      {
        dataField: '',
        text: 'Actions',
        style: { width: '50px', maxWidth: '50px' },
        headerStyle: { width: '80px', maxWidth: '80px' },
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <Dropdown>
              <Dropdown.Toggle className="mr-3 mb-3 toggle-hidden border-0 bg-white d-flex flex-row">
                <span className="_dot _inline-dot bg-primary mr-1"></span>
                <span className="_dot _inline-dot bg-primary mr-1"></span>
                <span className="_dot _inline-dot bg-primary"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mr-3 border-0 bg-white d-flex flex-column p-3">
                <ModalDetailOffre
                  title="Détails"
                  centered={true}
                  getData={() =>
                    this.getoffreDetail(row.id)
                  }
                ></ModalDetailOffre>
                <br />
                <ModalOffre
                  title="Offre Originale"
                  centered={true}
                  getData={() =>
                    this.getoffreBrute(row.id)
                  }
                />
                <br />
                {row.secteur_anapec_avant_mod || row.emploi_anapec_avant_mod || row.ville_avant_mod &&
                  <ModalAM
                    title="Offre avant modification"
                    centered={true}
                    secteur_avant={this.state.secteurs_anapec.find(e => e.id === row.secteur_anapec_avant_mod)?.Nom}
                    ville_avant={this.state.villes.find(e => e.id == row.ville_avant_mod)?.ville}
                    emploi_avant={this.state.emploi_metier.find(e => e.id === row.emploi_anapec_avant_mod)?.emploi_metier}
                    secteur={row?.secteur}
                    ville={row?.ville}
                    emploi={row?.emploi}
                  />}

                {row.secteur_anapec_avant_mod || row.emploi_anapec_avant_mod || row.ville_avant_mod && <br />}

                <Button variant="primary" onClick={(e) => {
                  e.preventDefault();
                  window.open(row.url, '_blank');

                }}>
                  Source
                                  </Button>
                <br />
                <ModalSkills
                  title="Compétences"
                  centered={true}
                  getData={async () =>
                    this.getoffreSkils(row.id)
                  }
                ></ModalSkills>
                <br />


              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        formatExtraData: this.state.count
      },

    ];

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <div>
        <Breadcrumb
          routeSegments={[{ name: "Offres Structurées" }]}
        ></Breadcrumb>

        <NotificationContainer />
        <section className="contact-list">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="card text-left">
                <div className="row px-4 mt-3">
                  <div className="form-inline ml-5 mb-5">
                    <input
                      className="form-control"
                      placeholder="Saisir l'URL de l'offre"
                      id="search"
                      type="string"
                      value={this.state.searchURL}
                      onChange={this.onChangeSearchByURL}
                    />
                    <Button
                      variant="secondary"
                      className="btn-icon m-1"
                      onClick={this.handleSubmitSearchByURL}
                    >
                      <span className="ul-btn__text"> Recherche par URL</span>
                    </Button>
                  </div>
                  <div className="form-inline ml-5 mb-5">
                    <input
                      className="form-control"
                      placeholder="Saisir l'Emploi-Métier"
                      id="search"
                      type="string"
                      value={this.state.searchEmploi}
                      onChange={this.onChangeSearch}
                    />
                    <Button
                      variant="secondary"
                      className="btn-icon m-1"
                      onClick={this.handleSubmitSearch}
                    >
                      <span className="ul-btn__text"> Recherche par Emploi métier Original</span>
                    </Button>
                  </div>
                </div>
                <div className="row px-4 mt-3">
                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Région</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listreg" list="listreg"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ reg: null });
                            } else {
                              this.setState({ reg: this.state.lesregs.find(e => e.Nom === target.value)?.id });

                            }
                          }} />
                        <datalist
                          id="listreg"
                        >
                          <option value="">Réinit.</option>
                          {this.state.lesregs.map((r) => (
                            <option key={r.id}>{r.Nom}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>


                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Ville</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listvilles" list="listvilles"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ ville: null });
                            } else {
                              this.setState({ ville: this.state.villes.find(e => e.ville === target.value)?.id });

                            }
                          }} />
                        <datalist
                          id="listvilles"
                        >
                          <option value="">Réinit.</option>
                          {this.state.villes.map((v) => (
                            <option>{v.ville}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Secteur</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listsecteurs" list="listsecteurs"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ secteur_anapec: null });
                            } else {
                              this.setState({ secteur_anapec: this.state.secteurs_anapec.find(e => e.Nom === target.value)?.id });

                            }
                          }} />
                        <datalist
                          id="listsecteurs"
                        >
                          <option value="">Réinit.</option>
                          {this.state.secteurs_anapec.map((s) => (
                            <option>{s.Nom}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center pr-3">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Emploi-Métier</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listemploi" list="listemploi"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ emploi: null });
                            } else {
                              this.setState({ emploi: this.state.emploi_metier.find(e => e.emploi_metier === target.value)?.id });

                            }
                          }} />
                        <datalist
                          id="listemploi"
                        >
                          <option value="">Réinit.</option>
                          {this.state.emploi_metier.map((m) => (
                            <option>{m.emploi_metier}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center pr-3">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Source</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listsrc" list="listsrc"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ source: null });
                            } else {
                              this.setState({ source: target.value });

                            }
                          }} />
                        <datalist
                          id="listsrc"
                        >
                          <option value="" />
                          <option value="anapec" />
                          <option value="emploi" />
                          <option value="marocannonces" />
                          <option value="m-job" />
                          <option value="marocadres" />
                          <option value="rekrute" />
                          <option value="offre-emploi" />
                        </datalist>

                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2 mb-2">
                    <div className="d-flex flex-row align-items-center pr-3">
                      <strong className="text-sm-left  heading text-primary">
                        <span className="mr-1">Année</span>
                      </strong>
                      <div className="mr-1">
                        <input className="form-control" type="text" name="listannee" list="listannee"
                          onChange={async ({ target }) => {
                            const promise = new Promise((resolve, reject) => {
                              resolve(this.setState({ page: 0 }));
                            });
                            await promise;
                            this.setState({ start: this.state.page * 20 });
                            if (target.value == "") {
                              this.setState({ startOfYear: null, endOfYear: null });
                            } else {
                              this.setState({
                                startOfYear: target.value + "-01-01",
                                endOfYear: target.value + "-12-31"
                              });
                            }
                          }} />
                        <datalist
                          id="listannee"
                        >
                          <option value="" />
                          <option value="2017" />
                          <option value="2018" />
                          <option value="2019" />
                          <option value="2020" />
                          <option value="2021" />
                          <option value="2022" />
                          <option value="2023" />
                          <option value="2024" />
                        </datalist>

                      </div>
                      <div className="d-flex justify-content-center">
                        <strong className="text-sm-left heading text-primary">
                          <span className="mr-1"></span>
                        </strong>
                        <Button
                          variant="primary"
                          className="btn-icon m-1"
                          onClick={this.handleActivateFilters}
                        >
                          <span>Rechercher</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Fragment>
                    <Modal
                      show={this.state.showModal}
                      onHide={() => this.setState({
                        showModal: false
                      })}
                      size="lg"
                      scrollable={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Modification des offres sélectionnées ({this.state.selected.length})</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <strong className="text-sm-left  heading text-primary">
                          <span className="mr-1">Modifier le Secteur</span>
                        </strong>
                        <div className="mr-1">
                          <input className="form-control" type="text" name="listsecteurs" list="listsecteurs" id="listsecteurs"
                            onChange={async ({ target }) => {
                              if (target.value == "") {
                                this.setState({ selectedSecteur: null });
                              } else {
                                this.setState({ selectedSecteur: this.state.secteurs_anapec.find(e => e.Nom === target.value)?.id });

                              }
                            }}
                          />
                          <datalist
                            id="listsecteurs"
                          >
                            <option value="">Réinit.</option>
                            {this.state.secteurs_anapec.map((s) => (
                              <option>{s.Nom}</option>
                            ))}
                          </datalist>
                        </div>
                        <strong className="text-sm-left  heading text-primary">
                          <span className="mr-1">Modifier l'Emploi-Métier</span>
                        </strong>
                        <div className="mr-1">
                          <input className="form-control" type="text" name="listemploi" list="listemploi" id="listemploi"
                            onChange={async ({ target }) => {
                              if (target.value == "") {
                                this.setState({ selectedMetier: null });
                              } else {
                                this.setState({ selectedMetier: this.state.emploi_metier.find(e => e.emploi_metier === target.value)?.id });

                              }
                            }}
                          />
                          <datalist
                            id="listemploi"
                          >
                            <option value="">Réinit.</option>
                            {this.state.emploi_metier.map((m) => (
                              <option>{m.emploi_metier}</option>
                            ))}
                          </datalist>
                        </div>
                        <strong className="text-sm-left  heading text-primary">
                          <span className="mr-1">Modifier la Ville</span>
                        </strong>
                        <div className="mr-1">
                          <input className="form-control" type="text" name="listvilles" list="listvilles" id="listvilles"
                            onChange={async ({ target }) => {
                              if (target.value == "") {
                                this.setState({ selectedVille: null });
                              } else {
                                this.setState({ selectedVille: this.state.villes.find(e => e.ville === target.value)?.id });

                              }
                            }}
                          />
                          <datalist
                            id="listvilles"
                          >
                            <option value="">Réinit.</option>
                            {this.state.villes.map((v) => (
                              <option>{v.ville}</option>
                            ))}
                          </datalist>
                        </div>
                        <br />
                        <div>
                          {/* <Form>
                            <strong className="text-sm-left  heading text-primary">
                              <span className="mr-1">Activer / Désactiver</span>
                            </strong>
                            <Form.Check
                              type="switch"
                              id="custom-switch 5"
                              label=""
                              checked={this.state.radioActivated}
                              onChange={() => {
                                this.setState({
                                  radioActivated: !this.state.radioActivated
                                })
                              }}
                            />
                          </Form> */}
                          <div className="d-flex flex-row">
                            <strong className="text-sm-left  heading text-primary">
                              <span className="mr-1">Activer</span>
                            </strong>
                            <input
                              name="r1"
                              type="radio"
                              checked={true === this.state.radioActivated}
                              onChange={e => {
                                this.setState({ radioActivated: true });
                              }}
                            />
                            <strong className="text-sm-left  heading text-primary">
                              <span className="mr-1">{" "}</span>
                            </strong>
                            <strong className="text-sm-left  heading text-primary">
                              <span className="mr-1">Désactiver</span>
                            </strong>
                            <input
                              name="r2"
                              type="radio"
                              checked={false === this.state.radioActivated}
                              onChange={e => {
                                this.setState({ radioActivated: false });
                              }}
                            />
                          </div>
                        </div>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          variant="primary"
                          className="btn-icon m-1"
                          onClick={async () => {
                            if (this.state.selectedMetier || this.state.selectedSecteur || this.state.selectedVille || this.state.radioActivated || !this.state.radioActivatedf ) {
                              this.setState({ Loading: true })
                              this.state.selected.forEach(async element => {
                                const exists = await strapi.offre_emploi_correction_exists(element);
                                if (exists.length > 0) {
                                  const region = this.state.villes.find((e) => e.id === this.state.selectedVille)
                                  const update = await strapi.updateOffreEmploi_Correction(exists[0].id, this.state.selectedSecteur, this.state.selectedMetier, this.state.selectedVille, region?.id_region?.id);
                                  if (update) {
                                    this.setState({selected: [], offre_emplois: [], nbr: 0 })
                                    const x = await strapi.disable_offre_emploi(exists[0].id_offre, this.state.radioActivated)
                                    if (x) {
                                      NotificationManager.success("Modification effectuée avec succès", "succès")
                                      this.setState({ Loading: false, showModal: false })
                                      setTimeout(() => {

                                      }, 1000);
                                      window.location.reload();
                                    } else {
                                      NotificationManager.error(
                                        "Erreur lors de la connexion au serveur. Merci de patienter...",
                                        "Erreur"
                                      );
                                      this.setState({ Loading: false })
                                    }

                                    window.location.reload();
                                  } else {
                                    this.setState({ Loading: false })
                                    NotificationManager.error(
                                      "Erreur lors de la connexion au serveur. Merci de patienter...",
                                      "Erreur"
                                    );
                                  }
                                } else {
                                  const element_date = this.state.offre_emplois.find((e) => e.id === element)?.date
                                  const region = this.state.villes.find((e) => e.id === this.state.selectedVille)
                                  const insert = await strapi.insertOffreEmploi_Correction(element, element_date, this.state.selectedSecteur, this.state.selectedMetier, this.state.selectedVille, region?.id_region?.id);
                                  if (insert) {
                                    this.setState(
                                      { selected: [], offre_emplois: [], nbr: 0 })
                                    const x = await strapi.disable_offre_emploi(element, this.state.radioActivated)
                                    if (x) {
                                      NotificationManager.success("Modification effectuée avec succès", "succès")
                                      this.setState({ Loading: false, showModal: false })
                                      setTimeout(() => {

                                      }, 1000);
                                      window.location.reload();
                                    } else {
                                      NotificationManager.error(
                                        "Erreur lors de la connexion au serveur. Merci de patienter...",
                                        "Erreur"
                                      );
                                    }
                                  } else {
                                    this.setState({ Loading: false })
                                    NotificationManager.error(
                                      "Erreur lors de la connexion au serveur. Merci de patienter...",
                                      "Erreur"
                                    );
                                  }
                                }
                              });
                            }

                          }}
                        >
                          <span className="ul-btn__text">Enregistrer les changements</span>

                        </Button>
                        <Button variant="secondary" onClick={() => this.setState({
                          showModal: false
                        })}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Fragment>
                </div>
                <br />
                <br />
                <Button
                  variant="primary"
                  className="btn-icon m-1"
                  hidden={this.state.selected.length === 0}
                  onClick={() => this.setState({ showModal: true })}
                >
                  <span className="ul-btn__text">Editer les offres ({this.state.selected.length})</span>
                </Button>
                {this.state.Loading == true ? (
                  <div className="card-body pt-1">
                    <div className="table-responsive">
                      <table
                        id="ul-contact-list"
                        className="display table w-100"
                      >
                        <thead>
                          <tr>
                            <th>Emploi-métier Original</th>
                            <th>Emploi-métier</th>
                            <th>Secteur Original</th>
                            <th>Secteur</th>
                            <th>Nombre de postes</th>
                            <th>Région</th>
                            <th>Date de publication</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <br />
                      <br />
                      <font size="7">
                        <div align="center">
                          <div className="spinner spinner-primary mr-3"></div>
                        </div>
                      </font>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                ) : (
                    <div className="card-body pt-1">

                      {localStorage.getItem("role") == "Super Admin" &&
                        <BootstrapTable
                          keyField='id'
                          data={this.state.offre_emplois}
                          columns={columns}
                          selectRow={selectRow}
                          rowStyle={this.rowStyle}
                        />}
                      {localStorage.getItem("role") == "Authenticated" &&
                        <BootstrapTable
                          keyField='id'
                          data={this.state.offre_emplois}
                          columns={columns}
                        />}
                      {/* {console.log(this.state.nbr)} */}
                      <div className="d-flex justify-content-end mr-lg-4">
                        <ReactPaginate
                          previousLabel={"Précédent"}
                          nextLabel={"Suivant"}
                          breakLabel={"..."}
                          forcePage={this.state.page}
                          // initialPage={0}
                          breakClassName={"break-me"}
                          pageCount={this.state.nbr / 20}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={6}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination pagination-lg"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                  )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default OffresList;
