import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NotificationManager,
} from "react-notifications"; import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";

class SecteurANAPEC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secteurs_anapec: [],
      loading: false,
      modifying: false,
      selectedItem: []
    };
  }

  componentDidMount() {
    this.getData();
  }



  async getData() {
    this.setState({ loading: true });
    const x = await strapi.getDataset_secteuranapec();
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false });
    } else {
      this.setState({ secteurs_anapec: x });
      this.setState({ loading: false });
    }
  }

  async update(row, value, column) {
    this.setState({ loading: true, modifying: false });
    const x = await strapi.updateDataset_secteuranapec(row, value, column);
    if (x === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false });
    } else {
      this.getData();
    }
  }

  columns = [
    {
      id: 1,
      dataField: "secteur_anapec_id.Nom",
      text: "Secteur ANAPEC",
      // editable: true,
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 }
    },
    {
      id: 2,
      dataField: "lexique",
      text: "Lexique",
      // editable: true,
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 200 }
    },
    {
      id: 3,
      dataField: "secteur_anapec_id.coefficient_aj",
      text: "Coefficient d'ajustement (%)",
      // editable: true,
      // style: {textAlign : "center"},
      // editor: {
      //   type: Type.TEXTAREA,
      // },
      // editorStyle: { height: 50 }
    },

  ];

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.setState({ modifying: true, selectedItem: row })
    }
  };

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (this.state.selectedItem) {
      if (row.id === this.state.selectedItem.id) {
        style.backgroundColor = "#c7a5bc"
      }
    }
    if (!row.secteur_anapec_id.isActive) {
      style.fontWeight = "bold";
      style.color = "red";
    }
    return style;;
  };


  handleSubmitClick = async () => {
    this.setState({ loading: true })
    const result = await strapi.disableDataset_secteurAnapec(this.state.selectedItem.secteur_anapec_id.id, !this.state.selectedItem.secteur_anapec_id.isActive)
    if (result) {
      // let copie_region = [... this.state.regions];
      // let selected = copie_region.find((e)=> e.secteur_anapec_id.id = this.state.selectedItem.secteur_anapec_id.id)
      // copie_region[selected.id - 1] = {id : selected.id, secteur_anapec_id : {id :selected.secteur_anapec_id.id, Nom: selected.secteur_anapec_id.Nom, isActive: !selected.secteur_anapec_id.isActive }, lexique : selected.lexique }
      // console.log(copie_region);
      // this.setState({ regions: copie_region, loading : false })
      this.setState({ loading: false })
      this.getData()

    } else {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
      this.setState({ loading: false })
    }
    this.setState({ modifying: false })
  }

  // paginationOptions = {
  //   // custom: true,
  //   paginationSize: 5,
  //   pageStartIndex: 1,
  //   firstPageText: "First",
  //   prePageText: "Précédent",
  //   nextPageText: "Suivante",
  //   lastPageText: "Dernière",
  //   nextPageTitle: "Page Suivante",
  //   prePageTitle: "Pre page",
  //   firstPageTitle: "Next page",
  //   lastPageTitle: "Last page",
  //   sizePerPageList: [
  //     {
  //       text: "15",
  //       value: 15,
  //     },
  //     {
  //       text: "30",
  //       value: 30,
  //     },
  //     // {
  //     //   text: "All",
  //     //   value: {this.state.userList}.length,
  //     // },
  //   ],
  //   showTotal: true,
  //   totalSize: 15,
  // };

  // rowStyle = (row, rowIndex) => {
  //   // if(!row.isActive){
  //   //   return <p style={{backgroundColor : "gray"}}></p>
  //   // }
  //   console.log("ROW",row);
  // };

  render() {
    return (
      <div>
        <SimpleCard
          style={{
            marginLeft: window.innerWidth - 750,
            top: 220,
            position: 'fixed',
            overflowY: 'hidden',
            overflowx: 'hidden',
            // backgroundColor : 'F9F6F4'
            // paddingRight : 0
            // width: 1200
          }}>

          <div className="d-flex justify-content-end mr-lg-4">
            <RefModal
              data={() => this.getData()}
              type="anapec"
              title="Ajouter un secteur ANAPEC"
              op="ajout"
            ></RefModal>
            {this.state.selectedItem.secteur_anapec_id && this.state.modifying && <RefModal
              data={() => this.getData()}
              type="anapec"
              title="Modifier"
              op="modification"
              nom={this.state.selectedItem.secteur_anapec_id.Nom}
              lexique={this.state.selectedItem.lexique}
              coef={this.state.selectedItem.secteur_anapec_id.coefficient_aj}
              id_secteur={this.state.selectedItem.secteur_anapec_id.id}
              id_data={this.state.selectedItem.id}
            ></RefModal>}
            {this.state.selectedItem.secteur_anapec_id && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={this.handleSubmitClick}
              visible={!this.state.modifying}
            >
              <span className="ul-btn__text"> {this.state.selectedItem.secteur_anapec_id.isActive ? "Désactiver" : "Activer"}</span>

            </Button>
            }
            {this.state.selectedItem.secteur_anapec_id && this.state.modifying && <Button
              variant="secondary"
              className="btn-icon m-1"
              onClick={() => this.setState({ modifying: false, selectedItem : []})}>
              <span className="ul-btn__text"> Annuler </span>
            </Button>}






          </div>
        </SimpleCard>

        {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
        <br />
        <br />
        <br />
        <br />
        <BootstrapTable
          bootstrap4
          keyField="id"
          // loading="true"
          data={this.state.secteurs_anapec}
          columns={this.columns}
          // cellEdit={cellEditFactory({
          //   mode: "dbclick",
          //   blurToSave: true,
          //   afterSaveCell: (oldValue, newValue, row, column) => { oldValue === newValue ? console.log("Nothing changed") : this.update(row, newValue, column.id) },
          //   onStartEdit: (row) => { this.setState({ modifying: true, selectedItem: row }) },

          // })}
          noDataIndication={"Aucun enregistrement"}
          rowStyle={this.rowStyle}
          rowEvents={this.rowEvents}
        />
      </div>
    );
  }
}

export default SecteurANAPEC;
