import React, { Component, useRef } from "react";
import { Breadcrumb } from "app/repertoire";
import ReactPaginate from "react-paginate";
import strapi from "app/services/strapi";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

class Correspondance_emploi extends Component {
    constructor(props) {
        super(props);

        // Initializing the state
        this.state = {
            correspondances: [],
            emploi_anapec: [],
            DropDownLoading: false,
        };

        this.columns = [
            {
                dataField: "emploi_original",
                text: "Emploi-Métier Offre",
                editable: false,
            },
            {
                id: 1,
                className: "anapec",
                dataField: "emploi_anapec",
                text: "Emploi-Métier ANAPEC",
                editable: true,
                editor: {
                    type: Type.SELECT,
                    getOptions: () => {
                        let oa = [];
                        this.state.emploi_anapec.forEach(element => {
                            oa.push({ label: element.emploi_metier, value: element.emploi_metier })
                        });
                        return oa;
                    }

                }
                // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                //     <Secteurs_Dropdown row={row} columnIndex={columnIndex} options={this.state.secteurs_anapec} onChange={this.onChange} isLoading={this.state.DropDownLoading} />
                // ),
            },
        ]

        // function Secteurs_Dropdown(props) {
        //     return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} id="div">
        //         <select className="form-control" style={{ width: '230px' }}

        //             onChange={async ({ target: { value, name } }) => {
        //                 props.onChange(props.columnIndex, props.row.id, value);
        //             }}>
        //             <option selected key={0} value={0}>Choisir le secteur correspondant </option>
        //             {props.options.map((sec) => (
        //                 <option key={sec.id} name={sec.Nom} value={sec.id}>{sec.Nom} </option>
        //             ))}
        //         </select>
        //         {props.isLoading && <Spinner
        //             style={{ marginTop: 10 }}
        //             animation="border"
        //             size="sm" />}
        //     </div>);


        // }
    }

    // onChange = async (columnIndex, id, value) => {
    //     this.setState({ DropDownLoading: true })
    //     const x = await strapi.updateSecteurCorrespondance(columnIndex, id, value);
    //     if (x === false) {
    //         NotificationManager.error(
    //             "Erreur lors de la connexion au serveur. Merci de patienter...",
    //             "Erreur"
    //         );
    //         this.setState({ DropDownLoading: false })
    //     } else {
    //         this.setState({ DropDownLoading: false })
    //         let copie_correspondance = [... this.state.correspondances];
    //         copie_correspondance[id - 1] = { id: x.id, secteur_offre: x.secteur_offre, secteur_anapec: x.secteur_anapec, secteur_rome: x.secteur_rome };
    //         this.setState({ correspondances: copie_correspondance })
    //         // 

    //     }
    // }

    getEmploiCorrespondance = async () => {
        const x = await strapi.getEmploiCorrespondance_ANAPEC_view();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
        } else {
            this.setState({ correspondances: x });
        }
    };

    getEmploiANAPEC = async () => {
        const x = await strapi.getDataset_emploi_ANAPEC();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
        } else {
            this.setState({ emploi_anapec: x });
        }
    }

    toshowLoad() {
        this.setState({ showload: true });
    }
    tohidLoad() {
        this.setState({ showload: false });
    }

    toSortDate() {
        const va = this.state.datesort;

        this.setState({ datesort: !va }, () =>
            this.getData(this.state.start, this.state.datesort)
        );
    }

    async componentDidMount() {
        this.setState({ Loading: true });
        this.getEmploiCorrespondance();
        this.getEmploiANAPEC();
        this.setState({Loading : false})

    }


    render() {

        return (
            <div id="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Matrice de correspondance des Emplois-Métier ANAPEC" }]} ></Breadcrumb>
                <NotificationContainer />
                <section className="contact-list">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="card text-left">
                                {this.state.Loading == true ? (
                                    <div className="card-body pt-1">
                                        <div className="table-responsive">
                                            {/* <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                data={this.state.correspondances}
                                                columns={this.columns}
                                                noDataIndication={"Aucun enregistrement"}
                                            /> */}
                                            <font size="7">
                                                <div align="center">
                                                    <div className="spinner spinner-primary mr-3"></div>
                                                </div>
                                            </font>
                                        </div>
                                    </div>
                                ) : (
                                        <div className="card-body pt-1">
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    data={this.state.correspondances}
                                                    columns={this.columns}
                                                    cellEdit={cellEditFactory({
                                                        mode: "dbclick",
                                                        blurToSave: true,
                                                        afterSaveCell: async (oldValue, newValue, row, column) => {
                                                            if (oldValue === newValue || !newValue) {
                                                            } else {
                                                                // this.setState({ Loading: true });
                                                                const id = this.state.emploi_anapec.find((e) => e.emploi_metier === newValue).id;
                                                                const correspondance_exists = await strapi.EmploiCorrespondanceExists(row.emploi_original);
                                                                if (correspondance_exists.length > 0) {
                                                                    const x = await strapi.updateCorrespondance_emploi(correspondance_exists[0].id, id);
                                                                    if (x === false) {
                                                                        NotificationManager.error(
                                                                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                            "Erreur"
                                                                        );
                                                                        // this.setState({ Loading: false });
                                                                    } else {
                                                                        this.setState({ Loading: false });
                                                                        this.getEmploiCorrespondance();
                                                                        this.getEmploiANAPEC();
                                                                    }
                                                                } else {
                                                                    // this.setState({ Loading: true });
                                                                    const new_correspondance = await strapi.addEmploiCorrespondance(row.emploi_original);
                                                                    if (new_correspondance) {
                                                                        const x = await strapi.updateCorrespondance_emploi(new_correspondance.id, id);
                                                                        if (x === false) {
                                                                            NotificationManager.error(
                                                                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                "Erreur"
                                                                            );
                                                                            // this.setState({ Loading: false });
                                                                        } else {
                                                                            this.setState({ Loading: false });
                                                                            this.getEmploiCorrespondance();
                                                                            this.getEmploiANAPEC();
                                                                        }

                                                                    } else {
                                                                        NotificationManager.error(
                                                                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                            "Erreur"
                                                                        );
                                                                        this.setState({ Loading: false });
                                                                    }

                                                                }




                                                            }
                                                        }
                                                    })}
                                                    noDataIndication={"Aucun enregistrement"}
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Correspondance_emploi;
