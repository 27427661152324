import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import strapi from "app/services/strapi";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const ModifyModal = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const [test, setTest] = useState(null);

  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
  });

  const getData = async () => {
    setShow(true);
    const data = await props.getData();
    setUser(data);
  };

  // const { username, email, password } = product;
  //   const [state, setState] = useState({
  //     name: "",
  //   });
  //   const [fields, handleFieldChange] = useFormFields({
  //     name: "",
  //   });

  const handleClose = () => {
    setShow(false);
  };


  const userhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      username: value,
    }));

  };
  const emailhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      email: value,
    }));

  };
  const passhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      password: value,
    }));

  };

  const rolehandleClick = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      role: value,
    }));

  };
  //   const getData = async () => {
  //     setShow(true);
  //     const data = await props.postData();
  //     setTest(data);
  //   };

  const reload = async () => {

    const data = await props.reloadData();
  };

  const handleSubmitClick = async () => {

    const rt = await strapi.postModifyTests(props.id, user);


    setShow(false);

    setUser((prevState) => ({
      ...prevState,

      username: "",
      email: "",
      password: "",
      role: "",
    }));


    reload();
  };

  return (
    <Fragment>
      <i
        className="nav-icon i-Pen-2 font-weight-bold"
        onClick={() => {
          getData();
        }}
      ></i>

      <Modal show={show} onHide={handleClose} {...props}>
        <Modal.Header closeButton>
          <Modal.Title>Mofifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <form onSubmit={handleSubmitClick}>
            <div className="row">
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="firstName">Username</label>
                <input
                  // id="name"
                  className="form-control"
                  placeholder="Enter your Username"
                  id="username"
                  type="text"
                  onChange={userhandleChange}
                  value={user.username}
                />
                <label htmlFor="firstName">Email</label>
                <input
                  id="email"
                  className="form-control"
                  placeholder="Enter your Email"
                  type="text"
                  onChange={emailhandleChange}
                  value={user.email}
                />
                <label htmlFor="firstName">Password</label>
                <input
                  id="pass"
                  className="form-control"
                  placeholder="Enter your Password"
                  type="password"
                  onChange={passhandleChange}
                  value={user.password}
                />
                <label htmlFor="firstName">Role</label>

                <select
                  onChange={rolehandleClick}
                  className="custom-select task-manager-list-select"
                >
                  <option>Choisir ...</option>
                  <option value={1}>Authencitated</option>
                  <option value={3}>Super admin</option>
                </select>
              </div>{" "}
            </div>{" "}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitClick}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModifyModal;
