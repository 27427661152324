import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import OffresList from "./views/offres/OffresList";
import PreNormalizedOffresList from "./views/offres/PreNormalizedOffresList";
import Dashboard1 from "./views/pages/Dashboard1";


import Referentiel from "./views/referentiel/Referentiel";
import ScrapingScript from "./views/scraping/ScrapingScript";
import Statistique from "./views/statistiques/Statistique";
import Stat2 from "./views/statistiques/Stat2";
import GestionUtilisateurs from "./views/gestion-utilisateurs/GestionUtilisateurs";
import Correspondance from "./views/correspondance/Correspondance";
import Correspondance_emploi from "./views/correspondance/Correspondance_emploi"
import Evolution_nbr_postes from "./views/graphes/Evolution_nbr_postes";
import Nbr_effectif from "./views/graphes/Nbr_effectif"
import Type_effectif from "./views/graphes/Type_effectif"
import Rep_par_region from "./views/graphes/Rep_par_region"
import Rep_par_contrat from "./views/graphes/Rep_par_contrat"
import Rep_par_exp from "./views/graphes/Rep_par_exp"
import Rep_par_formation from "./views/graphes/Rep_par_formation"
import Rep_par_s_anapec from "./views/graphes/Rep_par_s_anapec"
import Top15 from "./views/graphes/Top15";
import Top15_type_contrat from "./views/graphes/Top15_type_contrat"
import Top15_rome from "./views/graphes/Top15_emploi_secteur"
import Top15_rome_contrat from "./views/graphes/Top15_emploi_region"
import Top15_emploi_secteur from "./views/graphes/Top15_emploi_secteur"
import Top15_emploi_region from "./views/graphes/Top15_emploi_region"
import Region_secteur from "./views/graphes/Region_secteur"
import Vue_region from "./views/graphes/Vue_Region";
import Evolution_trimestre from "./views/graphes/Evolution_trimestre";
import GrapheRegion from "./views/graphes/GrapheRegion";
import Comparative_region from "./views/graphes/Comparative_region";
import ArticlesList from "./views/articles/ArticlesList"
import jwtAuthService from "./services/jwtAuthService"

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => (
      <Route exact path="/">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
           <Dashboard1 />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/articles",
    exact: true,
    component: () => (
      <Route exact path="/articles">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
           <ArticlesList />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },

  {
    path: "/offres/projetees",
    exact: true,
    component: () => (
      <Route>
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <OffresList />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/offres/brutes",
    exact: true,
    component: () => (
      <Route exact path="/offres/brutes">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <PreNormalizedOffresList />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
    {
    path: "/graphes/evolution",
    exact: true,
    component: () => (
      <Route exact path="/graphes/evolution">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Evolution_nbr_postes />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/type",
    exact: true,
    component: () => (
      <Route exact path="/graphes/type">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Type_effectif />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },    {
    path: "/graphes/effectif",
    exact: true,
    component: () => (
      <Route exact path="/graphes/effectif">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Nbr_effectif />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  }, 
  {  
    path: "/graphes/region",
    exact: true,
    component: () => (
      <Route exact path="/graphes/region">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Rep_par_region />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },  
    {  
    path: "/graphes/contrat",
    exact: true,
    component: () => (
      <Route exact path="/graphes/contrat">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Rep_par_contrat />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },    
  {
    path: "/graphes/formation",
    exact: true,
    component: () => (
      <Route exact path="/graphes/formation">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Rep_par_formation />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },    {
    path: "/graphes/experience",
    exact: true,
    component: () => (
      <Route exact path="/graphes/experience">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Rep_par_exp />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },    {
    path: "/graphes/secteur_anapec",
    exact: true,
    component: () => (
      <Route exact path="/graphes/secteur_anapec">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Rep_par_s_anapec />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },   

  {
    path: "/graphes/evolution/trimestre",
    exact: true,
    component: () => (
      <Route exact path="/graphes/evolution/trimestre">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Evolution_trimestre />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/emploi",
    exact: true,
    component: () => (
      <Route exact path="/graphes/emploi">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15 />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/emploi/contrat",
    exact: true,
    component: () => (
      <Route exact path="/graphes/emploi/contrat">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15_type_contrat />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/emploi/rome",
    exact: true,
    component: () => (
      <Route exact path="/graphes/emploi/rome">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15_rome />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/emploi/rome/contrat",
    exact: true,
    component: () => (
      <Route exact path="/graphes/emploi/rome/contrat">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15_rome_contrat />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/emploi/secteur",
    exact: true,
    component: () => (
      <Route exact path="/graphes/emploi/secteur">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15_emploi_secteur />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/region/emploi",
    exact: true,
    component: () => (
      <Route exact path="/graphes/region/emploi">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Top15_emploi_region />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/region/secteur",
    exact: true,
    component: () => (
      <Route exact path="/graphes/region/secteur">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Region_secteur />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/region/multiple",
    exact: true,
    component: () => (
      <Route exact path="/graphes/region/multiple">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <GrapheRegion />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/region/comparative",
    exact: true,
    component: () => (
      <Route exact path="/graphes/region/comparative">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Comparative_region />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/graphes/vue/region",
    exact: true,
    component: () => (
      <Route exact path="/graphes/vue/region">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Vue_region />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/Statistique_old",
    exact: true,
    component: () => (
      <Route exact path="Statistique">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Statistique />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/Statistique",
    exact: true,
    component: () => (
      <Route exact path="Statistique2">
        {jwtAuthService.checkTokenValidity()}
{localStorage.getItem("jwt_token") ? (
          <Stat2 />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/referentiel",
    exact: true,
    component: () => (
      <Route exact path="/referentiel">
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          <Referentiel />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/scraping/script",
    exact: true,
    component: () => (
      <Route exact path="/scraping/script">
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          <ScrapingScript />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },

  {
    path: "/gestion-utilisateurs",
    exact: true,
    component: () => (
      <Route exact path="/gestion-utilisateurs">
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          <GestionUtilisateurs />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },

  {
    path: "/correspondances/secteur",
    exact: true,
    component: () => (
      <Route exact path="/correspondances/secteur">
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          <Correspondance />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/correspondances/emploi",
    exact: true,
    component: () => (
      <Route exact path="/correspondances/emploi">
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          <Correspondance_emploi />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
  {
    path: "/ordonnanceur",
    exact: true,
    component: () => (
      <Route exact path="/ordonnanceur" >
        {localStorage.getItem("jwt_token") &&
        localStorage.getItem("role") == "Super Admin" ? (
          window.open("http://sprodige-anapec.digital-place.co:8080", "_blank"),
          <Redirect to="/offres/projetees" />
        ) : (
          <Redirect to="/session/signin" />
        )}
      </Route>
    ),
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionsRoutes,
  // {
  //   path: "/",
  //   component: Testauth,
  //   routes: [],
  // },
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...redirectRoute,
      ...errorRoute,
    ],
  },
];
export default routes;
