import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import strapi from "app/services/strapi";
import SweetAlert from "sweetalert2-react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { computeFallbackHeaderFormat } from "@fullcalendar/core";

const RefModal = ({ ...props }) => {
    const [addalert, setaddalert] = useState(false);
    const [alertype, setAlertype] = useState(false); //waring or succes
    const [show, setShow] = useState(false)
    const [nom, setNom] = useState(props.nom ? props.nom : "")
    const [lexique, setLexique] = useState(props.lexique ? props.lexique : "")
    const [coef, setCoef] = useState(props.lexique ? props.lexique : 100)
    const [clusters, setClusters] = useState([])
    const [selectedCluster, setSelectedCluster] = useState({})


    useEffect(() => {


        async function getData() {
            const x = await strapi.getDataset_skills_parents();
            if (x) {
                setClusters(x)
            } else {

                NotificationManager.error(
                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                    "Erreur"
                );
            }
        }

        getData();
    }, [])

    useEffect(() => {
        setNom(props.nom ? props.nom : "");
    }, [props.nom])

    useEffect(() => {
        setLexique(props.lexique ? props.lexique : "");
    }, [props.lexique])

    useEffect(() => {
        setCoef(props.coef ? props.coef : 100);
    }, [props.coef])


    const handleClose = () => {
        setShow(false)
    }

    const openModal = () => {
        setShow(true)
    }


    const handleAssign = async () => {
        console.log(props.skills);
        if (!Array.isArray(props.skills)) {

            const x = await strapi.addRelationSkill_Parent(props.skills.english_name, selectedCluster)
            if (x) {
                NotificationManager.success("Succès", "succès")
                 
                props.data();
            } else {

                NotificationManager.error(
                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                    "Erreur"
                );
                 
            }
        } else {
            props.skills.forEach(async element => {
                const x = await strapi.addRelationSkill_Parent(element.english_name, selectedCluster)
                if (x) {
                    NotificationManager.success("Succès", "succès")
                     
                    props.data();
                } else {

                    NotificationManager.error(
                        "Erreur lors de la connexion au serveur. Merci de patienter...",
                        "Erreur"
                    );
                     
                }
            });

        }
    }

    const handleUpdateClick = async () => {
        if ((nom && lexique && coef) || (props.type === "reg" && nom && coef) || ((props.type === "villes") || props.type === "cluster") && nom)
            switch (props.type) {
                case "reg":
                    const x = await strapi.updateDataset_region(props.id_data, props.id_region, nom, "", coef)
                    if (x) {
                        NotificationManager.success("Succès", "succès")
                         
                        props.data();
                    } else {

                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "anapec":
                    const y = await strapi.updateDataset_secteuranapec(props.id_data, props.id_secteur, nom, lexique, coef)
                    if (y) {
                        NotificationManager.success("Succès", "succès")
                         
                        props.data();
                    } else {

                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "emploianapec":
                    const z = await strapi.updateDataset_emploianapec(props.id, nom, lexique, coef)
                    if (z) {
                        NotificationManager.success("Succès", "succès")
                         
                        props.data();
                    } else {

                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "skills":
                    const w = await strapi.updateSkill(props.id, lexique, nom, coef)
                    if (w) {
                        NotificationManager.success("Succès", "succès")
                         
                        props.data();
                    } else {

                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "villes":
                    const ville = await strapi.updateDataRef_villes(props?.id, nom, coef, props?.isActive)
                    if (ville) {
                        NotificationManager.success("Succès", "succès")
                        props.data();
                         
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "cluster":
                    const cluster = await strapi.updateSkill_parent(lexique, nom)
                    if (cluster) {
                        NotificationManager.success("Succès", "succès")
                        props.data();
                         
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                    }

                    break;
                default:
                    break;
            }
    }

    const handleSubmitClick = async () => {
        if ((nom && lexique && coef) || (props.type === "reg" && nom && coef) || ((props.type === "villes") || props.type === "cluster") && nom)
            switch (props.type) {
                case "reg":
                    const region = await strapi.addRef_region(nom, coef)
                    if (region) {
                        NotificationManager.success("Succès", "succès")
                         
                        props.data();
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }

                    break;
                case "anapec":
                    const secteur_anapec = await strapi.addRef_secteur_anapec(nom, coef)
                    if (secteur_anapec) {
                        const anapec = await strapi.addDataset_secteurAnapec(secteur_anapec.id, lexique)
                        if (anapec) {
                            NotificationManager.success("Succès", "succès")
                             
                            props.data();

                        } else {
                            NotificationManager.error(
                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                "Erreur"
                            );
                             

                        }
                    }
                    else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         

                    }

                    break;
                case "emploianapec":
                    const emploianapec = await strapi.addDataset_emploi_anapec(nom, lexique, coef)
                    if (emploianapec) {
                        NotificationManager.success("Succès", "succès")
                        props.data();
                         
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         

                    }
                    break;
                case "villes":
                    const ville = await strapi.addDataRef_villes(nom, props?.region.id, coef)
                    if (ville) {
                        NotificationManager.success("Succès", "succès")
                        props.data();
                         
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "cluster":
                    const cluster = await strapi.addSkill_parent(nom);
                    if (cluster) {
                        NotificationManager.success("Succès", "succès")
                        props.data();
                         
                    } else {
                        NotificationManager.error(
                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                            "Erreur"
                        );
                         
                    }
                    break;
                case "skills":
                    switch (props.type_skill) {
                        case "ST1":
                            const res_ST1 = await strapi.addSkill_ST1(lexique, nom);
                            if (res_ST1) {
                                NotificationManager.success("Succès", "succès")
                                props.data();
                                 
                            } else {
                                NotificationManager.error(
                                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                                    "Erreur"
                                );
                                 
                            }
                            break;
                        case "ST2":
                            const res_ST2 = await strapi.addSkill_ST2(lexique, nom);
                            if (res_ST2) {
                                NotificationManager.success("Succès", "succès")
                                props.data();
                                 
                            } else {
                                NotificationManager.error(
                                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                                    "Erreur"
                                );
                                 
                            }
                            break;
                        case "ST3":
                            const res_ST3 = await strapi.addSkill_ST3(lexique, nom);
                            if (res_ST3) {
                                NotificationManager.success("Succès", "succès")
                                props.data();
                                 
                            } else {
                                NotificationManager.error(
                                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                                    "Erreur"
                                );
                                 
                            }
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
    }

    const nomhandleChange = ({ target: { value } }) => {
        setNom(value)

    };
    const lexiquehandleChange = ({ target: { value } }) => {
        setLexique(value)
    };
    const coefhandleChange = ({ target: { value } }) => {
        setCoef(value)
    }

    const renderCoefInput = () => {
        return <div>
            <label htmlFor="nom">Coefficient d'ajustement (%)</label>
            <input
                className="form-control"
                placeholder="Saisir le coefficient d'ajustement"
                id="coef"
                type="number"
                value={coef}
                onChange={coefhandleChange}
            />
        </div>

    }

    return (
        <Fragment>
            <Button
                variant={props.type === "cluster_assign" ? "secondary" : "primary"}
                className="btn-icon m-1"
                onClick={openModal}
            >
                <span className="ul-btn__text"> {props.op === "ajout" ? "Ajouter" : props.type === "cluster_assign" ? "Affecter à un Cluster" : "Modifier"}</span>
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                {...props}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <NotificationContainer />
                <Modal.Body>
                    {(props.type == "anapec" || props.type == "emploianapec") && <div>
                        <div><p><i>**Noter que les changements sur les référentiels n'affecterons pas les données structurées
                         jusqu'à le lancement d'un entraînement des modèles suivi par une projection sur les référentiels concernés.</i></p></div>
                        <div><p><i>**Merci de séparer les mots du lexique par un saut de ligne.</i></p></div>
                        <div className="">
                            <form
                                onSubmit={props.op === "ajout" ? handleSubmitClick : handleUpdateClick}
                            >
                                <label htmlFor="nom">Nom</label>
                                <input
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="nom"
                                    type="text"
                                    value={nom}
                                    onChange={nomhandleChange}
                                />
                                <label htmlFor="nom">Lexique</label>
                                <textarea
                                    // id="name"
                                    className="form-control"
                                    placeholder="Saisir le lexique avec un saut de ligne entre les mots"
                                    id="lexique"
                                    type="text"
                                    value={lexique}
                                    onChange={lexiquehandleChange}

                                />

                                {renderCoefInput()}
                            </form>
                        </div>
                    </div>}
                    {props.type === "skills" && <div>
                        <div className="">
                            <form
                                onSubmit={props.op === "ajout" ? handleSubmitClick : handleUpdateClick}
                            >
                                <label htmlFor="nom">Nomenclature française</label>
                                <input
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="nom"
                                    type="text"
                                    value={nom}
                                    onChange={nomhandleChange}
                                />
                                <label htmlFor="nom">Nomenclature anglaise</label>
                                <textarea
                                    // id="name"
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="lexique"
                                    type="text"
                                    value={lexique}
                                    onChange={lexiquehandleChange}

                                />
                            </form>
                        </div>

                    </div>}
                    {props.type === "reg" && <div>
                        <div className="">
                            <form
                                onSubmit={props.op === "ajout" ? handleSubmitClick : handleUpdateClick}
                            >
                                <div><p><i>**Noter que les changements sur les référentiels n'affecterons pas les données structurées
                         jusqu'à le lancement d'un entraînement des modèles suivi par une projection sur les référentiels concernés.</i></p></div>
                                <label htmlFor="nom">Région</label>
                                <input
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="nom"
                                    type="text"
                                    value={nom}
                                    onChange={nomhandleChange}
                                />
                                {renderCoefInput()}

                            </form>
                        </div>

                    </div>}
                    {props.type === "villes" && <div>
                        <div className="">
                            <form
                                onSubmit={props.op === "ajout" ? handleSubmitClick : handleUpdateClick}
                            >
                                <div><p><i>**Noter que les changements sur les référentiels n'affecterons pas les données structurées
                         jusqu'à le lancement d'un entraînement des modèles suivi par une projection sur les référentiels concernés.</i></p></div>

                                <label htmlFor="nom">Ville</label>
                                <input
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="nom"
                                    type="text"
                                    value={nom}
                                    onChange={nomhandleChange}
                                />
                                <label htmlFor="nom">Région</label>
                                <input
                                    className="form-control"
                                    placeholder={props?.region?.Nom}
                                    type="text"
                                    disabled
                                />
                                {renderCoefInput()}
                            </form>
                        </div>

                    </div>}
                    {props.type === "cluster" && <div>
                        <div className="">
                            <form
                                onSubmit={props.op === "ajout" ? handleSubmitClick : handleUpdateClick}
                            >
                                <div><p><i>**Noter que les changements sur les référentiels n'affecterons pas les données structurées
                         jusqu'à le lancement d'un entraînement des modèles suivi par une projection sur les référentiels concernés.</i></p></div>

                                <label htmlFor="nom">Nom du cluster</label>
                                <input
                                    className="form-control"
                                    placeholder="Saisir le nom"
                                    id="nom"
                                    type="text"
                                    value={nom}
                                    onChange={nomhandleChange}
                                />
                            </form>
                        </div>

                    </div>}
                    {props.type === "cluster_assign" && <div>
                        <div className="">
                            <form
                                onSubmit={handleAssign}
                            >
                                <div><p><i>**Noter que les changements sur les référentiels n'affecterons pas les données structurées
                         jusqu'à le lancement d'un entraînement des modèles suivi par une projection sur les référentiels concernés.</i></p></div>                                <br />
                                <label htmlFor="nom">Nouveau cluster</label>
                                <div className="mr-1">
                                    <select
                                        className="custom-select task-manager-list-select pl-4"
                                        id="inputGroupSelect01"
                                        onChange={async ({ target: { value } }) => {
                                            setSelectedCluster(value)
                                        }}
                                    >
                                        <option value="choisir">Réinitialiser</option>
                                        {clusters.map((cluster) => (
                                            <option key={cluster.french_name} value={cluster.french_name}>{cluster.french_name} </option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        </div>

                    </div>}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                    {/**props.op === "ajout" ? handleSubmitClick : handleUpdateClick */}
                    <Button variant="primary" onClick={() => {
                        if (props.op === "assign_cluster") {
                            handleAssign()
                        } else if (props.op === "ajout") {
                            handleSubmitClick()
                        } else {
                            handleUpdateClick()
                        }
                    }}>
                        Enregistrer
                        </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RefModal;