import React, { Component, useRef } from "react";
import { Breadcrumb } from "app/repertoire";
import ReactPaginate from "react-paginate";
import strapi from "app/services/strapi";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

class Correspondance extends Component {
    constructor(props) {
        super(props);

        // Initializing the state
        this.state = {
            correspondances: [],
            secteurs_anapec: [],
            secteurs_rome: [],
            DropDownLoading: false,
        };

        this.columns = [
            {
                dataField: "secteur_offre",
                text: "Secteur Offre",
                editable: false,
            },
            {
                id: 1,
                className: "anapec",
                dataField: "secteur_anapec",
                text: "Secteur ANAPEC",
                editable: true,
                editor: {
                    type: Type.SELECT,
                    getOptions: () => {
                        let oa = [];
                        this.state.secteurs_anapec.forEach(element => {
                            oa.push({ label: element.Nom, value: element.Nom })
                        });
                        return oa;
                    }

                }
                // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                //     <Secteurs_Dropdown row={row} columnIndex={columnIndex} options={this.state.secteurs_anapec} onChange={this.onChange} isLoading={this.state.DropDownLoading} />
                // ),
            },
            // {
            //     id: 2,
            //     className: "rome",
            //     dataField: "secteur_rome",
            //     text: "Secteur ROME",
            //     editable: true,
            //     editor: {
            //         type: Type.SELECT,
            //         getOptions: () => {
            //             let or = [];
            //             this.state.secteurs_rome.forEach(element => {
            //                 or.push({ label: element.Nom, value: element.Nom })
            //             });
            //             return or;

            //         }
            //     }
                // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                //     <Secteurs_Dropdown row={row} columnIndex={columnIndex} options={this.state.secteurs_rome} onChange={this.onChange} isLoading={this.state.DropDownLoading} />
                // ),
            // },
        ]

        // function Secteurs_Dropdown(props) {
        //     return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} id="div">
        //         <select className="form-control" style={{ width: '230px' }}

        //             onChange={async ({ target: { value, name } }) => {
        //                 props.onChange(props.columnIndex, props.row.id, value);
        //             }}>
        //             <option selected key={0} value={0}>Choisir le secteur correspondant </option>
        //             {props.options.map((sec) => (
        //                 <option key={sec.id} name={sec.Nom} value={sec.id}>{sec.Nom} </option>
        //             ))}
        //         </select>
        //         {props.isLoading && <Spinner
        //             style={{ marginTop: 10 }}
        //             animation="border"
        //             size="sm" />}
        //     </div>);


        // }
    }

    // onChange = async (columnIndex, id, value) => {
    //     this.setState({ DropDownLoading: true })
    //     const x = await strapi.updateSecteurCorrespondance(columnIndex, id, value);
    //     if (x === false) {
    //         NotificationManager.error(
    //             "Erreur lors de la connexion au serveur. Merci de patienter...",
    //             "Erreur"
    //         );
    //         this.setState({ DropDownLoading: false })
    //     } else {
    //         this.setState({ DropDownLoading: false })
    //         let copie_correspondance = [... this.state.correspondances];
    //         copie_correspondance[id - 1] = { id: x.id, secteur_offre: x.secteur_offre, secteur_anapec: x.secteur_anapec, secteur_rome: x.secteur_rome };
    //         this.setState({ correspondances: copie_correspondance })
    //         // 

    //     }
    // }

    getSecteurCorrespondance = async () => {
        const x = await strapi.getSecteurCorrespondance_ANAPEC_view();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ Loading: false });
        } else {
            this.setState({ correspondances: x });
            this.setState({ Loading: false });
        }
    };

    getSecteurANAPEC = async () => {
        const x = await strapi.getDataRef_secteurs_anapec();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ Loading: false });
        } else {
            this.setState({ secteurs_anapec: x });
            this.setState({ Loading: false });
        }
    }

    getSecteurROME = async () => {
        const x = await strapi.getDataRef_secteurs();
        if (x === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ Loading: false });
        } else {
            this.setState({ secteurs_rome: x });
            this.setState({ Loading: false });
        }
    }

    toshowLoad() {
        this.setState({ showload: true });
    }
    tohidLoad() {
        this.setState({ showload: false });
    }

    toSortDate() {
        const va = this.state.datesort;

        this.setState({ datesort: !va }, () =>
            this.getData(this.state.start, this.state.datesort)
        );
    }

    async componentDidMount() {
        this.getSecteurCorrespondance();
        this.getSecteurROME();
        this.getSecteurANAPEC();

    }


    render() {

        return (
            <div id="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Matrice de correspondance des secteurs ANAPEC " }]} ></Breadcrumb>
                <NotificationContainer />
                <section className="contact-list">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="card text-left">
                                {this.state.Loading == true ? (
                                    <div className="card-body pt-1">
                                        <div className="table-responsive">
                                            {/* <BootstrapTable
                                                bootstrap4
                                                keyField="secteur_offre"
                                                columns={this.columns}
                                                noDataIndication={"Aucun enregistrement"}
                                            /> */}
                                            <font size="7">
                                                <div align="center">
                                                    <div className="spinner spinner-primary mr-3"></div>
                                                </div>
                                            </font>
                                        </div>
                                    </div>
                                ) : (
                                        <div className="card-body pt-1">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    data={this.state.correspondances}
                                                    columns={this.columns}
                                                    cellEdit={cellEditFactory({
                                                        mode: "dbclick",
                                                        blurToSave: true,
                                                        afterSaveCell: async (oldValue, newValue, row, column) => {
                                                            if (oldValue === newValue || !newValue) {
                                                                // console.log("Nothing Changed");
                                                            } else {
                                                                if (column.id === 1) {
                                                                    // console.log("COLUMN", column.id);
                                                                    const id = this.state.secteurs_anapec.find((e) => e.Nom === newValue).id;
                                                                    const secteur_exists = await strapi.SecteurCorrespondanceExists(row.secteur_offre);
                                                                    // console.log("EXIST LENGTH", secteur_exists);
                                                                    if (secteur_exists.length > 0) {
                                                                        const x = await strapi.updateSecteurCorrespondance(column.id, secteur_exists[0].id, id);
                                                                        if (x === false) {
                                                                            NotificationManager.error(
                                                                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                "Erreur"
                                                                            );
                                                                        } else {
                                                                            this.getSecteurCorrespondance();
                                                                            this.getSecteurROME();
                                                                            this.getSecteurANAPEC();
                                                                        }
                                                                    } else {
                                                                        const new_correspondance = await strapi.addSecteurCorrespondance(row.secteur_offre);

                                                                        if (new_correspondance) {
                                                                            const x = await strapi.updateSecteurCorrespondance(column.id, new_correspondance.id, id);
                                                                            if (x === false) {
                                                                                NotificationManager.error(
                                                                                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                    "Erreur"
                                                                                );
                                                                            } else {
                                                                                this.getSecteurCorrespondance();
                                                                                this.getSecteurROME();
                                                                                this.getSecteurANAPEC();
                                                                            }

                                                                        } else {
                                                                            NotificationManager.error(
                                                                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                "Erreur"
                                                                            );
                                                                        }

                                                                    }


                                                                } else if (column.id === 2) {
                                                                    const id = this.state.secteurs_rome.find((e) => e.Nom === newValue).id;
                                                                    // console.log("offre", row.secteur_offre);
                                                                    const secteur_exists = await strapi.SecteurCorrespondanceExists(row.secteur_offre);
                                                                    // console.log("EXIST LENGTH", secteur_exists);
                                                                    if (secteur_exists.length > 0) {
                                                                        const x = await strapi.updateSecteurCorrespondance(column.id, secteur_exists[0].id, id);
                                                                        if (x === false) {
                                                                            NotificationManager.error(
                                                                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                "Erreur"
                                                                            );
                                                                        } else {
                                                                            this.getSecteurCorrespondance();
                                                                            this.getSecteurROME();
                                                                            this.getSecteurANAPEC();
                                                                        }
                                                                    } else {
                                                                        const new_correspondance = await strapi.addSecteurCorrespondance(row.secteur_offre);
                                                                        if (new_correspondance) {
                                                                            const x = await strapi.updateSecteurCorrespondance(column.id, new_correspondance.id, id);
                                                                            if (x === false) {
                                                                                NotificationManager.error(
                                                                                    "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                    "Erreur"
                                                                                );
                                                                            } else {
                                                                                this.getSecteurCorrespondance();
                                                                                this.getSecteurROME();
                                                                                this.getSecteurANAPEC();
                                                                            }

                                                                        } else {
                                                                            NotificationManager.error(
                                                                                "Erreur lors de la connexion au serveur. Merci de patienter...",
                                                                                "Erreur"
                                                                            );
                                                                        }

                                                                    }

                                                                }

                                                            }
                                                        }
                                                    })}
                                                    noDataIndication={"Aucun enregistrement"}
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Correspondance;
