import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import strapi from "app/services/strapi";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const ModalDetailOffre = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const [offre, setOffre] = useState(null);

  const handleClose = () => {
    setShow(false);
  };

  const getData = async () => {
    setShow(true);
    const data = await props.getData();
    setOffre(data);
  };

  const printy = () => {
    var divContents = document.getElementById("print").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    // a.document.write("<body > <h1>Div contents are <br>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  return (
    <Fragment>
      <NotificationContainer />
      {props.title ? (
        <Button
          onClick={() => {
            getData();
          }}
        >
          {props.title}
        </Button>
      ) : (
        <font size="4">
          <i
            className="i-Add mr-4   font-weight-900 text-primary cursor-pointer"
            onClick={() => {
              getData();
            }}
          ></i>
        </font>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size="lg"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Offre Complète</Modal.Title>
        </Modal.Header>
        {offre && (
          <Modal.Body>
            {/* <div id="print-area">
              <p>poste : {offre.poste}</p>
              <p>secteur : {offre.secteur.Nom}</p>
              <p>region : {offre.region.Nom}</p>
              <p>Nombre de postes : {offre.region.nombre_poste}</p>
              <p>Date : {offre.date}</p>
              <p>Type de contrat : {offre.type_de_contrat}</p>
              <p>Entreprise : {offre.entreprise}</p>
              <p>Fonction : {offre.fonction}</p>
              <p>Niveau d'experience : {offre.niv_experience}</p>
              <p>Source : {offre.source}</p>
              <p>URL : {offre.url}</p>
              <p>Description entreprise : {offre.description_entreprise}</p>
              <p>Description poste : {offre.description_poste}</p>
              <p>Profil recherché : {offre.profil_recherche}</p>
            </div> */}
            {/* <Button
              onClick={() => window.print()}
              className="py-2"
              variant="warning"
            >
              Print
            </Button> */}

            <div className="ul-widget-app__recent-messages" id="print">


              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Secteur</h6>
                    <p className="mb-2">{offre.secteur_anapec.Nom}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Emploi Métier</h6>
                    <p className="mb-2">{offre.emploi_anapec.emploi_metier}</p>
                  </div>
                </div>
              </div>
{/* 
              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Secteur ROME</h6>
                    <p className="mb-2">{offre.secteur_rome.Nom}</p>
                  </div>
                </div>
              </div> */}

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Région</h6>
                    <p className="mb-2">{offre.region.Nom}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Ville</h6>
                    <p className="mb-2">{offre.ville.ville}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Nombre de postes</h6>
                    <p className="mb-2">{offre.nombre_poste}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Date</h6>
                    <p className="mb-2">{offre.date}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Type de contrat</h6>
                    <p className="mb-2">{offre.type_de_contrat}</p>
                  </div>
                </div>
              </div>


              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">
                      Niveau d'experience
                    </h6>
                    <p className="mb-2">
                      {offre.niv_experience}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Catégorie Professionnelle</h6>
                    <p className="mb-2">{offre.poste}</p>
                  </div>
                </div>
              </div> */}

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">
                      Formation
                    </h6>
                    <p className="mb-2">
                      {offre.formation}
                    </p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">Source</h6>
                    <p className="mb-2">{offre.source}</p>
                  </div>
                </div>
              </div>

              <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <h6 className="heading text-primary">URL</h6>
                    <p className="mb-2">{offre.url}</p>
                  </div>
                </div>
              </div>

              {/* <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
                <div className="ul-widget-app__comment">
                  <div className="ul-widget-app__profile-title">
                    <Button onClick={() => printy()}>Print </Button>
                  </div>
                </div>
              </div> */}
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalDetailOffre;
