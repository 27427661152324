import React, { Component } from "react";
import strapi from "app/services/strapi";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NotificationManager,
} from "react-notifications";
import { SimpleCard } from "app/repertoire";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import RefModal from "./RefModal";
import { Button } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

class Ville extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regions: [],
            selectedRegion: 0,
            villes: [],
            loading: false,
            modifying: false,
            selectedItem: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const reg = await strapi.getDataRef_regions();
        if (reg === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false });
        } else {
            this.setState({ regions: reg, loading: false });
            if(this.state.selectedRegion){
                await this.getVilleByIdRegion(this.state.selectedRegion)
            }
        }



    }

    async getVilleByIdRegion(id) {
        const villes = await strapi.getDataRef_villes_By_ID_Region(id);
        if (villes === false) {
            NotificationManager.error(
                "Erreur lors de la connexion au serveur. Merci de patienter...",
                "Erreur"
            );
            this.setState({ loading: false });
        } else {
            this.setState({ villes: villes, loading: false, modifying : false, selectedItem : [] })
        }
    }


    columns = [
        {
            id: 1,
            dataField: "ville",
            text: "Ville",
            style: { textAlign: "center" },

        },
        {
            id: 2,
            dataField: "coefficient_aj",
            text: "Coefficient d'ajustement",
            style: { textAlign: "center" },

        },

    ];

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.setState({ modifying: true, selectedItem: row });
        }
    };

    rowStyle = (row, rowIndex) => {
        const style = {};
        if (this.state.selectedItem) {
            if (row.id === this.state.selectedItem.id) {
                style.backgroundColor = "#c7a5bc"
            }
        }
        if (!row.isActive) {
            style.fontWeight = "bold";
            style.color = "red";
        }
        return style;
    };

    handleSubmitClick = async () => {
        this.setState({ loading: true });
        const x = await strapi.updateDataRef_villes(this.state.selectedItem.id, this.state.selectedItem.ville, this.state.selectedItem.coefficient_aj, !this.state.selectedItem.isActive );
        if (x === false) {
          NotificationManager.error(
            "Erreur lors de la connexion au serveur. Merci de patienter...",
            "Erreur"
          );
          this.setState({ loading: false, modifying: false });
        } else {
          this.getData();
        }
    }


    render() {
        return (
            <div>
                {this.state.villes.length > 0 && <SimpleCard
                    style={{
                        marginLeft: window.innerWidth - 750,
                        top: 220,
                        position: 'fixed',
                        overflowY: 'hidden',
                        overflowx: 'hidden',
                        // backgroundColor : 'F9F6F4'
                        // paddingRight : 0
                        // width: 1200
                    }}>

                    <div className="d-flex justify-content-end mr-lg-4">
                        <RefModal
                            data={() => this.getData()}
                            type="villes"
                            title="Ajouter une ville"
                            region={this.state.regions.find((e)=> e.id == this.state.selectedRegion)}
                            op="ajout"
                        ></RefModal>
                        {this.state.selectedItem.id && this.state.modifying && <RefModal
                            data={() => this.getData()}
                            type="villes"
                            title="Modification"
                            nom={this.state.selectedItem.ville}
                            region={this.state.regions.find((e)=> e.id == this.state.selectedRegion)}
                            id={this.state.selectedItem.id}
                            coef={this.state.selectedItem.coefficient_aj}
                            isActive={this.state.selectedItem.isActive}
                            op="modification"
                        ></RefModal>}
                        {this.state.selectedItem.id && this.state.modifying && <Button
                            variant="secondary"
                            className="btn-icon m-1"
                            onClick={this.handleSubmitClick}
                            visible={!this.state.modifying}
                        >
                            <span className="ul-btn__text"> {this.state.selectedItem.isActive ? "Désactiver" : "Activer"}</span>

                        </Button>
                        }
                        {this.state.selectedItem.id && this.state.modifying && <Button
                            variant="secondary"
                            className="btn-icon m-1"
                            onClick={() => { this.setState({ modifying: false, selectedItem: [] }) }}
                            visible={!this.state.modifying}
                        >
                            <span className="ul-btn__text"> Annuler</span>

                        </Button>
                        }



                    </div>
                </SimpleCard>}

                {this.state.loading ? <font size="7"><div align="center"><div className="spinner spinner-primary mr-3"></div></div></font> : <div></div>}
                <br />
                <br />
                <br />
                <br />

                <div align="center">
                    <div className="d-flex flex-row align-items-center pr-5">
                        <strong className="text-sm-left  heading text-primary">
                            <span className="mr-1">Choisissez une région pour lister les villes</span>
                        </strong>
                        <div className="mr-1">
                            <select
                                className="custom-select task-manager-list-select pl-4"
                                id="inputGroupSelect01"

                                onChange={async ({ target: { value } }) => {
                                    
                                    const x = await this.getVilleByIdRegion(value)
                                    if (x === false) {
                                        NotificationManager.error(
                                            "Erreur lors de la connexion au serveur. Merci de patienter...",
                                            "Erreur"
                                        );
                                        this.setState({ loading: false });
                                    }else {
                                        this.setState({
                                            selectedRegion: value
                                        });
                                    }
                                }}
                            >
                                <option value="choisir">Réinitialiser</option>
                                {this.state.regions.map((lesreg) => (
                                    <option key={lesreg.id} value={lesreg.id}>{lesreg.Nom} </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <center>
                    <div style={{ width: '50%' }}>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={this.state.villes}
                            columns={this.columns}
                            noDataIndication={"Aucun enregistrement"}
                            rowStyle={this.rowStyle}
                            rowEvents={this.rowEvents}
                        />
                    </div>
                </center>
            </div>
        );
    }
}

export default Ville;
