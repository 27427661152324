import { lazy } from "react";

const Offreslist = lazy(() => import("./OffresList"));

const PreNormalizedOffresList = lazy(() => import("./PreNormalizedOffresList"));

const Test2 = lazy(() => import("../app/invoice/Test2"));

const OffresRoutes = [
  {
    path: "/offres/projetees",
    // component: localStorage.getItem("jwt_token") ? Offreslist : Test2,
    component: Offreslist,
  },
  {
    path: "/offres/brutes",
    component: PreNormalizedOffresList,
  },
];

export default OffresRoutes;
