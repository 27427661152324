import React, { Component } from "react";

function Full(props) {
  return (
    <iframe
      width={window.innerWidth - 200}
      height={window.innerHeight - 200}
      src={
        props.filters ?
          `https://app.powerbi.com/reportEmbed?reportId=0c54c265-dca6-463e-94e9-fd051d6d8c53&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&navContentPaneEnabled=false&${props.filters}`
          :
          `https://app.powerbi.com/reportEmbed?reportId=0c54c265-dca6-463e-94e9-fd051d6d8c53&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&navContentPaneEnabled=false`

      }
      frameborder="0"
      allowFullScreen="true"
      
    >
      {console.log("unendcoded ",props.filters)}
      {console.log("encoded ",encodeURI(props.filters))}
      {console.log("source ", `https://app.powerbi.com/reportEmbed?reportId=0c54c265-dca6-463e-94e9-fd051d6d8c53&autoAuth=true&ctid=d83c258f-fc49-4685-91d6-415c68ad417f&${props.filters}`)}


    </iframe>
  );
}

export default Full;
