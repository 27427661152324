import jwtAuthService from "app/services/jwtAuthService";
import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import strapi from "app/services/strapi";
import SweetAlert from "sweetalert2-react";
import validator from "validator";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import GestionUtilisateurs from "./GestionUtilisateurs";

const TestModal = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const [conpass, setcon] = useState(null);
  const [loading, setloading] = useState(false);
  const [addalert, setaddalert] = useState(false);
  const [alertype, setAlertype] = useState(false);

  const [textalert, setTextalert] = useState("");

  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
  });

  const [errors, setError] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
    vpassword: "",
  });

  const getData = async () => {
    setShow(true);
    const data = await props.getData();
    setUser(data);
  };

  // const { username, email, password } = product;
  //   const [state, setState] = useState({
  //     name: "",
  //   });
  //   const [fields, handleFieldChange] = useFormFields({
  //     name: "",
  //   });
  // const validateEmail = (va) => {

  //   if (validator.isEmail(email)) {
  //     setEmailError('Valid Email :)')
  //   } else {
  //     setEmailError('Enter valid Email!')
  //   }
  // }

  const handleClose = () => {
    setShow(false);
  };

  const reload = async () => {
    const data = await props.reloadData();
  };



  const userhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      username: value,
    }));

  };
  const emailhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      email: value,
    }));

  };
  const passhandleChange = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      password: value,
    }));

  };

  const conpasshandleChange = ({ target: { value } }) => {
    setcon(value);

  };

  const rolehandleClick = ({ target: { value } }) => {
    setUser((prevState) => ({
      ...prevState,

      role: value,
    }));

  };
  //   const getData = async () => {
  //     setShow(true);
  //     const data = await props.postData();
  //     setTest(data);
  //   };

  const handleSubmitClick = async () => {
    if (!user.username) {
      setError((prevState) => ({
        ...prevState,

        username: "*Le nom d'utilisateur est obligatoire",
      }));
    } else if (!user.email) {
      setError((prevState) => ({
        ...prevState,

        email: "*L'email est obligatoire",
      }));
    } else if (!validator.isEmail(user.email)) {
      setError((prevState) => ({
        ...prevState,

        email: " *Le format de l'Email est invalide",
      }));
    } else if (!user.password) {
      setError((prevState) => ({
        ...prevState,

        password: " *Le mot de passe est obligatoire",
      }));
    } else if (user.password != conpass) {
      setError((prevState) => ({
        ...prevState,

        vpassword: "Mot de passe incorrect ",
      }));
    } else if (!user.role) {
      setError((prevState) => ({
        ...prevState,

        role: " *Le rôle est obligatoire ",
      }));
    }


    else if (!user.password && user.password != conpass) {
    } else {
      setloading(true);
      if (props.type == "Add") {
        const rt = await strapi.postDataTests(user);

        if (
          rt.error &&
          rt.message &&
          rt.message[0].messages &&
          (rt.message[0].messages[0].message == "Username already taken." ||
            rt.message[0].messages[0].message == "Email already taken.")
        ) {
          setAlertype("warning");
          setTextalert("Le nom d'utilisateur ou l'Email sont déjà utilisés");
        } else if (rt.error && rt.error == "Unauthorized") {
          setAlertype("warning");
          NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
        } else {
          setAlertype("success");
          setTextalert("L'utilisateur a été ajouté avec succès");
        }
      } else {
        const rt = await strapi.postModifyTests(props.id, user);

        if (
          rt.error &&
          rt.message &&
          rt.message[0].messages &&
          (rt.message[0].messages[0].message == "Username already taken." ||
            rt.message[0].messages[0].message == "Email already taken.")
        ) {
          setAlertype("warning");
          setTextalert("Le nom d'utilisateur ou l'Email sont déjà utilisés");
        } else if (rt.error && rt.error == "Unauthorized") {
          setAlertype("warning");
          NotificationManager.error("Erreur lors de la connexion au serveur. Merci de patienter...", "Erreur");
        } else {
          setAlertype("success");
          setTextalert("L'utilisateur a été modifié avec succès");
        }
      }
      setloading(false);

      setShow(false);

      // setUser((prevState) => ({
      //   ...prevState,

      //   username: "",
      //   email: "",
      //   password: "",
      //   role: "",
      // }));
      // setError((prevState) => ({
      //   ...prevState,

      //   username: "",
      //   email: "",
      //   password: "",
      //   role: "",
      //   vpassword: "",
      // }));
      // setcon("");
      const a = await reload();
      setaddalert(true);
    }
  };

  const result = async () => {};

  const open = async () => {
    setaddalert(false);
    setUser((prevState) => ({
      ...prevState,

      username: "",
      email: "",
      password: "",
      role: "",
    }));
    setError((prevState) => ({
      ...prevState,

      username: "",
      email: "",
      password: "",
      role: "",
      vpassword: "",
    }));
    setcon("");
    if (props.type == "modify") {
      setShow(true);
      const data = await props.getData();
      setUser(data);
    }

    setShow(true);
  };

  return (
    <Fragment>
      <SweetAlert
        show={addalert}
        title=""
        type={alertype}
        text="L'utilisateur a été ajouté avec succès"
        text={textalert}
        // onConfirm={() => this.toggleAlert("success")}
      />

      {props.type == "Add" ? (
        <div>
          <Button
            variant="primary"
            className="btn-icon m-1"
            onClick={() => open()}
          >
            <span className="ul-btn__icon">
              <i className="i-Add-User"></i>
            </span>

            <span className="ul-btn__text"> Ajouter</span>
          </Button>
        </div>
      ) : (
        <i
          className="nav-icon i-Pen-2 font-weight-bold"
          onClick={() => open()}
        ></i>
      )}
      {/* <Button onClick={() => reload()}></Button> */}
      <Modal show={show} onHide={handleClose} {...props}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div>
              <div align="center">
                <div className="spinner spinner-primary mr-3"></div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmitClick}>
              <div className="">
                <div className="">
                  <label htmlFor="firstName">Nom d'utilisateur</label>
                  <input
                    // id="name"
                    className="form-control"
                    placeholder="Saisir votre nom d'utilisateur"
                    id="username"
                    type="text"
                    onChange={userhandleChange}
                    value={user.username}
                  />
                  {errors.username ? (
                    <div className="text-danger mt-1 ml-2">
                      {errors.username}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <label htmlFor="firstName">Email</label>
                  <input
                    id="email"
                    className="form-control"
                    placeholder="Saisir votre Email"
                    type="text"
                    onChange={emailhandleChange}
                    value={user.email}
                  />
                  {errors.email ? (
                    <div className="text-danger mt-1 ml-2">{errors.email}</div>
                  ) : (
                    <div></div>
                  )}
                  <label htmlFor="firstName">Password</label>
                  <input
                    type="password"
                    id="pass"
                    className="form-control"
                    placeholder="Saisir le mot de passe"
                    onChange={passhandleChange}
                    value={user.password}
                  />
                  {errors.password ? (
                    <div className="text-danger mt-1 ml-2">
                      {errors.password}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <label htmlFor="firstName">Confirmer le mot de passe</label>
                  <input
                    type="password"
                    id="conpass"
                    className="form-control"
                    placeholder="Confirmer le mot de passe"
                    onChange={conpasshandleChange}
                    value={conpass}
                  />
                  {errors.vpassword ? (
                    <div className="text-danger mt-1 ml-2">
                      {errors.vpassword}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <label htmlFor="firstName">Rôle</label>

                  <select
                    onChange={rolehandleClick}
                    className="custom-select task-manager-list-select"
                  >
                    <option>Choisir ...</option>
                    <option value={1}>Admin</option>
                    <option value={3}>Super admin</option>
                  </select>
                  {errors.role ? (
                    <div className="text-danger mt-1 ml-2">{errors.role}</div>
                  ) : (
                    <div></div>
                  )}
                </div>{" "}
              </div>{" "}
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleSubmitClick}>
            Enregistrer          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default TestModal;
