import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { loginWithEmailAndPassword } from "app/redux/actions/LoginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import jwtAuthService from "app/services/jwtAuthService";
import LoadingButton from "app/repertoire/components/buttons/LoadingButton";
import SweetAlert from "sweetalert2-react";
import strapi from "app/services/strapi";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LaddaButton, {
  XL,
  EXPAND_LEFT,
  EXPAND_RIGHT,
  EXPAND_UP,
  EXPAND_DOWN,
  CONTRACT,
  SLIDE_LEFT,
  SLIDE_RIGHT,
  SLIDE_UP,
  SLIDE_DOWN,
  ZOOM_IN,
  ZOOM_OUT,
} from "react-ladda";

const popovermail = (props) => (
  <Popover id="popover-basic" {...props}>
    {/* <Popover.Title as="h3">Alert</Popover.Title> */}
    <Popover.Content>
      <div style={{ color: "red" }}>Veuillez entrer votre email</div>
    </Popover.Content>
  </Popover>
);
const popoverpass = (props) => (
  <Popover id="popover-basic" {...props}>
    <font color="red">
      {/* <Popover.Title as="h3">Alert</Popover.Title> */}
      <Popover.Content>
        <div style={{ color: "red" }}>Veuillez entrer votre mot de passe</div>
      </Popover.Content>
    </font>
  </Popover>
);

// const SigninSchema = yup.object().shape({
//   email: yup.string().email("Invalid email").required("email is required"),
//   password: yup
//     .string()
//     .min(8, "Password must be 8 character long")
//     .required("password is required"),
// });

class Signin extends Component {
  state = {
    email: "",
    password: "",
    Loading: false,
    mailnull: false,
    passnull: false,
    error: false,
    errorcnx: false,
  };

  toggleAlert = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  // handleChange = (event) => {
  //   event.persist();
  //   console.log("gsgdfdfdfdfdf" + event.target.name);
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  // getrole = async (email) => {
  //   const ro = await strapi.getUserRole_By_email(email);
  //   console.log("ro");
  //   console.log(ro[0].role.name);
  //   localStorage.setItem("role", ro[0].role.name);
  //   console.log(localStorage.getItem("role"));
  // };

  handleSubmit = async (e) => {
    e.preventDefault();
    const user = {
      identifier: this.state.email,
      password: this.state.password,
    };

    this.setState({ Loading: true });
    // console.log(user);
    // console.log(this.state.email + this.state.password);

    if (!this.state.email) {
      this.setState({ mailnull: true });

      this.setState({ Loading: false });
      setTimeout(() => {
        this.setState({ mailnull: false });
      }, 10000);
      return;
    }
    if (!this.state.password) {
      this.setState({ Loading: false });
      this.setState({ passnull: true });
      setTimeout(() => {
        this.setState({ passnull: false });
      }, 10000);

      return;
    }

    const x = await jwtAuthService.login(this.state.email, this.state.password);

    if (
      x.error &&
      x.message &&
      x.message[0].messages &&
      x.message[0].messages[0].message == "Identifier or password invalid."
    ) {
      this.setState({ error: true });
    } else if (x == false) {
      // this.setState({ errorcnx: true });
      this.setState({ Loading: false });
      NotificationManager.error("La connexion a échoué !", "Erreur", 10000);
    } else if (x.error && x.error == "Internal Server Error") {
      // this.setState({ errorcnx: true });
      NotificationManager.error("La connexion a échoué !", "Erreur", 10000);
      this.setState({ Loading: false });
    } else {


      localStorage.setItem("role", x.user.role.name);
      // this.getrole(this.state.email);

      // window.location.reload(false);
    }

    this.setState({ Loading: false });

    // console.log(this.props.user);

  };

  render() {
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url(/assets/images/back1.jpg)",
        }}
      >
        <div className="col-md-3">
          <div className="card ">
            <div>
              <div className="center">
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <img src="/assets/images/anapec.png" alt="" />
                  </div>
                  <h1 className="mb-3 text-18"></h1>
                  <form onSubmit={(event) => this.handleSubmit(event)}>
                    <div className="form-group">
                      <label htmlFor="email">Adresse Email</label>
                      <OverlayTrigger
                        show={this.state.mailnull}
                        placement="right"
                        overlay={popovermail}
                      >
                        <input
                          className="form-control form-control-rounded position-relative"
                          type="email"
                          name="email"
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                          value={this.state.email}
                        />
                      </OverlayTrigger>
                      {/* {errors.email && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.email}
                            </div>
                          )} */}
                    </div>

                    <NotificationContainer />
                    <div className="form-group">
                      <label htmlFor="password">Mot de passe</label>

                      <OverlayTrigger
                        show={this.state.passnull}
                        placement="right"
                        overlay={popoverpass}
                      >
                        <input
                          className="form-control form-control-rounded"
                          type="password"
                          name="password"
                          onChange={(event) => {
                            this.setState({ password: event.target.value });
                          }}
                          value={this.state.password}
                        />
                      </OverlayTrigger>
                      {/* {errors.password && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.password}
                            </div>
                          )} */}
                    </div>
                    {/* <Button className="text-capitalize m-1" type="submit">
                      Sign In
                    </Button> */}
                    <br />

                    {/* <LoadingButton
                      className="btn btn-rounded btn-primary  sm-2 mr-1"
                      type="submit"
                      loading={this.state.Loading}
                      animation="border"
                      spinnerSize="sm"
                    >
                      <Button
                        className="btn btn-rounded btn-primary  sm-2"
                        // type="submit"
                        size="sm"
                        // onClick={() => this.toggleAlert("error")}
                      >
                        Sign In
                      </Button>
                    </LoadingButton> */}
                    <LaddaButton
                      type="submit"
                      className="btn btn-lg btn-rounded btn-primary  sm-2 mr-1"
                      loading={this.state.Loading}
                      animation="border"
                      spinnerSize="lg"
                    >
                      Se connecter
                    </LaddaButton>
                  </form>

                  <SweetAlert
                    show={this.state.error}
                    title="Attention !"
                    type="error"
                    text="Votre email ou votre mot de passe sont incorrects"
                    onConfirm={() => this.toggleAlert("error")}
                  />
                  <SweetAlert
                    show={this.state.errorcnx}
                    title="Attention !"
                    type="errorcnx"
                    text="La connexion a échoué"
                    onConfirm={() => this.toggleAlert("errorcnx")}
                  />
                  {/* <div className="mt-3 text-center">
                    <Link to="/session/forgot-password" className="text-muted">
                      <u>Forgot Password?</u>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  user: state.user,
});

export default connect(mapStateToProps, {
  loginWithEmailAndPassword,
})(Signin);
