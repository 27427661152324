import React, { Component, useRef } from "react";

import SimpleCard from "@gull/components/cards/SimpleCard";
import { Row, Col } from "react-bootstrap";
import Full_graphes from "app/views/graphes/Full_graphes";
import Evolution_nbr_postes from "app/views/graphes/Evolution_nbr_postes";
import Skills from "app/views/graphes/Skills";
import Rep_par_secteur from "app/views/graphes/Rep_par_s_anapec";
import strapi from "app/services/strapi";
import { Button, Carousel } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class Dashboard1 extends Component {
  state = {
    total: 0,
    regions: [],
    secteurs: [],
    metiers: [],
    articles: [],
    filters: "",
    nbroffre: 0,
    nbrposte: 0,
    nbrcomp: 0,
    selectedRegion: 0,
    selectedSecteur: 0,
    selectedMetier: 0,
    selectedGraph: 1,
    displayGraphs: false,
  };

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  async getreg() {
    const rt = await strapi.getDataRef_regions();
    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ regions: rt });
    }
  }

  async getsect() {
    const secteurs = await strapi.getDataRef_secteurs_anapec();
    if (secteurs === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ secteurs: secteurs });
    }
  }

  async getemp() {
    const rt = await strapi.getDataset_emploi_ANAPEC();
    if (rt === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ metiers: rt });
    }
  }

  async getStats() {
    const count = await strapi.getOffreCountBySourceAndDate();
    if (count === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({
        nbroffre: Math.round(count / 1000),
      });
    }

    const sum = await strapi.getNombredePostes();

    if (sum === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({
        nbrposte: Math.round(sum[0].nbr / 1000),
      });
    }

    const comp = await strapi.getSkill_Count();
    if (comp === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({
        nbrcomp: Math.round(comp / 1000),
      });
    }
  }

  async getarticles() {
    let i = 0;
    const art = await strapi.getarticles(1, 10);
    if (art === false) {
      NotificationManager.error(
        "Erreur lors de la connexion au serveur. Merci de patienter...",
        "Erreur"
      );
    } else {
      this.setState({ articles: art });
    }
  }

  async componentDidMount() {
    this.getreg();
    this.getStats();
    this.getsect();
    this.getemp();
    // this.getarticles();
  }

  render() {
    const regex = /(<([^>]+)>)/gi;
    let cardList1 = [
      {
        icon: "i-Suitcase text-50 text-primary ",
        title: this.state.nbroffre + "K",
        subtitle: "Nombre d'offre d'emplois",
      },
      {
        icon: "i-ID-Card text-50 text-primary",
        title: this.state.nbrposte + "K",
        subtitle: "Nombre de poste",
      },
      {
        icon: "i-Checkout-Basket text-50 text-primary",
        title: this.state.nbrcomp + "K",
        subtitle: "Nombre de compétences extraites",
      },
    ];

    return (
      <div className="container-fluid ml-2">
        <div className="row my-3"></div>
        <div className="row  justify-content-md-end"></div>
        {/* <Row>
          <Col>
            <Carousel className="bg-primary rounded" variant="light">
              {!this.state.articles ? (
                <div className="align-center">
                  <span className="spinner-glow spinner-glow-primary mr-3 "></span>{" "}
                </div>
              ) : (
                  this.state.articles.map((article) => (
                    <Carousel.Item fluid>
                      <a href={article.source} target="_blank">
                        <Row>
                          <Col>
                            <h5
                              className="card-title text-white text-center"

                            >
                              <b>{article.post_title}</b>
                            </h5>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-between px-5 pb-3">
                          <Col className="w-50">
                            <img
                              className="img-thumbnail w-100"
                              src={article.image}
                              // style={{ maxHeight: "500px", maxWidth: "500px" }}
                            ></img>
                          </Col>
                          <Col className="d-flex align-items-center "><p className="text-20 text-lg-rigth text-white">{article.post_content.replace(regex, "")}</p></Col>
                        </Row>

                        <Carousel.Caption></Carousel.Caption>
                      </a>
                    </Carousel.Item>
                  ))
                )}
            </Carousel>
          </Col>
        </Row> */}
        {/* <Breadcrumb
          // routeSegments={[
          //   { name: "Accueil" }
          // ]}
        ></Breadcrumb> */}
        {!this.state.displayGraphs && (
          <div>
            <div className="row my-3">
              <h1 className="text-primary ">Statistiques</h1>
            </div>
            <Row className="d-flex  align-items-stretch bd-highlight mr-2 mb-2">
              {cardList1.map((card, index) => (
                <Col className="p-2 align-self-stretch bd-highlight  col-example ">
                  <div className="card p-2  o-hidden mb-2">
                    <div className="card-body ">
                      <Row className="d-flex  mr-2 mb-2">
                        <Col className="py-2 my-2 text-center flex-shrink-1">
                          <i className={card.icon}></i>
                        </Col>
                        <Col className="py-2 text-center flex-grow-1">
                          <div className="content">
                            <p className="text-muted mt-2 mb-0">
                              {card.subtitle}
                            </p>
                            <p className="text-primary text-24 mb-2">
                              {card.title}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        <div className="row my-1">
          <h1 className="text-primary my-1">Veille et Prospective</h1>
        </div>
        <Row className="d-flex  align-items-stretch bd-highlight mr-2 mb-2">
          <Col className="p-2 align-self-stretch bd-highlight  col-example ">
            <div className="card p-2  o-hidden mb-2">
              <div className="card-body ">
                <Row className="d-flex">
                  <Col className="py-2 text-center flex-grow-1">
                    <div className="content">
                      <p className="text-muted mt-2 mb-0">
                        Je souhaite m'informer sur une région
                      </p>
                      <p className="text-primary text-24 mb-2 text-capitalize">
                        <strong className="text-sm-left  heading text-primary">
                          <center>
                            <span>Région</span>
                          </center>
                        </strong>
                        <select
                          className="custom-select task-manager-list-select"
                          id="inputGroupSelect01"
                          onChange={async ({ target: { value } }) => {

                            this.setState({
                              filters: `${value != 0
                                ? `filter=prodigedb_dev2_x0020_ref_region%2FRegion%20eq%20'${value}'`
                                : `filter=`
                                }${this.state.selectedSecteur != 0
                                  ? `${value != 0 ? `%20and%20` : ``
                                  }prodigedb_dev2_x0020_ref_secteur_anapec%2FSecteur%20eq%20'${this.state.selectedSecteur
                                  }'`
                                  : ``
                                }${this.state.selectedMetier != 0
                                  ? `${this.state.selectedSecteur != 0 ||
                                    value != 0
                                    ? `%20and%20`
                                    : ``
                                  }prodigedb_dev2_x0020_dataset_emploi_anapec%2FEmploi_x0020_Metier%20eq%20'${this.state.selectedMetier
                                  }'`
                                  : ``
                                }`,

                              selectedRegion: value != 0 ? value : 0,
                            });
                          }}
                        >
                          <option value={0}>Réinitialiser</option>
                          {this.state.regions.map((reg) => (
                            <option value={reg.Nom}>{reg.Nom} </option>
                          ))}
                        </select>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col>
            <Col className="p-2 align-self-stretch bd-highlight  col-example ">
              <div className="card p-2  o-hidden mb-2">
                <div className="card-body ">
                  <Row className="d-flex">
                    <Col className="py-2 text-center flex-grow-1">
                      <div className="content">
                        <p className="text-muted mt-2 mb-0">
                          Je souhaite explorer les données d'un secteur
                          d'activité
                        </p>
                        <p className="text-primary text-24 mb-2 text-capitalize">
                          <strong className="text-sm-left  heading text-primary">
                            <center>
                              <span>Secteur</span>
                            </center>
                          </strong>
                          <select
                            className="custom-select task-manager-list-select"
                            id="inputGroupSelect01"
                            onChange={async ({ target: { value } }) => {
                              this.setState({
                                filters: `${value != 0
                                  ? `filter=prodigedb_dev2_x0020_ref_secteur_anapec%2FSecteur%20eq%20'${value}'`
                                  : `filter=`
                                  }${this.state.selectedRegion != 0
                                    ? `${value != 0 ? `%20and%20` : ``
                                    }prodigedb_dev2_x0020_ref_region%2FRegion%20eq%20'${this.state.selectedRegion
                                    }'`
                                    : ``
                                  }${this.state.selectedMetier != 0
                                    ? `${this.state.selectedRegion != 0 ||
                                      value != 0
                                      ? `%20and%20`
                                      : ``
                                    }prodigedb_dev2_x0020_dataset_emploi_anapec%2FEmploi_x0020_Metier%20eq%20${this.state.selectedMetier
                                    }`
                                    : ``
                                  }`,
                                selectedSecteur: value != 0 ? value : 0,
                              });
                            }}
                          >
                            <option value={0}>Réinitialiser</option>
                            {this.state.secteurs.map((sec) => (
                              <option value={sec.Nom}>{sec.Nom} </option>
                            ))}
                          </select>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Col>
          <Col>
            <Col className="p-2 align-self-stretch bd-highlight  col-example ">
              <div className="card p-2  o-hidden mb-2">
                <div className="card-body ">
                  <Row className="d-flex">
                    <Col className="py-2 text-center flex-grow-1">
                      <div className="content">
                        <p className="text-muted mt-2 mb-0">
                          Je souhaite accéder aux données relatives à un
                          Emploi-Métier
                        </p>
                        <p className="text-primary text-24 mb-2 text-capitalize">
                          <strong className="text-sm-left  heading text-primary">
                            <center>
                              <span>Emploi Métier</span>
                            </center>
                          </strong>
                          <select
                            className="custom-select task-manager-list-select"
                            id="inputGroupSelect01"
                            onChange={async ({ target: { value } }) => {
                              this.setState({
                                filters: `${value != 0
                                  ? `filter=prodigedb_dev2_x0020_dataset_emploi_anapec%2FEmploi_x0020_Metier%20eq%20'${value}'`
                                  : `filter=`
                                  }${this.state.selectedSecteur != 0
                                    ? `${value != 0 ? `%20and%20` : ``}prodigedb_dev2_x0020_ref_secteur_anapec%2FSecteur%20eq%20'${this.state.selectedSecteur}'`
                                    : ``
                                  }${this.state.selectedRegion != 0
                                    ? `${this.state.selectedSecteur != 0 ||
                                      value != 0
                                      ? `%20and%20`
                                      : ``
                                    }prodigedb_dev2_x0020_ref_region%2FRegion%20eq%20'${this.state.selectedRegion
                                    }'`
                                    : ``
                                  }`,
                                selectedMetier: value != 0 ? value : 0,
                              });
                            }}
                          >
                            <option value={0}>Réinitialiser</option>
                            {this.state.metiers.map((m) => (
                              <option value={m.emploi_metier}>{m.emploi_metier} </option>
                            ))}
                          </select>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        {!this.state.displayGraphs && (
          <Row className="d-flex justify-content-center">
            <Button
              variant="primary"
              className="btn-icon m-1 "
              onClick={(e) => this.setState({ displayGraphs: true })}
              // disabled={
              //   this.state.selectedMetier === 0 &&
              //   this.state.selectedRegion === 0 &&
              //   this.state.selectedSecteur === 0
              // }
            >
              <span className="ul-btn__text"> Lancer la recherche</span>
            </Button>
          </Row>
        )}
        {/* START GRAPH PANEL*/}
        <div>
          {this.state.displayGraphs && (
            <div className="row">
              <div className="col">
                <SimpleCard className="mb-4">
                  <Row className="d-flex flex-row mr-2 mb-2">
                    <Button
                      variant="primary"
                      className="btn-icon m-1 "
                      onClick={(e) => {
                        this.setState({ displayGraphs: false });
                      }}
                    >
                      <span className="ul-btn__text">
                        {" "}
                        {"Revenir vers la page principale"}
                      </span>
                    </Button>
                  </Row>
                  {/* <Row className="d-flex flex-row-reverse mr-2 mb-2">
                    <select
                      id="graph3"
                      className="form-control col-lg-3 "
                      onChange={async ({ target: { value } }) => {
                        this.setState({
                          selectedGraph: value,
                        });
                      }}
                    >
                      <option value={1}>Top 15 des emplois-métiers</option>
                      <option value={2}>Evolution du nombre de postes</option>
                      <option value={3}>Compétences </option>
                      <option value={4}>
                        Répartition du nombre de poste par secteur{" "}
                      </option>
                    </select>
                    <label htmlFor="graph3" className="my-1">
                      <h4 className="text-primary text-monaco font-weight-bold">
                        Choisir le graphe : &nbsp;{" "}
                      </h4>
                    </label>

                    <Col>
                      {this.state.selectedRegion != 0 && (
                        <p>
                          Région sélectionnée :{" "}
                          <b>
                            {
                              this.state.regions.filter(
                                (e) => e.id == this.state.selectedRegion
                              )[0]?.Nom
                            }
                          </b>
                        </p>
                      )}
                      {this.state.selectedSecteur != 0 && (
                        <p>
                          Secteur sélectionné :{" "}
                          <b>
                            {" "}
                            {
                              this.state.secteurs.filter(
                                (e) => e.id == this.state.selectedSecteur
                              )[0]?.Nom
                            }
                          </b>
                        </p>
                      )}
                      {this.state.selectedMetier != 0 && (
                        <p>
                          Emploi-métier sélectionné :{" "}
                          <b>
                            {" "}
                            {
                              this.state.metiers.filter(
                                (e) => e.id == this.state.selectedMetier
                              )[0]?.emploi_metier
                            }
                          </b>
                        </p>
                      )}
                    </Col>
                  </Row> */}
                  <Row>
                    <Full_graphes
                      filters={this.state.filters}
                      className="w-responsive w-75 w-auto h-1000 p-3"
                    />
                    {/* {this.state.selectedGraph == 1 && (
                      <Top15_emploi_region
                        filters={this.state.filters}
                        className="w-responsive w-75 w-auto h-1000 p-3"
                      />
                    )}
                    {this.state.selectedGraph == 2 && (
                      <Evolution_nbr_postes
                        filters={this.state.filters}
                        className="w-responsive w-75 w-auto h-1000 p-3"
                      />
                    )}
                    {this.state.selectedGraph == 3 && (
                      <Skills
                        filters={this.state.filters}
                        className="w-responsive w-75 w-auto h-1000 p-3"
                      />
                    )}
                    {this.state.selectedGraph == 4 && (
                      <Rep_par_secteur
                        filters={this.state.filters}
                        className="w-responsive w-75 w-auto h-1000 p-3"
                      />
                    )} */}
                  </Row>
                </SimpleCard>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard1;
