import axios from "axios";
import localStorageService from "./localStorageService";
import qs from "qs";
import { loginWithEmailAndPassword } from "../redux/actions/LoginActions";
import history from "@history.js";
const apibaseurl = "https://mwkb.anapec.org";
const rss_apibaseurl = "https://digitaldevops.net/prodigeposts/wp-json/prodige/v1";
const pre_normalized_offre_emploiroute = "pre-normalized-offre-emplois";
const offre_emploiroute = "offre-emplois";
const ref_regionroute = "ref-regions";
const ref_secteur_rome = "ref-secteurs";
const ref_secteur_anapec = "ref-secteur-anapecs"
const userroute = "users";
const correspondanderoute = "secteur-correspondances"
const regexroute = "regex-extraction-results";
const bertroute = "extraction-result-berts"
const datasetregion = "dataset-regions"
const datasetsecteur = "dataset-secteurs"
const datasetsecteuranapec = "dataset-secteur-anapecs"
const datasetcategoriepro = "Dataset-emploi-romes"
const correspondances_anapec_view = "secteur_parametres"
const correspondances_emploi_view = "emploi_parametres"
const datasetemploianapec = "Dataset-emploi-anapecs"
const correspondances_emploi = "emploi-anapec-correspondances"
const competences = "skills"
const villes = "ref-villes"
const offre_emploi_correction = "offre-emploi-corrections"
// const powerbi_token_path="powerbi_GetToken"
class strapi {
  constructor() { }

  BItoken = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `http://localhost:1337/powerbi`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }


  getOriginaleById = async (id) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${pre_normalized_offre_emploiroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getarticles = async (page, nb_per_page) => {
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // console.log(page, nb_per_page);
      // console.log(`${rss_apibaseurl}/news?page=${page}&per_page=${nb_per_page}`);
      const response = await fetch(
        `${rss_apibaseurl}/news?page=${page}&per_page=${nb_per_page}`,
        requestConfig
      );
      // console.log(response);
      const json = await response.json();
      // console.log(json);
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getarticles_keyword = async (page, nb_per_page, keyword) => {
    // console.log(keyword);
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // console.log("keyword", page, nb_per_page, keyword);
      // console.log(`${rss_apibaseurl}/posts?page=${page}&per_page=${nb_per_page}&keywords=${keyword}`);
      const response = await fetch(
        `${rss_apibaseurl}/posts?page=${page}&per_page=${nb_per_page}&keywords=${keyword}`,
        requestConfig
      );
      // console.log(response);
      const json = await response.json();
      // console.log("keyword", json);
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getEmploiCorrespondance = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_emploi}?_limit=600`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  updateCorrespondance_emploi = async (id, value) => {
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        emploi_anapec: value
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_emploi}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addDataset_emploi_anapec = async (nom, lexique, coefficient_aj) => {

    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        emploi_metier: nom,
        lexique: lexique,
        coefficient_aj
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetemploianapec}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getBlacklistedstate = async (id) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/blacklist?id=${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  updateDataset_emploianapec = async (id, emploi_metier, lexique, coef) => {
    let requestConfig = {}
    requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        emploi_metier: emploi_metier,
        lexique: lexique,
        coefficient_aj: coef
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetemploianapec}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }
  getDataset_emploi_ANAPEC = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${datasetemploianapec}?_limit=1000`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getEmploiCorrespondance_ANAPEC_view = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_emploi_view}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getSecteurCorrespondance_ANAPEC_view = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_anapec_view}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addRef_region = async (region, coefficient_aj) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        Nom: region,
        coefficient_aj: coefficient_aj
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_regionroute}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addRef_secteur_anapec = async (secteur_anapec, coefficient_aj) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        Nom: secteur_anapec,
        coefficient_aj: coefficient_aj
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_anapec}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addRef_secteur_rome = async (secteur_rome) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        Nom: secteur_rome
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_rome}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }


  addDataset_region = async (id, lexique) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        region_id: id,
        lexique: lexique
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetregion}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }


  addDataset_secteurAnapec = async (id, lexique) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        secteur_anapec_id: id,
        lexique: lexique
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteuranapec}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }


  }

  addDataset_secteur_rome = async (id, lexique) => {

    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        secteur_id: id,
        lexique: lexique
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteur}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }


  }

  addDataset_emploi_rome = async (nom, lexique) => {

    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        categorie_professionnelle: nom,
        lexique: lexique
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetcategoriepro}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  disableDataset_region = async (id, newState) => {
    if (newState)
      newState = true
    else
      newState = false
    // console.log("id", id);
    // console.log("newState", newState);
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        isActive: newState
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_regionroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  disableDataset_secteurAnapec = async (id, newState) => {
    if (newState)
      newState = true
    else
      newState = false
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        isActive: newState
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_anapec}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  disableDataset_secteur_rome = async (id, newState) => {
    if (newState)
      newState = true
    else
      newState = false
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        isActive: newState
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteur}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }

  disableDataset_emploi_rome = async (id, newState) => {
    if (newState)
      newState = true
    else
      newState = false
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        isActive: newState
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetcategoriepro}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  disableDataset_emploi_anapec = async (id, newState) => {
    if (newState)
      newState = true
    else
      newState = false
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        isActive: newState
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetemploianapec}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }



  updateDataset_region = async (data_id, reg_id, nom, lexique, coef) => {
    let requestConfig = {}
    requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        lexique: lexique

      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetregion}/${data_id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      } else {
        requestConfig = {
          method: "PUT",
          body: JSON.stringify({
            Nom: nom,
            coefficient_aj: coef
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
            "Content-Type": "application/json",
          },
        };
        try {
          const response = await fetch(
            `${apibaseurl}/${ref_regionroute}/${reg_id}`,
            requestConfig
          );
          const json = await response.json();
          if (json.error) {
            return false;
          }
          return json;
        } catch (err) {
          return false;
        }

      }
      return json;
    } catch (err) {
      return false;
    }

  }

  updateDataset_secteuranapec = async (data_id, sec_id, nom, lexique, coef) => {
    let requestConfig = {}
    requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        lexique: lexique + ""
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteuranapec}/${data_id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      } else {
        requestConfig = {
          method: "PUT",
          body: JSON.stringify({
            Nom: nom,
            coefficient_aj: coef
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
            "Content-Type": "application/json",
          },
        };

        try {
          const response = await fetch(
            `${apibaseurl}/${ref_secteur_anapec}/${sec_id}`,
            requestConfig
          );
          const json = await response.json();
          if (json.error) {
            return false;
          }
          return json;
        } catch (err) {
          return false;
        }
      }
      return json;
    } catch (err) {
      return false;
    }


  }

  updateDataset_secteurrome = async (row, value, column) => {
    let requestConfig = {}
    if (column == 1) {
      // TODO : update ref_secteur
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          Nom: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };


      try {
        const response = await fetch(
          `${apibaseurl}/${ref_secteur_rome}/${row.secteur_id.id}`,
          requestConfig
        );
        const json = await response.json();
        if (json.error) {
          return false;
        }
        return json;
      } catch (err) {
        return false;
      }
    } else if (column == 2) {
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          lexique: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };


      try {
        const response = await fetch(
          `${apibaseurl}/${datasetsecteur}/${row.id}`,
          requestConfig
        );
        const json = await response.json();
        if (json.error) {
          return false;
        }
        return json;
      } catch (err) {
        return false;
      }
    }


  }

  updateDataset_categoriespro = async (row, value, column) => {
    let requestConfig = {}
    if (column == 1) {
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          categorie_professionnelle: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };
    } else if (column == 2) {
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          lexique: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };
    }


    try {
      const response = await fetch(
        `${apibaseurl}/${datasetcategoriepro}/${row.id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getDataset_categoriespro = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${datasetcategoriepro}?_limit=1000`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getDataset_secteurrome = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteur}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getDataset_secteuranapec = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${datasetsecteuranapec}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getDataset_region = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${datasetregion}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getDataset_skills_ST1 = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${competences}?type=ST1&limit=100&skip=0`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getDataset_skills_ST2 = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${competences}?type=ST2&limit=100&skip=0`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getDataset_skills_ST3 = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${competences}?type=ST3&limit=100&skip=0`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }



  getSecteurCorrespondance = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${correspondanderoute}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  EmploiCorrespondanceExists = async (emploi_offre) => {

    let requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_emploi}?emploi_offre_contains=${emploi_offre.trim()}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  SecteurCorrespondanceExists = async (secteur_offre) => {
    let requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${correspondanderoute}?secteur_offre_contains=${secteur_offre}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  updateSecteurCorrespondance = async (column, id, value) => {
    let requestConfig = {}
    if (column == 1) {
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          secteur_anapec: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };
    } else if (column == 2) {
      requestConfig = {
        method: "PUT",
        body: JSON.stringify({
          secteur_rome: value
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };
    }

    // console.log(`${apibaseurl}/${correspondanderoute}/${id}`);
    try {
      const response = await fetch(
        `${apibaseurl}/${correspondanderoute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addEmploiCorrespondance = async (emploi_offre) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        emploi_offre: emploi_offre
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${correspondances_emploi}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getOffre_Skills_regex = async (id) => {
    let requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${regexroute}?id_job_offer=${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  getOffre_Skills_bert = async (id) => {
    let requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${bertroute}?id_job_offer=${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addSecteurCorrespondance = async (secteur_offre) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        secteur_offre: secteur_offre
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${correspondanderoute}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }


  deleteUser = async (id) => {
    if ((id != null) & (id != "")) {
      const requestConfig = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await fetch(
          `${apibaseurl}/${userroute}/${id}`,
          requestConfig
        );
        const json = await response.json();

        if (json.error) {
          return null;
        }
        return json;
      } catch (err) {
        return false;
      }
    }
  };


  postModifyTests = async (id, user) => {
    const requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        username: user.username,
        email: user.email,
        password: user.password,
        role: user.role,
        confirmed: 1,
        blocked: 0,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${userroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  postDataTests = async (user) => {
    // const jwt = localStorage.getItem("jwt");
    // axios
    //   .post("http://localhost:1337/firms", {
    //     name: "Dolemon Sushi",
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   });

    const requestConfig = {
      method: "POST",
      body: JSON.stringify({
        username: user.username,
        email: user.email,
        password: user.password,
        role: user.role,
        confirmed: 1,
        blocked: 0,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(`${apibaseurl}/${userroute}`, requestConfig);
      const json = await response.json();
      // if (json.error) {
      //   return false;
      // }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataUsers = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(`${apibaseurl}/${userroute}`, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getUserRole_By_email = async (email) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${userroute}?email=${email}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataOffre_emplois = async (reg, ville, secteur_anapec, start, nbpostesort, source, startOfYear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let endPoint = "";

    let dsort = "";
    let nbsort = "";

    // if(nbpostesort){
    //   nbsort = "DESC"
    // }else {
    //   nbsort = "ASC"
    // }

    // if (datesort) {
    //   dsort = "DESC";
    // } else {
    //   dsort = "ASC";
    // }

    // const queryreg = qs.stringify({
    //   _where: [{ region: reg }],
    // });

    // const querysecanapec = qs.stringify({
    //   _where: [{ secteur_anapec: secteur_anapec }],
    // });

    // const querysecrome = qs.stringify({
    //   _where: [{ secteur_rome: secteur_rome }],
    // });

    // const queryRegSec = qs.stringify({
    //   _where: { _and: [{ secteur_anapec: secteur_anapec }, { region: reg }, { secteur_rome: secteur_rome }] },
    // });

    // if (!reg && !) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}?_start=${start}&_limit=10&_sort=date:${dsort}`;
    // } else if (reg && !secteur) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}?${queryreg}&_start=${start}&_limit=10&_sort=date:${dsort}`;
    // } else if (!reg && secteur) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}?${querysec}&_start=${start}&_limit=10&_sort=date:${dsort}`;
    // } else {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}?${queryRegSec}&_start=${start}&_limit=10&_sort=date:${dsort}`;
    // }

    endPoint = `${apibaseurl}/${offre_emploiroute}?${reg ? `&region=${reg}` : ``}${ville ? `&ville=${ville}` : ``}${secteur_anapec ? `&secteur_anapec=${secteur_anapec}` : ``}&_start=${start}&_limit=10${nbpostesort ? `&_sort=nombre_poste:DESC` : `&_sort=date:DESC`}${source ? `&source=${source}` : ""}${startOfYear && endOfYear ? `&date_gte=${startOfYear}&date_lte=${endOfYear}` : ""}`;
    // console.log(endPoint);
    // console.log(`Bearer ${localStorage.getItem("jwt_token")}`);
    // ,nombre_poste:${nbsort}

    try {
      const response = await fetch(endPoint, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataPre_normalized_offre_emplois = async (start, datesort) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let dsort = "";

    if (datesort) {
      dsort = "DESC";
    } else {
      dsort = "ASC";
    }

    try {
      // console.log("data",`${apibaseurl}/${pre_normalized_offre_emploiroute}?_start=${start}&_limit=10&_sort=created_at:${dsort}`);

      const response = await fetch(
        `${apibaseurl}/${pre_normalized_offre_emploiroute}?_start=${start}&_limit=10&_sort=created_at:${dsort}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataExported_Pre_normalized_offre_emplois = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${pre_normalized_offre_emploiroute}?_limit=-1`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataExported_offre_emplois = async (reg, secteur) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let endPoint = "";

    const queryreg = qs.stringify({
      _where: [{ region: reg }],
    });

    const querysec = qs.stringify({
      _where: [{ secteur: secteur }],
    });

    const queryRegSec = qs.stringify({
      _where: { _and: [{ secteur: secteur }, { region: reg }] },
    });

    if (!reg && !secteur) {
      endPoint = `${apibaseurl}/${offre_emploiroute}?_limit=-1`;
    } else if (reg && !secteur) {
      endPoint = `${apibaseurl}/${offre_emploiroute}?${queryreg}&_limit=-1`;
    } else if (!reg && secteur) {
      endPoint = `${apibaseurl}/${offre_emploiroute}?${querysec}&_limit=-1`;
    } else {
      endPoint = `${apibaseurl}/${offre_emploiroute}?${queryRegSec}&_limit=-1`;
    }

    try {
      const response = await fetch(endPoint, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getAutre_countsec = async (source, startOfyear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    const querycolumn = qs.stringify({
      _where: [{ secteur: 15 }],
    });
    try {
      const response = await fetch(
        `${apibaseurl}/${offre_emploiroute}/count?${source ? `&source=${source}` : ""
        }${startOfyear && endOfYear
          ? `&date_gte=${startOfyear}&date_lte=${endOfYear}`
          : ""
        }&${querycolumn}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };
  getAutre_countreg = async (source, startOfYear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    const querycolumn = qs.stringify({
      _where: [{ region: 13 }],
    });
    try {
      const response = await fetch(
        `${apibaseurl}/${offre_emploiroute}/count?${source ? `&source=${source}` : ""
        }${startOfYear && endOfYear
          ? `&date_gte=${startOfYear}&date_lte=${endOfYear}`
          : ""
        }&${querycolumn}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getUser_count = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${userroute}/count`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getAutre_count = async (attribute, source, startOfyear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let query = `${apibaseurl}/${offre_emploiroute}/count?${attribute}=autre${source ? `&source=${source}` : ""
      }${startOfyear && endOfYear
        ? `&date_gte=${startOfyear}&date_lte=${endOfYear}`
        : ""
      }`;
    try {
      const response = await fetch(query, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getVide_count = async (attribute, source, startOfyear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let query = `${apibaseurl}/${offre_emploiroute}/count?${source ? `source=${source}` : ""
      }${startOfyear && endOfYear
        ? `&date_gte=${startOfyear}&date_lte=${endOfYear}`
        : ""
      }&${attribute}=`;

    try {
      const response = await fetch(query, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getOffreCountBySourceAndDate = async (source, startOfYear, endOfYear) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let query = `${apibaseurl}/${offre_emploiroute}/count?${source ? `source=${source}` : ""
      }${startOfYear && endOfYear
        ? `&date_gte=${startOfYear}&date_lte=${endOfYear}`
        : ""
      }`;
    try {
      const response = await fetch(query, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getOffre_emplois_count = async (reg, ville, secteur_anapec, source, startOfyear, endOfYear) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    // const queryreg = qs.stringify({
    //   _where: [{ region: reg }],
    // });

    // const querysec = qs.stringify({
    //   _where: [{ secteur: secteur }],
    // });

    // const queryRegSec = qs.stringify({
    //   _where: { _and: [{ secteur: secteur }, { region: reg }] },
    // });

    let endPoint = "";
    // if (!reg && !secteur) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}/count`;
    // } else if (reg && !secteur) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}/count?${queryreg}`;
    // } else if (!reg && secteur) {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}/count?${querysec}`;
    // } else {
    //   endPoint = `${apibaseurl}/${offre_emploiroute}/count?${queryRegSec}`;
    // }

    endPoint = `${apibaseurl}/${offre_emploiroute}/count?${reg ? `&region=${reg}` : ``}${secteur_anapec ? `&secteur_anapec=${secteur_anapec}` : ``}${ville ? `&ville=${ville}` : ``}${source ? `&source=${source}` : ``}${startOfyear && endOfYear ? `&date_gte=${startOfyear}&date_lte=${endOfYear}` : ""}`;
    // console.log(endPoint);


    try {
      const response = await fetch(endPoint, requestConfig);
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getPre_normalized_offre_emplois_count = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    // if (datesort) {
    //   dsort = "DESC";
    // } else {
    //   dsort = "ASC";
    // }

    try {
      const response = await fetch(
        `${apibaseurl}/${pre_normalized_offre_emploiroute}/count`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataPre_normalized_offre_emplois_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${pre_normalized_offre_emploiroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getOffre_emplois_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${offre_emploiroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getUsers_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${userroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  addDataRef_villes = async (ville, region, coef) => {
    let requestConfig = {
      method: "POST",
      body: JSON.stringify({
        ville: ville,
        id_region: region,
        coefficient_aj: coef,
        isActive: true
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${villes}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }


  }
  updateDataRef_villes = async (id, value, coef, isActive) => {
    let requestConfig = {
      method: "PUT",
      body: JSON.stringify({
        ville: value,
        coefficient_aj: coef,
        isActive: isActive
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${villes}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }


  }
  getDataRef_villes_By_ID_Region = async (id) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${villes}?id_region=${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getDataRef_villes = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${villes}?_sort=ville:ASC`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getDataRef_regions_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_regionroute}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataRef_secteurs_ROME_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_rome}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataRef_secteurs_ANAPEC_By_ID = async (id) => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_anapec}/${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataRef_regions = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_regionroute}?_sort=Nom:ASC&_limit=100`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataRef_secteurs = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_rome}?_sort=Nom:ASC&_limit=100`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };

  getDataRef_secteurs_anapec = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${ref_secteur_anapec}?_sort=Nom:ASC&_limit=100`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };
  getNombredePostes = async () => {
    // const jwt = localStorage.getItem("jwt");

    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/count`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  };


  addSkill_ST1 = async (english_name, french_name_deepl) => {

    const requestConfig = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/create?english_name=${english_name}&french_name_deepl=${french_name_deepl}&levenshtein_treshold=0&type_id=ST1&type_name=Specialized Skill&id=${uniqueId}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  addSkill_ST2 = async (english_name, french_name_deepl) => {
    const requestConfig = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/create?english_name=${english_name}&french_name_deepl=${french_name_deepl}&levenshtein_treshold=0&type_id=ST2&type_name=Common Skill&id=${uniqueId}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  addSkill_ST3 = async (english_name, french_name_deepl) => {
    const requestConfig = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/create?english_name=${english_name}&french_name_deepl=${french_name_deepl}&levenshtein_treshold=0&type_id=ST3&type_name=Certification&id=${uniqueId}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }
  deleteSkill = async (english_name) => {
    const requestConfig = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/delete?english_name=${english_name}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  updateSkill = async (id, english_name, french_name, levenshtein_treshold) => {
    const requestConfig = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/update?id=${id}&english_name=${english_name}&french_name_deepl=${french_name}&levenshtein_treshold=${levenshtein_treshold}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }

  deleteSkill = async (id) => {

    const requestConfig = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/delete?id=${id}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }

  searchSkillByKeyword = async (type, keyword) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/search?type=${type}&keyword=${keyword}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getDataset_skills_parents = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/parents`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  addSkill_parent = async (nom) => {
    const requestConfig = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    // let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/parents?nom=${nom}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  updateSkill_parent = async (nom, newValue) => {
    const requestConfig = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/parents?nom=${nom}&updated_nom=${newValue}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  deleteSkill_parent = async (nom) => {
    const requestConfig = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/parents?nom=${nom}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }

  }

  addRelationSkill_Parent = async (skill, parent) => {
    const requestConfig = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    // let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/relation?skill=${skill}&parent=${parent}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

  getSkill_Parent = async (skill) => {
    const requestConfig = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        "Content-Type": "application/json",
      },
    };

    // let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

    try {
      const response = await fetch(
        `${apibaseurl}/${competences}/getparent?skill=${skill}`,
        requestConfig
      );
      const json = await response.json();
      if (json.error) {
        return false;
      }
      return json;
    } catch (err) {
      return false;
    }
  }

getSkill_Count = async () => {
  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/regex-extraction-results/count`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

getData_offre_emploi_new_count = async (reg, ville, secteur_anapec, emploi,emploi_original, source, startOfYear, endOfYear) => {
  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/offre_emploi_new/count?${reg? `&region=${reg}` : ``}${ville? `&ville=${ville}` : ``}${secteur_anapec? `&secteur=${secteur_anapec}` : ``}${emploi? `&emploi=${emploi}` : ``}${emploi_original? `&emploi_original=${emploi_original}` : ``}${source? `&source=${source}` : ``}${startOfYear? `&dateA=${startOfYear}&dateB=${endOfYear}` : ``}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

getData_offre_emploi_new = async (reg, ville, secteur_anapec, emploi, emploi_original, start, nbpostesort, source, startOfYear, endOfYear) => {
  // console.log("start", start);
  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/offre_emploi_new?${reg? `&region=${reg}` : ``}${ville? `&ville=${ville}` : ``}${secteur_anapec? `&secteur=${secteur_anapec}` : ``}${emploi? `&emploi=${emploi}` : ``}${emploi_original? `&emploi_original=${emploi_original}` : ``}${source? `&source=${source}` : ``}${nbpostesort? `&orderbynbpost=1` : ``}${startOfYear? `&dateA=${startOfYear}&dateB=${endOfYear}` : ``}&start=${start}&limit=20`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

searchClusterByKeyword = async (keyword) => {
  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/${competences}/parents/search?keyword=${keyword}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

getJobOffer_byURL = async (url) => {
  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/offre_emploi_new/URL?url='${url}'`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }

}

insertOffreEmploi_Correction = async(id, date, secteur, emploi, ville, region) => {

  let requestConfig = {
    method: "POST",
    body: JSON.stringify({
          id_offre : id,
          date : date,
          secteur_anapec : secteur,
          emploi_anapec : emploi,
          ville : ville,
          region : region
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/${offre_emploi_correction}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

updateOffreEmploi_Correction = async(id, secteur, emploi, ville, region) => {
  let requestConfig = {
    method: "PUT",
    body: JSON.stringify({
      secteur_anapec : secteur,
      emploi_anapec : emploi,
      ville : ville,
      region : region
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/${offre_emploi_correction}/${id}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

offre_emploi_correction_exists = async (id) => {

  const requestConfig = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${apibaseurl}/${offre_emploi_correction}?id_offre=${id}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}

disable_offre_emploi = async (id, value) => {
  console.log(id+"//"+value);
  let requestConfig = {
    method: "PUT",
    body: JSON.stringify({
      active: value
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      "Content-Type": "application/json",
    },
  };


  try {
    const response = await fetch(
      `${apibaseurl}/${offre_emploiroute}/${id}`,
      requestConfig
    );
    const json = await response.json();
    if (json.error) {
      return false;
    }
    return json;
  } catch (err) {
    return false;
  }
}


}
///////////////////////////////////

export default new strapi();
